import { SVGProps } from "react";

export function Checklist(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>checklist</title>
      <g id="Checklist">
        <path d="M163.366,997.52433A101.48515,101.48515,0,1,1,264.85118,896.03918,101.599,101.599,0,0,1,163.366,997.52433Zm0-158.41585a56.9307,56.9307,0,1,0,56.9307,56.9307A56.99585,56.99585,0,0,0,163.366,839.10848Z" />
        <path d="M103.47084,660.89066a22.28287,22.28287,0,0,1-17.85272-8.95053c-7.85891-10.53044-38.96581-43.95982-72.74133-76.94056A22.27712,22.27712,0,0,1,44.003,543.12109c19.18124,18.73067,41.80848,41.91581,58.24954,59.9184,70.5987-92.33737,149.21682-169.83679,204.56374-220.0263a22.27749,22.27749,0,1,1,29.92922,33.00588c-58.236,52.80882-142.85852,136.57371-215.28465,235.73348a22.27781,22.27781,0,0,1-17.874,9.13811Z" />
        <path d="M102.43047,284.653a22.28292,22.28292,0,0,1-17.85273-8.95053c-7.855-10.52464-38.96-43.95305-72.74133-76.94153a22.27643,22.27643,0,1,1,31.12624-31.87654c19.18316,18.73163,41.80847,41.91773,58.2476,59.91742C171.81088,134.46449,250.429,56.96507,305.77592,6.77556a22.27749,22.27749,0,1,1,29.92922,33.00588c-58.236,52.80883-142.85852,136.57372-215.28465,235.73349a22.27784,22.27784,0,0,1-17.874,9.13811Z" />
        <path d="M975.24722,126.2372H400.9898a22.27723,22.27723,0,1,1,0-44.55446H975.24722a22.27723,22.27723,0,1,1,0,44.55446Z" />
        <path d="M698.0195,225.2471H400.9898a22.27723,22.27723,0,1,1,0-44.55446H698.0195a22.27723,22.27723,0,1,1,0,44.55446Z" />
        <path d="M975.24722,502.47482H400.9898a22.27723,22.27723,0,1,1,0-44.55445H975.24722a22.27722,22.27722,0,1,1,0,44.55445Z" />
        <path d="M698.0195,601.48472H400.9898a22.27723,22.27723,0,1,1,0-44.55445H698.0195a22.27722,22.27722,0,1,1,0,44.55445Z" />
        <path d="M975.24722,858.91046H400.9898a22.27723,22.27723,0,1,1,0-44.55445H975.24722a22.27722,22.27722,0,1,1,0,44.55445Z" />
        <path d="M698.0195,957.92037H400.9898a22.27723,22.27723,0,1,1,0-44.55446H698.0195a22.27723,22.27723,0,0,1,0,44.55446Z" />
      </g>
    </svg>
  );
}
