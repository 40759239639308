import { Avatar24 } from "@bphxd/ds-core-react/lib/icons";

interface UserProps {
  name: string;
  dataTestid: string;
}

const User = ({ name, dataTestid }: UserProps) => {
  return (
    <div data-testid={dataTestid} className="d-flex align-items-center gap-2">
      <Avatar24 />
      <div className="small mb-0">{name}</div>
    </div>
  );
};

export default User;
