import { Button } from "../button";

type CancelDialogProps = {
  onConfirm: () => void;
  onDeny: () => void;
  cancelConfirmation: string;
};

export function CancelDialog({ onConfirm = () => {}, onDeny = () => {}, cancelConfirmation }: CancelDialogProps) {
  return (
    <div className="d-flex flex-column">
      <h4 className="text-center fw-light mb-4" data-testid="cancel-dialog-header">
        Are you sure you want to leave this form?
      </h4>

      <p className="text-center fw-light">Clicking 'Leave' will erase any information you have provided.</p>

      <div className="text-center border-primary mt-4">
        <Button
          onClick={onDeny}
          data-testid="cancel-dialog-deny"
          name="close-modal-dialog-cancel"
          type="button"
          className="x5-me-1"
          level="tertiary"
          theme="darker"
          rounded="pill"
        >
          Stay
        </Button>
        <Button
          onClick={onConfirm}
          data-testid="cancel-dialog-confirm"
          type="button"
          name={cancelConfirmation}
          level="primary"
          theme="standard"
          rounded="pill"
        >
          Leave
        </Button>
      </div>
    </div>
  );
}
