import { forwardRef } from "react";
import Editor from "react-markdown-editor-lite";
import MarkdownRenderer from "./MarkdownRenderer";
import "react-markdown-editor-lite/lib/index.css";

const MarkdownEditor = forwardRef((props, ref) => {
  return (
    <Editor
      ref={ref}
      renderHTML={(text) => <MarkdownRenderer content={text} components={{ ...props?.rendererComponents }} />}
      remarkPlugins={[
        "header",
        "font-bold",
        "font-italic",
        "font-strikethrough",
        "list-unordered",
        "list-ordered",
        "block-quote",
        "block-wrap",
        "block-code-inline",
        "block-code-block",
        "table",
        "image",
        "link",
        "clear",
        "logger",
        "mode-toggle",
        "full-screen",
        "tab-insert",
      ]}
      {...props}
    />
  );
});

MarkdownEditor.displayName = "MarkdownEditor";

export default MarkdownEditor;
