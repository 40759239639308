import { Col, FormGroup, Label } from "reactstrap";

export function SearchModalFormField({ label = "", formController = <></>, ...labelProps }) {
  return (
    <Col xs="8" className="pb-3">
      <Label className="mb-0" {...labelProps}>
        {label}
      </Label>
      <FormGroup className="mt-0 align-items-center gap-5">{formController}</FormGroup>
    </Col>
  );
}
