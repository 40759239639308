import { AdvancedSearchFilters, generateSearchFilterParams } from "../../views/search/search-filters";
import { addQueryIdToResultItems } from "./helpers";
import { getSearchAPI, postAdvancedSearchAPI, putSearchAPI } from "./rest-api";
import { AdvancedSearchResults } from "./types";
import { MAX_RESULTS_PER_QUERY } from "../../views/search/filter-item";
import { DocumentStatus } from "../../views/khub/enums";

type NotFilter = {
  EqualsTo: {
    Key: string;
    Value: {
      StringValue: DocumentStatus;
    };
  };
};

export async function fetchAdvancedSearchResults(
  { s: search }: Record<string, string>,
  categories: AdvancedSearchFilters[],
  count = `${MAX_RESULTS_PER_QUERY}`,
  includeRetiredContent: boolean = false,
) {
  if (!search) {
    return;
  }
  const searchTerms = { search };
  const params = {
    q: search,
    count,
  };
  const payload: { filters: { OrAllFilters?: any; NotFilter?: NotFilter; AndAllFilters?: any } } = {
    filters: { AndAllFilters: [] },
  };
  if (categories.length > 0) {
    payload.filters?.AndAllFilters.push({
      OrAllFilters: categories.map(generateSearchFilterParams),
    });
  }
  if (!includeRetiredContent) {
    const retiredFilter = {
      NotFilter: {
        EqualsTo: {
          Key: "status",
          Value: {
            StringValue: DocumentStatus.RETIRED,
          },
        },
      },
    };
    payload.filters.AndAllFilters.push(retiredFilter);
  }
  try {
    const response = await postAdvancedSearchAPI<AdvancedSearchResults>({
      spanName: "Search",
      spanAttributes: searchTerms,
      query: `v2/search?${new URLSearchParams(params).toString()}`,
      payload,
    });

    // Adds the QueryId to each result item
    if (response?.body?.items?.[0]?.ResultItems && response?.body?.items?.[0]?.QueryId) {
      response.body.items[0].ResultItems = addQueryIdToResultItems(
        response.body.items[0].ResultItems,
        response.body.items[0].QueryId,
      );
    }

    return response?.body;
  } catch (error: any) {
    throw new Error(error);
  }
}

/** Calls the feedback endpoint which in turn submits a click-through feedback to Kendra
 @param {string} queryId - The query id of the search result
 @param {string} resultId - The id of the search result that was clicked
 @param {string} resultClickTime - The time at which the search result was clicked
 @returns {object} - The response from the feedback endpoint
 */
export async function submitSearchFeedback(queryId: string, resultId: string, resultClickTime: string) {
  const response = await putSearchAPI({
    spanName: "Search feedback",
    spanAttributes: {},
    query: `v2/search/feedback?query_id=${queryId}&clicked_result_id=${resultId}&click_time=${resultClickTime}`,
  });
  return response;
}

export async function fetchDataSources() {
  const response = await getSearchAPI({
    spanName: "Search data sources",
    spanAttributes: {},
    query: `v2/search/data-sources`,
  });
  return response?.body as string[];
}
