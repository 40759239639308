/**
 * Returns the initials of a name. if malformed, returns undefined
 * @param name
 * @returns initials of name or undefined
 */

export function getInitials(name: string | undefined) {
  if (!name) {
    return undefined;
  }

  const nameComponents = name.split(" ").filter(Boolean);
  if (nameComponents.length < 1) {
    return undefined;
  }

  const firstInitial = nameComponents[0].charAt(0).toUpperCase();
  const secondInitial = nameComponents.length > 1 ? nameComponents[1].charAt(0).toUpperCase() : "";

  return firstInitial + secondInitial;
}
