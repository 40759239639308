import { PropsWithChildren } from "react";
import ContentLoader from "react-content-loader";

export function NavBtnSkeleton(props: PropsWithChildren) {
  return (
    <div className="border border-secondary-subtle bg-primary rounded w-100">
      <ContentLoader
        {...props}
        speed={3}
        width={200}
        height={120}
        viewBox="0 0 200 120"
        backgroundColor="#11111117"
        foregroundColor="#11111157"
        uniqueKey={process.env.NODE_ENV === "test" ? "navBtnSkeleton" : undefined}
      >
        <rect x="32" y="32" rx="3" ry="3" width="88" height="14" />
        <rect x="32" y="65" rx="3" ry="3" width="120" height="20" />
      </ContentLoader>
    </div>
  );
}
