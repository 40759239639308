import { SVGProps } from "react";

export function Plant(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>plant</title>
      <g id="Decorative_elements_plant_" data-name="Decorative elements (plant)">
        <path d="M599.0099,997.52475H400.9901c-74.60454,0-99.32608-71.59073-100.34228-74.63838q-.26976-.81219-.47765-1.64178L264.32356,777.85814a119.04409,119.04409,0,0,1-31.71217-10.40764c-33.47966-16.7408-51.91832-48.42977-51.91832-89.23268s18.43866-72.49188,51.91832-89.23267a120.03605,120.03605,0,0,1,49.56683-12.25248H717.82178a120.039,120.039,0,0,1,49.56683,12.25248c33.47966,16.74079,51.91832,48.42976,51.91832,89.23267s-18.43866,72.49188-51.91832,89.23268a119.055,119.055,0,0,1-31.71217,10.40764L699.82983,921.24459c-.1373.55306-.29781,1.10032-.47765,1.64178C698.335,925.934,673.61541,997.52475,599.0099,997.52475ZM343.13119,909.38467c2.043,5.4204,17.85949,43.58563,57.85891,43.58563H599.0099c40.08741,0,55.86518-38.26578,57.855-43.57016l39.34484-157.3774a22.278,22.278,0,0,1,21.612-16.87423s15.87833-.29586,30.73755-8.1103c17.62453-9.27057,26.19315-25.23979,26.19315-48.82039,0-24.04664-8.92637-40.19957-27.28961-49.38119a77.50868,77.50868,0,0,0-29.64109-7.5495H282.17822s-15.87736.29587-30.73658,8.1103c-17.62647,9.27058-26.19412,25.23979-26.19412,48.82039,0,24.04664,8.92637,40.19957,27.28961,49.38119a77.50868,77.50868,0,0,0,29.64109,7.5495,22.278,22.278,0,0,1,21.612,16.87423Z" />
        <path d="M500,550.9901a22.27741,22.27741,0,0,1-22.27723-22.27723V374.22068c0-71.58976,55.548-133.907,132.08346-148.17643,57.59785-10.73929,97.76841-33.74266,122.80516-70.32507,18.51407-27.05369,37.19639-30.78686,49.63838-29.16151,12.4942,1.63791,29.64689,9.98607,40.40455,40.451,19.82325,56.13494,11.6646,178.52046-68.80415,262.92931-.12376.12956-.24752.25719-.37322.38289C707.2633,476.52,637.44972,499.358,575.62655,488.50847a22.277,22.277,0,1,1,7.70034-43.88343c47.88057,8.4023,102.20065-9.50263,138.46109-45.62674,54.703-57.52824,69.04007-136.87055,64.68325-187.37431-1.93379-22.40389-7.06026-34.23383-10.17753-39.09054a48.34787,48.34787,0,0,0-6.91329,8.3501c-31.61548,46.19237-82.55724,76.12353-151.40779,88.961-27.34762,5.09843-52.07689,18.837-69.63568,38.68542-16.80461,18.99559-26.05971,42.32479-26.05971,65.69074V528.71287A22.278,22.278,0,0,1,500,550.9901Z" />
        <path d="M431.68317,337.6518c-36.886,0-96.8605-24.38892-133.37523-65.20247q-.17694-.19723-.349-.39932c-37.06973-43.47734-51.75975-94.686-57.55531-129.98821-6.862-41.79977-4.51539-83.6914,6.12431-109.32665,10.2423-24.67706,26.196-29.48735,34.79366-30.15935,15.94988-1.227,30.1255,8.13158,42.203,27.863,18.96368,30.98023,49.33961,50.45057,92.86626,59.5239,61.92857,12.91,106.87655,68.56049,106.87655,132.32325a22.27723,22.27723,0,0,1-44.55446,0c0-42.084-30.70177-80.21929-71.41476-88.70668-55.1139-11.48863-95.93228-38.12268-121.33644-79.16828-3.81053,12.6315-6.84754,36.1038-3.45084,66.88873,3.02154,27.39306,13.43112,79.58114,49.17621,121.63618,29.02807,32.3,77.51392,50.16147,99.99613,50.16147a22.27722,22.27722,0,1,1,0,44.55445Z" />
      </g>
    </svg>
  );
}
