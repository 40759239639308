import { SVGProps } from "react";

export function ComputerCloud(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" fill="#000000" {...props}>
      <title>computer_cloud</title>
      <path d="M932.7,61.88H67.3A64.89,64.89,0,0,0,2.48,126.7V703.88A64.89,64.89,0,0,0,67.3,768.7H384.37V913.37H313.29a22.28,22.28,0,0,0,0,44.55H686.71a22.28,22.28,0,0,0,0-44.55H615.63V768.7H932.7a64.89,64.89,0,0,0,64.82-64.82V126.7A64.89,64.89,0,0,0,932.7,61.88ZM571.07,913.37H428.93V768.7H571.07ZM953,703.88a20.29,20.29,0,0,1-20.27,20.27H67.3A20.29,20.29,0,0,1,47,703.88V126.7A20.29,20.29,0,0,1,67.3,106.44H932.7A20.29,20.29,0,0,1,953,126.7Zm-202.08-317a167.07,167.07,0,0,0,9.94-56.73c0-93.33-77.17-169.27-172-169.27-67.91,0-128.72,39.41-156.38,98.63a128,128,0,0,0-50.86-10.43c-70.09,0-127.1,56.15-127.1,125.17,0,3.78.19,7.53.53,11.27-59.33,12.37-104,64.32-104,126.38,0,71.23,58.85,129.18,131.19,129.18H717.82c72.34,0,131.19-58,131.19-129.18C849,451.92,807.27,401.36,750.89,386.89ZM717.82,596.53H282.18c-47.77,0-86.64-38-86.64-84.62,0-45.73,37.32-83.07,83.76-84.56.74.07,1.49.13,2.25.13a22.39,22.39,0,0,0,6.8-1.07l.14-.05a22.25,22.25,0,0,0,13-32.43A78.62,78.62,0,0,1,299,374.26c0-44.45,37-80.62,82.55-80.62a83.19,83.19,0,0,1,49.49,16.09l.31.2c.44.31.88.61,1.35.89l.64.37c.41.23.82.45,1.24.65l.9.4,1.07.43c.38.14.76.26,1.14.37l.6.2c.12,0,.25.05.37.08.38.1.77.18,1.15.26s.72.15,1.08.2.63.08.94.11.88.09,1.31.11l.68,0c.51,0,1,0,1.53,0l.5,0c.55,0,1.1-.09,1.64-.17.17,0,.33-.07.5-.09.53-.1,1.05-.2,1.57-.32l.66-.19c.45-.13.9-.26,1.34-.41l.9-.35,1-.43c.36-.17.71-.36,1.06-.54s.55-.28.81-.43.72-.45,1.07-.68.5-.32.74-.49l.95-.74.78-.64.75-.71c.29-.28.58-.57.86-.87s.38-.42.57-.64.61-.72.89-1.1l.19-.23c.1-.14.18-.29.28-.43.27-.38.52-.76.77-1.17s.32-.55.48-.84.37-.68.54-1,.34-.74.5-1.12.23-.55.33-.83c.17-.47.33-.94.47-1.41,0-.14.1-.28.14-.43,14.93-54.3,65.55-92.22,123.11-92.22,70.29,0,127.47,55.94,127.47,124.71a122.5,122.5,0,0,1-17.6,63.29A22.27,22.27,0,0,0,703.46,422l.36.3c.37.29.75.57,1.13.84l.87.59.42.28.3.15a21.87,21.87,0,0,0,5.3,2.26l.06,0a22.27,22.27,0,0,0,5.92.82c47.77,0,86.64,38,86.64,84.63S765.59,596.53,717.82,596.53Z" />
    </svg>
  );
}
