import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import { Form, Row, Col } from "reactstrap";
import { FormGroup, Label } from "../../../components/form-group";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { Button } from "../../../components/button";

import * as yup from "yup";
import MarkdownEditor from "../../../components/MarkdownEditor";

export const DATA_TESTIDS = {
  editForm: "faq-edit-question-form",
  submitForm: "faq-submit-question-form",
  titleInput: "faq-title",
  bodyInput: "faq-body",
  tagsSelect: "faq-tags",
  submitButton: "faq-submit-button",
  editButton: "faq-edit-button",
};

export default function Step1Form({
  onSubmit = () => {},
  tagOptions = [],
  editMode = false,
  isSubmitting = false,
  ...data
}) {
  const schemaObject = {
    title: yup.string().trim().required("Required").min(5, "Title should be at least 5 characters long"),
    body: yup.string().trim().required("Required").min(5, "Question should be at least 5 characters long"),
    tags: yup.array().min(1, "Please specify at least one tag"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      tags: data.tags?.map((name) => ({ value: name, label: name })),
    },
  });

  function serializeValues(data) {
    onSubmit({
      ...data,
      tags: data?.tags.map(({ value }) => value) || [],
    });
  }

  return (
    <Form
      onSubmit={handleSubmit(serializeValues)}
      data-testid={editMode ? DATA_TESTIDS.editForm : DATA_TESTIDS.submitForm}
    >
      <Row>
        <Col xs="12">
          <FormGroup className="without-margin-top">
            <Label htmlFor="title">Title</Label>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id="title"
                  data-testid={DATA_TESTIDS.titleInput}
                  type="text"
                  {...field}
                  error={error?.message}
                  invalid={!!error}
                  makeRoomForError
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <FormGroup className="without-margin-top">
            <Label htmlFor="body">Body</Label>
            <Controller
              name="body"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <MarkdownEditor
                    readOnly={isSubmitting}
                    style={{ height: "calc(35vh)" }}
                    {...field}
                    onChange={({ text }) => {
                      field.onChange(text);
                    }}
                  />
                  <div className="small" style={{ color: "#a80000", opacity: 1 }}>
                    {error?.message}
                  </div>
                </>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <FormGroup className="without-margin-top">
            <Label htmlFor="tags">Tags</Label>
            <Controller
              name="tags"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <Select
                      isMulti
                      className="basic-multi-select"
                      invalid={!!error}
                      placeholder="Start typing to search for tags"
                      options={tagOptions}
                      isDisabled={isSubmitting}
                      {...field}
                    />
                    <div className="small" style={{ color: "#a80000", opacity: 1 }}>
                      {error?.message}
                    </div>
                  </>
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="2"></Col>
        <Col xs="8">
          <div className="m-auto"></div>
        </Col>
        <Col xs="2">
          <div className="text-end">
            <Button
              data-testid={editMode ? DATA_TESTIDS.editButton : DATA_TESTIDS.submitButton}
              name={editMode ? "submit-faq-edit" : "submit-new-faq"}
              aria-label={editMode ? "submit-faq-edit" : "submit-new-faq"}
              type="submit"
              className="text-nowrap"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              level="primary"
              theme="darker"
              rounded="pill"
            >
              {editMode ? "Save" : "Submit"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
