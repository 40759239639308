"use client";
import { useEffect, useState, Children, Fragment, PropsWithChildren, ReactNode } from "react";
import { Link } from "../components/Link";
import { Button } from "../components/button";
import { Collapse, Nav, NavItem, NavbarText } from "reactstrap";
import { Home24, Right32 } from "@bphxd/ds-core-react/lib/icons";
import { Divider } from "../components/divider";
import LeftSideNotification from "../components/NotificationTray/left-side-notification";
import { trackEvent } from "../utils/event-tracker";
import { slugToText, textToSlug } from "../utils/helpers/utils";
import { NavTree as KHubNavTree } from "../views/khub/nav-tree";
import { env } from "next-runtime-env";
import { useLocation } from "../hooks/use-location";
import { useMenuData } from "./mega-menu/use-menu-data";

type NavSectionProps = PropsWithChildren<{
  groupPath?: string | string[];
  icon?: ReactNode;
  sectionName: string;
  startOpen?: boolean;
}>;
function NavSection({ groupPath, icon, sectionName, children, startOpen = false }: NavSectionProps) {
  const { pathname } = useLocation();
  let locationMatched;
  const [isOpen, setIsOpen] = useState(startOpen);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(startOpen);
  }, [startOpen]);

  if (typeof groupPath === "object") {
    locationMatched = groupPath.some((path) => path === pathname);
  } else if (groupPath) {
    locationMatched = pathname?.includes(groupPath) ?? false;
  }

  return (
    <NavItem
      tabIndex={0}
      className={`navBar-left__section ps-7 pe-4 ${locationMatched ? "selected" : ""} ${isOpen ? "open" : ""}`}
      role="menuitem"
    >
      <NavbarText
        onClick={toggle}
        data-testid={`${textToSlug(sectionName)}-side-navItem`}
        className="navBarLeft__heading"
        tag="div"
      >
        <div className="navbar-left__icon">{icon}</div>
        <div className="navBarLeft__title">{sectionName}</div>
        <Right32 className={`navbar-left32__arrow ${isOpen ? "down-arrow" : ""}`} />
      </NavbarText>
      <Collapse isOpen={isOpen}>
        <Nav
          className="navbarLeft__items"
          data-testid={`${textToSlug(sectionName)}-content-${isOpen ? "displayed" : "not-displayed"}`}
          role="menu"
        >
          {Children.map(children, (child) => (
            <NavItem>{child}</NavItem>
          ))}
        </Nav>
      </Collapse>
    </NavItem>
  );
}

type PageLinkProps = PropsWithChildren<{
  to: string;
  pageName: string;
  pageTitle?: string;
  pageDescription?: string;
  onClick?: () => void;
  doNotTrack?: boolean;
  dataTestid?: string;
}>;
function PageLink({
  to,
  pageName,
  pageTitle,
  pageDescription,
  onClick,
  doNotTrack = false,
  dataTestid,
}: PageLinkProps) {
  const label = slugToText(pageName);

  function trackedOnClick() {
    if (!doNotTrack) {
      trackEvent({ name: `nav-link_click`, to, pageName });
    }
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      {pageDescription ? (
        <>
          <h3 className="mb-1">{pageTitle}</h3>
          <div className={`nav-section-description mb-4`}>{pageDescription}</div>
          <Button href={to} className="ms-4 mb-3" level="tertiary" theme="standard" rounded="pill" size="sm">
            {label}
          </Button>
        </>
      ) : (
        <Link
          hideExternalLinkIcon
          name={textToSlug(label)}
          data-testid={dataTestid}
          href={to}
          className="nav-links"
          // @ts-expect-error Link doesn't have an activeClassName prop
          activeClassName="selected"
          onClick={() => trackedOnClick()}
        >
          <span>{label}</span>
        </Link>
      )}
    </>
  );
}

type SideMegaMenuProps = PropsWithChildren<{
  onClick?: () => void;
  displayNotification?: boolean;
}>;
export function SideMegaMenu({ onClick, displayNotification = false }: SideMegaMenuProps) {
  const menuData2 = useMenuData();

  const { pathname } = useLocation();
  const paths = pathname?.split("/");

  if (paths && paths[1] === "knowledgehub") {
    return <KHubNavTree docUuid={paths[2]} />;
  }

  const showLeftSideNotification = env("NEXT_PUBLIC_ENABLE_LEFTSIDE_NAV_NOTIFICATION") === "true";

  if (showLeftSideNotification && displayNotification === true) {
    return <LeftSideNotification />;
  }

  return (
    <Nav color="light" vertical className="navbar-left-col" role="menubar">
      <NavItem data-testid="home-section-item" className="navBar-left__section ps-7" style={{ height: "50px" }}>
        <Link
          href="/"
          className="home-link"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Home24 />
        </Link>
      </NavItem>
      {menuData2.map(({ id, featureColumn, columns }) => {
        const {
          dropDownMenu: { header, description, links },
        } = featureColumn;
        return (
          <NavSection key={id} sectionName={header}>
            {links[0] && (
              <PageLink
                dataTestid={`${header}-nav-link`}
                to={`${links[0].href}`}
                pageTitle={header}
                pageDescription={description}
                pageName={links[0].name}
                onClick={onClick}
              />
            )}
            <Divider pBottom="XS" pTop="XS" />

            {columns.map((column) => {
              const {
                dropDownMenu: { header, links },
              } = column;

              return (
                <Fragment key={column.id}>
                  <p className="lead mt-3 mb-0">{header}</p>
                  {links.map((link) => (
                    <PageLink key={link.id} to={link.href} pageName={link.description} onClick={onClick} />
                  ))}
                </Fragment>
              );
            })}
          </NavSection>
        );
      })}
    </Nav>
  );
}
