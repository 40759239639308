"use client";
import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useUser } from "../hooks/use-user";
import { Navbar } from "reactstrap";
import { Hamburger48, Remove48, Left24 } from "@bphxd/ds-core-react/lib/icons";

import { Avatar, Logo } from "@bphxd/ds-core-react";
import { SideMegaMenu } from "./side-mega-menu";
import NotificationTray from "../components/NotificationTray/index";
import { Link } from "../components/Link";
import { Button } from "../components/button";
import { OpaqueScreen } from "../components/opaque-screen";
import { SearchBar } from "../components/search-bar";
import { SearchModal } from "../components/search/modal";
import NotificationButton from "../components/NotificationTray/notification-button";
import { env } from "next-runtime-env";
import { useLocation } from "../hooks/use-location";
import { GlobalProductTour } from "../components/global-product-tour";
import { getInitials } from "../utils/user-initials";
import { useAppActions, useSearchIsActive } from "../hooks/use-app-store";

export function NavbarTop() {
  const user = useUser();
  const path = useLocation().pathname;
  const paths = path?.split("/") ?? [];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //Use state hook for process.env.NEXT_PUBLIC_ENABLE_LEFTSIDE_NAV_NOTIFICATION
  const [displayNotification, setDisplayNotification] = useState(false);
  const showLeftSideNotification = env("NEXT_PUBLIC_ENABLE_LEFTSIDE_NAV_NOTIFICATION") === "true";
  const searchIsActive = useSearchIsActive();

  const { setIsHelpAndSupportModalOpen } = useAppActions();

  const menuStyles = {
    bmBurgerButton: {
      position: "relative",
    },
    bmCrossButton: {
      height: "48px",
      width: "48px",
      top: "28px",
      right: "15px",
    },
    bmMenuWrap: {
      top: 0,
      left: 0,
      background: "white",
      height: "100%",
    },
    bmOverlay: {
      top: 0,
    },
  };

  function handleNavClick() {
    setIsMenuOpen(false);
  }

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  function handleHelpAndSupportClick() {
    setIsHelpAndSupportModalOpen(true);
  }

  const GraphQLUserPhoto = () => {
    return (
      <Avatar
        aria-label="avatar"
        href="/profile"
        image={`${user?.photo ? `data:image/jpeg;base64, ${user?.photo}` : ""}`}
        initials={getInitials(`${user?.givenName} ${user?.surname}`)}
      />
    );
  };

  return (
    <>
      <div className="bg-white z-2 position-relative">
        <Navbar light expand="md" className="main-nav" container="xxl" aria-label="Page-header">
          <div className="d-lg-none">
            <Menu
              width={"384px"}
              // @ts-expect-error styles isn't on this overload
              styles={menuStyles}
              itemListElement="div"
              customBurgerIcon={<Hamburger48 />}
              customCrossIcon={<Remove48 />}
              isOpen={isMenuOpen}
              onOpen={handleMenuOpen}
              onClose={handleMenuClose}
            >
              {showLeftSideNotification ? (
                <div>
                  {displayNotification ? (
                    //Display Leftside Notification Header
                    <div
                      data-testid="Left-side-notification-header"
                      className="d-flex align-items-center ps-5"
                      style={{
                        height: "103px",

                        borderBottom: "1px solid rgba(17, 17, 17, 0.2)",
                      }}
                    >
                      <Left24
                        style={{ cursor: "pointer" }}
                        className="me-4"
                        onClick={() => setDisplayNotification(!displayNotification)}
                      />
                      <p className="fs-5 text my-0">Notifications</p>
                    </div>
                  ) : (
                    // Display the Default Leftside Navigation Header
                    <div
                      data-testid="Left-side-default-header"
                      className="navbar-left ps-6"
                      style={{
                        height: "103px",
                        borderBottom: "1px solid rgba(17, 17, 17, 0.2)",
                      }}
                    >
                      <div data-testid="user-profile">
                        <GraphQLUserPhoto />
                      </div>
                      <NotificationButton
                        disabled={paths.includes("dac")}
                        onDisplayNotificationClick={() => setDisplayNotification(!displayNotification)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div
                    className="navbar-left ps-6"
                    style={{
                      height: "103px",
                      borderBottom: "1px solid rgba(17, 17, 17, 0.2)",
                    }}
                  >
                    <div data-testid="user-profile">
                      <GraphQLUserPhoto />
                    </div>
                    <NotificationTray />
                  </div>
                </div>
              )}
              <div className="pb-13">
                <SideMegaMenu
                  {...(showLeftSideNotification && {
                    displayNotification: displayNotification,
                  })}
                  data-testid="navbar-left"
                  onClick={handleNavClick}
                />
              </div>
              <Link className="py-3 ps-8 link-primary" href="/" onClick={handleHelpAndSupportClick}>
                Support
              </Link>
              <Link href="/" className="no-underline navbar-logo-wrapper col ps-7 pt-9 d-flex">
                <div className="d-flex align-items-center">
                  <Logo height={80} />
                  <span className="ps-4 text-brand-primary fs-4 text">Accelerate</span>
                </div>
              </Link>
            </Menu>
          </div>
          <Link href="/" className="no-underline navbar-logo-wrapper px-0">
            <div className="d-flex align-items-center">
              <div className="d-none d-lg-block ms-n3">
                <Logo height={80} />
              </div>
              <div className="text-brand-primary fs-4 text">Accelerate</div>
            </div>
          </Link>
          <div className="navbar-main-content px-0 py-0 col flex-lg-grow-1">
            <SearchBar />
            <SearchModal />
            <div className="navbar-right d-none d-lg-flex ms-4">
              <Button
                onClick={handleHelpAndSupportClick}
                name="help-and-support-navbar-top"
                data-testid="navbar-support-button"
                level="tertiary"
                theme="standard"
                rounded="pill"
              >
                Support
              </Button>
            </div>
          </div>
          <div className="navbar-right d-none d-lg-inline-flex">
            <NotificationTray />
            <div data-testid="user-profile">
              <GraphQLUserPhoto />
            </div>
          </div>
          <GlobalProductTour />
        </Navbar>
      </div>
      <OpaqueScreen searchIsActive={searchIsActive} />
    </>
  );
}
