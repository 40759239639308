import { graphql } from "../../gql";

export const GET_USER_BY_EMAIL = graphql(`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      jobTitle
      photo
      givenName
      surname
      email
      userId
    }
  }
`);

export const GET_ALL_SURVEYS = graphql(`
  query ListSurveys {
    listSurveys {
      surveyId
      author
      linkToSurvey
      startDate
      endDate
      estimatedTime
      approved
      description
      surveyName
      lastUpdatedBy
      createdDate
      updatedDate
    }
  }
`);

export const USER_HAS_SURVEY = graphql(`
  query HasSurvey {
    hasSurvey {
      surveyId
      surveyName
      linkToSurvey
      estimatedTime
      createdDate
      updatedDate
    }
  }
`);

export const ADD_NEW_SURVEY = graphql(`
  mutation PersistSurvey($survey: SurveyInput!) {
    persistSurvey(survey: $survey) {
      author
      startDate
      endDate
      estimatedTime
      description
      linkToSurvey
      surveyName
      lastUpdatedBy
    }
  }
`);

export const UPDATE_SURVEY = graphql(`
  mutation UpdateSurvey($survey: SurveyInput!) {
    updateSurvey(survey: $survey) {
      surveyId
      author
      startDate
      endDate
      estimatedTime
      approved
      description
      linkToSurvey
      surveyName
      lastUpdatedBy
    }
  }
`);

export const DELETE_SURVEY = graphql(`
  mutation RemoveSurvey($surveyId: String!) {
    removeSurvey(surveyId: $surveyId) {
      success
      message
    }
  }
`);

export const COMPLETE_SURVEY = graphql(`
  mutation CompleteSurvey($completeSurvey: CompleteSurveyInput!) {
    completeSurvey(completeSurvey: $completeSurvey) {
      userId
      surveyId
      hasCompletedSurvey
      surveyStatus
      userEmail
      surveyName
    }
  }
`);
