import styled from "@emotion/styled";

const OpaqueScreenStyled = styled.div`
  background: rgba(52, 52, 52, 0.8);

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 300ms;
  }

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s linear 300ms,
      opacity 300ms;
  }
`;

export function OpaqueScreen({ searchIsActive = false }) {
  return (
    <OpaqueScreenStyled
      className={`d-flex w-100 h-100 top-0 left-0 position-fixed border-0 z-1 ${searchIsActive ? "fadeIn" : "fadeOut"}`}
      data-testid={searchIsActive ? "opaque-screen" : ""}
    />
  );
}
