import { Box } from "../../components/box";
import { Button } from "../../components/button";
import { Edit24, Trash24, Check16, Star16 } from "@bphxd/ds-core-react/lib/icons/index";
import { Badge } from "@bphxd/ds-core-react";
import { TagDetailType } from "../../gql/graphql";

export const TESTID_ENUMS = {
  container: "tag-preview-card",
  editButton: "edit-moderation-tag",
  highLighted: "highlighted-tag",
  tagType: "tagType-tag",
  deleteButton: "delete-moderation-tag",
};

export type TagDetails = TagDetailType;

type TagPreviewCardProps = {
  tag: TagDetails;
  iconUrl?: string;
  onEditClick: (tag: TagDetails) => void;
  onDeleteClick: ({ type, id }: { type: string; id: string }) => void;
};

export function TagPreviewCard({ tag, iconUrl, onEditClick, onDeleteClick }: TagPreviewCardProps) {
  return (
    <div data-testid={TESTID_ENUMS.container}>
      <Box padding={[16]} gap="10" border="1px solid #d8d8d8" borderRadius="12">
        <Box flex flexDirection="column" width={120} minHeight="100px" gap={30}>
          <Badge color={tag.tagType ? "info" : "danger"} data-testid={TESTID_ENUMS.tagType}>
            {tag.tagType && <Check16 />}
            {tag.tagType ? tag.tagType : "Undefined"}
          </Badge>

          {tag.highlighted && (
            <Badge color="warning" data-testid={TESTID_ENUMS.highLighted}>
              <Star16 />
              Highlighted
            </Badge>
          )}
        </Box>
        <Box flex fullWidth>
          <Box flex flexDirection="column" fullWidth>
            <Box flex fullWidth gap="20">
              <h4>{tag?.tagName}</h4>
              {iconUrl && <img src={iconUrl} style={{ height: "32px", width: "32px" }} alt="tag icon" />}
            </Box>

            <div>{tag?.description}</div>
          </Box>
          <Box flex fullWidth>
            <div className="ms-auto"></div>
            <Box flex fullWidth gap={8} align="end" justify="end">
              <Button
                name="moderator-edit-tag"
                data-testid={`${TESTID_ENUMS.editButton}-${tag.tagName}`}
                onClick={() => onEditClick(tag)}
                Icon={Edit24}
                level="tertiary"
                theme="standard"
                rounded="pill"
                size="xs"
              >
                Edit
              </Button>
              <Button
                name="moderator-delete-tag"
                data-testid={`${TESTID_ENUMS.deleteButton}-${tag.tagName}`}
                onClick={() => onDeleteClick({ type: "delete", id: tag?.id })}
                Icon={Trash24}
                level="tertiary"
                theme="standard"
                rounded="pill"
                size="xs"
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
