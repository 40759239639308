import { SVGProps } from "react";

export function RoadTankerMed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>road-tanker-med</title>
      <g id="Tanker">
        <path d="M519.4713,602.14457H183.498c-99.81629,0-181.02278-81.20746-181.02278-181.02278C2.47525,321.3055,83.68174,240.099,183.498,240.099H519.4713c99.81629,0,181.02375,81.20649,181.02375,181.02278C700.49505,520.93711,619.28759,602.14457,519.4713,602.14457ZM183.498,284.65347c-75.24849,0-136.46833,61.21983-136.46833,136.46832S108.24954,557.59011,183.498,557.59011H519.4713c75.24946,0,136.46929-61.21983,136.46929-136.46832S594.72076,284.65347,519.4713,284.65347Z" />
        <path d="M183.16832,799.505a91.58416,91.58416,0,1,1,91.58416-91.58416A91.68823,91.68823,0,0,1,183.16832,799.505Zm0-138.61386a47.02971,47.02971,0,1,0,47.0297,47.0297A47.0832,47.0832,0,0,0,183.16832,660.89109Z" />
        <path d="M391.08911,799.505a91.58416,91.58416,0,1,1,91.58416-91.58416A91.68823,91.68823,0,0,1,391.08911,799.505Zm0-138.61386a47.02971,47.02971,0,1,0,47.0297,47.0297A47.08319,47.08319,0,0,0,391.08911,660.89109Z" />
        <path d="M767.32673,799.505a91.58416,91.58416,0,1,1,91.58416-91.58416A91.68886,91.68886,0,0,1,767.32673,799.505Zm0-138.61386a47.02971,47.02971,0,1,0,47.02971,47.0297A47.0832,47.0832,0,0,0,767.32673,660.89109Z" />
        <path d="M975.24752,601.48515H737.62376a22.278,22.278,0,0,1-22.27723-22.27723V262.37624A22.27741,22.27741,0,0,1,737.62376,240.099H845.63544c46.09375,0,84.257,46.962,108.15478,86.35713,24.93232,41.10265,43.73453,90.17056,43.73453,114.13792V579.20792A22.278,22.278,0,0,1,975.24752,601.48515ZM759.901,556.93069H952.9703V440.59406c0-10.61746-12.45553-50.117-37.27375-91.03013-24.28837-40.03809-51.1332-64.91046-70.06111-64.91046H759.901Z" />
        <path d="M632.67327,730.198H529.703a22.27723,22.27723,0,1,1,0-44.55446h102.9703a22.27723,22.27723,0,1,1,0,44.55446Z" />
      </g>
    </svg>
  );
}
