"use client";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "../../components/Link";
import { slugToText } from "../../utils/helpers/utils";
import _capitalize from "lodash/capitalize";
import { Dict } from "mixpanel-browser";
import { ExtractDocInfoV2 } from "../dac/helpers";
import { BreadcrumbData, getBreadcrumbs } from "../../api/khub/v2/documents/breadcrumbs";
import { useEffect, useState } from "react";
import { TESTID } from "./enums";
import { useQuery } from "react-query";
import { breadcrumbsQueryKey } from "../../api/khub/v2/query-key";
import { Event, Feature, View } from "../../telemetry/enums";
import { FileAdd24 } from "@bphxd/ds-core-react/lib/icons";
import { KHubModalAction, useModalActions } from "../../hooks/khub/use-modal-store";
import { Button } from "../../components/button";

export const KHUB_LIBRARY_HREF = "/khub/library";

function createBreadcrumbLinks(breadcrumbs: BreadcrumbData[], trackingEventProps: Dict | undefined) {
  const excludeBreadcrumbItems = ["knowledgehub"];
  return breadcrumbs.map((breadcrumbItem, index) => {
    const bcUuid = breadcrumbItem.uuid;
    const isActive = breadcrumbs.length === index + 1;
    const crumbsToExclude = excludeBreadcrumbItems.map((items) => items.toLowerCase());

    if (crumbsToExclude.includes(breadcrumbItem.title.toLowerCase())) {
      return null;
    }
    let href = "/knowledgehub/" + bcUuid;
    if (!bcUuid) {
      href = "/dac/" + breadcrumbItem.path.replace(".md", "");
    }
    return (
      <BreadcrumbItem key={index} active={isActive}>
        {isActive ? (
          createBreadcrumbText(breadcrumbItem.title)
        ) : (
          <Link href={href} trackingEventProps={{ ...trackingEventProps, href }}>
            {createBreadcrumbText(breadcrumbItem.title)}
          </Link>
        )}
      </BreadcrumbItem>
    );
  });
}

function createBreadcrumbText(breadcrumbTitle: string) {
  return _capitalize(slugToText(breadcrumbTitle));
}

export function BreadcrumbContainer({ documentPath }: { documentPath: string }) {
  const { uuid } = ExtractDocInfoV2();
  const { setIsKHubPageModalOpen, setKHubModalAction, setKHubModalPath, setKHubModalUuid } = useModalActions();
  const trackingEventProps = (name: string, feature: string) => ({
    name: name,
    view: View.Khub,
    feature: feature,
    page_uuid: uuid,
    page_path: documentPath,
  });
  const [breadcrumbItems, setBreadcrumbItems] = useState<(JSX.Element | null)[]>([]);
  const [isError, setIsError] = useState(false);
  const { isLoading, data } = useQuery(breadcrumbsQueryKey(uuid), async () => {
    const response = await getBreadcrumbs(uuid);
    if (!response.ok) {
      setIsError(true);
      return;
    }
    return response.body?.breadcrumbs;
  });

  useEffect(() => {
    setBreadcrumbItems(createBreadcrumbLinks(data ?? [], trackingEventProps(Event.LinkClick, Feature.Breadcrumbs)));
  }, [data]);

  return (
    <div className="mt-4">
      <Breadcrumb data-testid={TESTID.breadcrumbs}>
        <BreadcrumbItem>
          <Link
            href={KHUB_LIBRARY_HREF}
            trackingEventProps={{
              ...trackingEventProps(Event.LinkClick, Feature.Breadcrumbs),
              href: KHUB_LIBRARY_HREF,
            }}
          >
            Library
          </Link>
        </BreadcrumbItem>
        {isLoading && <BreadcrumbItem>Loading...</BreadcrumbItem>}
        {isError && <BreadcrumbItem>⚠️🤖 Something went wrong!</BreadcrumbItem>}
        {!isLoading && !isError && breadcrumbItems}
        {!isLoading && !isError && (
          <BreadcrumbItem>
            <Button
              Icon={FileAdd24}
              theme="standard"
              level="quartenary"
              rounded="pill"
              className="px-0"
              size="xs"
              trackingEventProps={{
                name: Event.ButtonClick,
                view: View.Khub,
                feature: "create_subpage_breadcrumb_button",
              }}
              onClick={() => {
                setIsKHubPageModalOpen(true);
                setKHubModalAction(KHubModalAction.Create);
                setKHubModalPath(documentPath.split(".md")[0]);
                setKHubModalUuid(uuid);
              }}
            >
              Create subpage
            </Button>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
}
