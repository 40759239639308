export enum KendraSearchFilters {
  All = "All",
  Code = "Code",
  Automation = "Automation",
  Documents = "Documents",
  FAQ = "FAQ",
  KDD = "KDD",
}

export enum AdvancedSearchFilters {
  Code = "Snippet",
  Automation = "Automation",
  Documents = "Documents",
  FAQ = "FAQ",
  KDD = "KDD",
}

export type AllFilters = KendraSearchFilters | AdvancedSearchFilters;

export enum KendraFilterValues {
  Code = "CODE",
  Automation = "AUTOMATION",
  Documents = "DOCUMENT",
  FAQ = "DEVOPS_FAQ",
  KDD = "kdd-template.md",
}

export function getSearchFilterByCategory(category: string) {
  return Object.keys(KendraFilterValues).find(
    (key) => KendraFilterValues[key as keyof typeof KendraFilterValues] === category,
  ) as keyof typeof KendraFilterValues;
}

export enum FilterBy {
  Category = "_category",
  DocumentType = "document_type",
}

enum FilterType {
  Equals = "EqualsTo",
}

type FilterValues = {
  StringValue: string;
};

type AdvancedSearchFilterValues = {
  filter_by: FilterBy;
  filter_type: FilterType.Equals;
  filter_values: FilterValues;
};

export function generateSearchFilterParams(category: AdvancedSearchFilters) {
  const { filter_by, filter_type, filter_values } = advancedSearchFilterQueryParams(category);
  return { [filter_type]: { Key: filter_by, Value: filter_values } };
}

export function generateSearchFilters(
  StringValue: string,
  FilterByAttribute: FilterBy = FilterBy.Category,
): AdvancedSearchFilterValues {
  return {
    filter_by: FilterByAttribute,
    filter_type: FilterType.Equals,
    filter_values: {
      StringValue,
    },
  };
}

export function kendraSearchFilterQueryParams(searchFilter: KendraSearchFilters) {
  switch (searchFilter) {
    case KendraSearchFilters.All:
      return undefined;
    case KendraSearchFilters.Code:
      return generateSearchFilters(KendraFilterValues.Code);
    case KendraSearchFilters.Automation:
      return generateSearchFilters(KendraFilterValues.Automation);
    case KendraSearchFilters.Documents:
      return generateSearchFilters(KendraFilterValues.Documents);
    case KendraSearchFilters.FAQ:
      return generateSearchFilters(KendraFilterValues.FAQ);
    case KendraSearchFilters.KDD:
      return generateSearchFilters(KendraFilterValues.KDD, FilterBy.DocumentType);
  }
}

export function advancedSearchFilterQueryParams(searchFilter: AdvancedSearchFilters) {
  switch (searchFilter) {
    case AdvancedSearchFilters.Code:
      return generateSearchFilters(KendraFilterValues.Code);
    case AdvancedSearchFilters.Automation:
      return generateSearchFilters(KendraFilterValues.Automation);
    case AdvancedSearchFilters.Documents:
      return generateSearchFilters(KendraFilterValues.Documents);
    case AdvancedSearchFilters.FAQ:
      return generateSearchFilters(KendraFilterValues.FAQ);
    case AdvancedSearchFilters.KDD:
      return generateSearchFilters(KendraFilterValues.KDD, FilterBy.DocumentType);
  }
}
