import { DateTime } from "luxon";
import { TagOption, mapTagsToSelectOptions } from "../../api/common-tags-functionality";
import { sAxelUrlAndQuery } from "../search-types";
import * as yup from "yup";

export type FormValues = {
  advanced_search_author?: string;
  advanced_search_contains?: string;
  advanced_search_contains_exact: boolean;
  advanced_search_date?: string;
  advanced_search_tags?: TagOption[];
  advanced_search_data_sources?: TagOption[];
  advanced_search_include_retired_content: boolean;
  advanced_search_document_type?: TagOption[];
};

export type DefaultAdvancedSearchFormValues = {
  dataSources: TagOption[];
  tags: TagOption[];
  author?: string;
  defaultDate: Date[];
  cleanedSearchFormQuery: string;
  isExactPhrase: boolean;
};
const DATA_SOURCE_REGEX_PATTERN = /data_source_name:"([^"]+)"/;
const TAGS_REGEX_PATTERN = /tags:"([^"]+)"/;
const DATE_REGEX_PATTERN = /_last_updated_at:\[(\d{4}-\d{2}-\d{2}) TO (\d{4}-\d{2}-\d{2})]/;
const AUTHOR_REGEX_PATTERN = /_authors:"([^"]+)"/;
const EXACT_PHRASE_REGEX_PATTERN = /^(['"])(.*)(\1)$/;

/**
 * Checks whether phrase is wrapped in quotes or not
 *
 * @returns `true` if wrapped in quotation marks, `false` otherwise
 * @param phrase
 */
export function isExactPhrase(phrase: string) {
  return EXACT_PHRASE_REGEX_PATTERN.test(phrase.trim());
}

/**
 * Returns a YYYY-MM-DD string representation of a date object
 *
 * @returns YYYY-MM-DD formatted date string
 * @param date
 */
export function formatDateToYYYYMMDD(date: Date) {
  return DateTime.fromJSDate(date).toISODate();
}

export const formSchema = yup
  .object({
    advanced_search_contains: yup.string(),
    advanced_search_contains_exact: yup.boolean(),
    advanced_search_date: yup.string(),
    advanced_search_tags: yup.array().min(0),
    advanced_search_data_sources: yup.array().min(0),
    advanced_search_include_retired_content: yup.boolean(),
  })
  .required();

/**
 * Creates an advanced search URL from the form values and date range. The form values should come from the advanced search modal. If used outside of modal, make sure you provide the `advaced_search_date` string to be able to use `dateFrom` and `dateTo` filters
 *
 * @returns: the encoded advanced search URL with advanced filters added (if any)
 * @param dateFrom
 * @param dateTo
 * @param formValues
 * @param chatId
 */
export function createAdvancedSearchUrl(
  dateFrom: Date | undefined,
  dateTo: Date | undefined,
  formValues: FormValues,
  chatId?: string,
) {
  const {
    advanced_search_author,
    advanced_search_contains,
    advanced_search_contains_exact,
    advanced_search_date,
    advanced_search_tags,
    advanced_search_data_sources,
  } = formValues;
  const filters: string[] = [
    advanced_search_author && `_authors:"${advanced_search_author}"`,
    advanced_search_data_sources &&
      advanced_search_data_sources
        .map((dataSource: TagOption) => `data_source_name:"${dataSource.value}"`)
        .join(" OR "),
    advanced_search_tags && advanced_search_tags.map((tag: TagOption) => `tags:"${tag.value}"`).join(" OR "),
    advanced_search_date &&
      dateFrom &&
      dateTo &&
      `_last_updated_at:[${formatDateToYYYYMMDD(dateFrom)} TO ${formatDateToYYYYMMDD(dateTo)}]`,
  ].filter(Boolean) as string[];

  let searchQuery: string = advanced_search_contains
    ? advanced_search_contains_exact && !/^".+"$/.test(advanced_search_contains.trim())
      ? `"${advanced_search_contains}"`
      : advanced_search_contains
    : "";

  searchQuery = addFiltersToSearchQuery(filters, searchQuery);

  return sAxelUrlAndQuery(searchQuery, chatId);
}

/**
 * Adds advanced search filters to the search query
 * @returns search query with advanced search filters added (if any)
 * @param filters
 * @param searchQuery
 */
function addFiltersToSearchQuery(filters: string[], searchQuery: string) {
  if (filters.length) {
    if (filters.length === 1) {
      if (filters[0].includes(" OR ")) {
        searchQuery += " (" + filters[0] + ")";
      } else {
        searchQuery += " " + filters[0];
      }
    } else {
      searchQuery += " (" + filters.join(" AND ") + ")";
    }
  }
  return searchQuery;
}

/**
 * adds a global flag to a regex pattern
 *
 * @returns: new regex pattern with the global flag added to it
 * @param regexPattern
 */
function globalRegex(regexPattern: RegExp) {
  return RegExp(regexPattern, "g");
}

export function getCleanedSearchFormQuery(searchFormQuery: string) {
  let cleanedSearchFormQuery = searchFormQuery
    .replaceAll(globalRegex(/ AND /), "")
    .replaceAll(globalRegex(/ OR /), "")
    .replaceAll(globalRegex(DATA_SOURCE_REGEX_PATTERN), "")
    .replaceAll(globalRegex(TAGS_REGEX_PATTERN), "")
    .replaceAll(globalRegex(AUTHOR_REGEX_PATTERN), "")
    .replaceAll(globalRegex(DATE_REGEX_PATTERN), "")
    .replaceAll(/\(\s*\)/g, "")
    .trim();
  const isSearchQueryExactPhrase = isExactPhrase(cleanedSearchFormQuery);
  cleanedSearchFormQuery = cleanedSearchFormQuery.replace(globalRegex(EXACT_PHRASE_REGEX_PATTERN), "$2");
  return {
    cleanedSearchFormQuery,
    isSearchQueryExactPhrase,
  };
}

/**
 * Extracts the filters from search query and removes them from the search query text
 *
 * @param searchFormQuery
 * @returns DefaultAdvancedSearchFormValues object containing the filters and cleaned query
 */
export function getAdvancedSearchModalValuesFromSearchQuery(searchFormQuery: string) {
  const dataSources: TagOption[] = mapTagsToSelectOptions(
    [...searchFormQuery.matchAll(globalRegex(DATA_SOURCE_REGEX_PATTERN))].map((match) => match[1]) ?? [],
  );
  const tags: TagOption[] = mapTagsToSelectOptions(
    [...searchFormQuery.matchAll(globalRegex(TAGS_REGEX_PATTERN))].map((match) => match[1]) ?? [],
  );
  const author: string | undefined = searchFormQuery.match(AUTHOR_REGEX_PATTERN)?.[1];
  const dates = searchFormQuery.match(DATE_REGEX_PATTERN)?.slice(1);
  const defaultDate = dates ? dates.map((dateStr) => new Date(dateStr)) : [];
  const { cleanedSearchFormQuery, isSearchQueryExactPhrase } = getCleanedSearchFormQuery(searchFormQuery);
  return {
    dataSources,
    tags,
    author,
    defaultDate,
    cleanedSearchFormQuery,
    isExactPhrase: isSearchQueryExactPhrase,
  } as DefaultAdvancedSearchFormValues;
}
