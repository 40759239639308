import { css } from "@emotion/react";
import styled from "@emotion/styled";

const getBorder = ({ border }) => css(border ? `border: 1px solid #d8d8d8` : "");

const getRadius = ({ borderRadius }) => {
  return `border-radius: ${typeof borderRadius === "number" ? `${borderRadius}px` : `${borderRadius / 16}rem`};`;
};

const getPadding = ({ padding }) => {
  const newPad = Array.isArray(padding) ? padding : padding ? [padding] : [];

  return css(newPad.length ? `padding: ${newPad.map((pad) => `${pad / 16}rem`).join(" ")};` : "");
};

const getBackground = ({ backgroundImage }) => {
  const image = `../images/${backgroundImage}`;
  return backgroundImage && css(`background: url(${image}) no-repeat right 30px`);
};

const CardWidthListContainer = styled.div`
  width: 100%;
  margin-bottom: 56px;

  a:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }

  ${getPadding}
  ${getBackground}
  ${getBorder}
  ${getRadius}
`;
const FullWidthListContainer = styled(CardWidthListContainer)`
  a {
    border-bottom: 1px solid #f2f2f2;
  }
`;

const ListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const Text = styled.div`
  margin-right: 16px;
  font-size: 1rem;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  color: #111;
`;

const Description = styled.div`
  margin-right: 16px;
  font-size: 1rem;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  color: #111;
  opacity: 0.7;
`;

const style = {
  CardWidthListContainer,
  FullWidthListContainer,
  ListGrid,
  Text,
  Description,
};

export default style;
