import { env } from "next-runtime-env";

export const tenantID = env("NEXT_PUBLIC_AZURE_TENANT_ID") || "ea80952e-a476-42d4-aaf4-5457852b0f7e";

export const AZURE_APP_REG_CLIENT_IDS = {
  search: env("NEXT_PUBLIC_AZURE_SEARCH_CLIENT_ID") || "5efb78ff-0ec1-4a2b-a3fa-71331caf4784",
  apollo: env("NEXT_PUBLIC_APOLLO_CLIENT_ID") || "5efb78ff-0ec1-4a2b-a3fa-71331caf4784",
  knowledgehub: env("NEXT_PUBLIC_KNOWLEDGEHUB_CLIENT_ID") || "5efb78ff-0ec1-4a2b-a3fa-71331caf4784",
};

export const msalConfig = {
  auth: {
    clientId: env("NEXT_PUBLIC_AZURE_CLIENT_ID") || "95b4abe2-1d51-4aed-a7e8-afcef9ad9313",
    authority: `https://login.microsoftonline.com/${tenantID}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
