import { SVGProps } from "react";

export function FileTileCode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Diversity_and_inclusion"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <title>file-tile-code</title>
      <path
        d="M863.3,64.3c-14.1-28.2-44-61.8-106.6-61.8H375.9c-7.8,0-13.7,3-17.7,7.4l0,0L127.9,266.3c-4.3,4.2-6.7,9.9-6.6,15.9v594.1&#10;&#9;c0.6,20.6,5.6,40.9,14.7,59.5c14.1,28.2,44,61.8,106.6,61.8h514.2c20.6-0.6,40.9-5.6,59.5-14.7c28.2-14.1,61.8-44,61.8-106.6V123.8&#10;&#9;C877.5,103.1,872.4,82.8,863.3,64.3z M371.8,61.4v163.4c0,30.3-21.1,54.9-47,54.9h-149L371.8,61.4z M833.5,876.2&#10;&#9;c0,31.9-11.8,53.5-36,66.1c-19.8,10.3-40.6,10.6-40.7,10.6H242.6c-31.9,0-53.5-11.8-66.1-36c-10.3-19.8-10.6-40.6-10.6-40.7v-552&#10;&#9;h159c50.5,0,91.6-44.6,91.6-99.4V47h340.4c31.9,0,53.5,11.8,66.1,36c10.3,19.8,10.6,40.6,10.6,40.7L833.5,876.2z M735.8,556.1&#10;&#9;c5.7,7.8,5.7,18.5,0,26.3L636,718.6c-7.3,9.9-21.2,12.1-31.1,4.8c-9.9-7.3-12.1-21.2-4.8-31.1c0,0,0,0,0,0l90.2-123l-90.2-123&#10;&#9;c-7.3-9.9-5.1-23.9,4.8-31.1c9.9-7.3,23.9-5.1,31.1,4.8L735.8,556.1z M400,446.3l-90.2,123l90.2,123c7.3,9.9,5.2,23.9-4.7,31.1&#10;&#9;c-9.9,7.3-23.9,5.2-31.1-4.7c0,0,0,0,0,0l-99.8-136.1c-5.7-7.8-5.7-18.5,0-26.3L364,420c7.3-9.9,21.2-12.1,31.1-4.8&#10;&#9;C405.1,422.5,407.3,436.4,400,446.3L400,446.3z M576.1,356.9L467.2,792.5c-3,11.9-15.1,19.2-27,16.2s-19.2-15.1-16.2-27l108.9-435.6&#10;&#9;c3-11.9,15.1-19.2,27-16.2C571.8,332.8,579.1,344.9,576.1,356.9C576.1,356.9,576.1,356.9,576.1,356.9L576.1,356.9z"
      />
    </svg>
  );
}
