import { gql } from "@apollo/client";

export const GET_APPROVED_QUESTIONS = gql`
  query GetApprovedQuestions {
    approvedQuestions {
      body
      author
      title
      tags
      answers
      comments
      createdAt
      deletedAt
      id
      status
      updatedAt
      updatedBy
      upvotes
    }
  }
`;

export const GET_USER_QUESTIONS = gql`
  query AllUserQuestions($hideInReview: Boolean, $hideApproved: Boolean, $hideRejected: Boolean, $user: String!) {
    userQuestions(hideInReview: $hideInReview, hideApproved: $hideApproved, hideRejected: $hideRejected, user: $user) {
      body
      author
      title
      tags
      answers
      comments
      createdAt
      deletedAt
      id
      status
      updatedAt
      updatedBy
      upvotes
    }
    approvedQuestions {
      body
      author
      title
      tags
      answers
      comments
      createdAt
      deletedAt
      id
      status
      updatedAt
      updatedBy
      upvotes
    }
  }
`;

export const GET_QUESTIONS = gql`
  query Questions($hideInReview: Boolean, $hideApproved: Boolean, $hideRejected: Boolean) {
    questions(hideInReview: $hideInReview, hideApproved: $hideApproved, hideRejected: $hideRejected) {
      body
      author
      title
      tags
      answers
      comments
      createdAt
      deletedAt
      id
      status
      updatedAt
      updatedBy
      upvotes
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags($hideInReviewQuestions: Boolean, $hideApprovedQuestions: Boolean, $hideRejectedQuestions: Boolean) {
    tags(
      hideInReviewQuestions: $hideInReviewQuestions
      hideApprovedQuestions: $hideApprovedQuestions
      hideRejectedQuestions: $hideRejectedQuestions
    ) {
      name
      questionCount
    }
  }
`;

export const GET_GLOBAL_TAGS = gql`
  query AllGlobalTags {
    allTags {
      tags
    }
  }
`;

export const GET_TAG = gql`
  query GetTag(
    $name: String!
    $hideInReviewQuestions: Boolean
    $hideApprovedQuestions: Boolean
    $hideRejectedQuestions: Boolean
  ) {
    tag(
      name: $name
      hideInReviewQuestions: $hideInReviewQuestions
      hideApprovedQuestions: $hideApprovedQuestions
      hideRejectedQuestions: $hideRejectedQuestions
    ) {
      name
      questionCount
      questions {
        answers
        author
        body
        comments
        createdAt
        deletedAt
        id
        status
        tags
        title
        updatedAt
        updatedBy
        upvotes
      }
    }
  }
`;

export const ADD_TAG = gql`
  mutation AddTag($name: String!) {
    addTag(name: $name) {
      createdAt
      deletedAt
      name
      questionCount
      questions
      updatedAt
      updatedBy
    }
  }
`;

export const ADD_QUESTION = gql`
  mutation AddQuestion($data: AddQuestionInput!) {
    addQuestion(data: $data) {
      answers
      author
      body
      comments
      createdAt
      deletedAt
      id
      tags
      status
      title
      updatedAt
      updatedBy
      upvotes
    }
  }
`;

export const GET_QUESTION = gql`
  query Question($questionId: String!) {
    question(id: $questionId) {
      id
      createdAt
      updatedBy
      updatedAt
      author
      title
      body
      tags
      userHasUpvoted
      upvotes
      answers {
        id
        createdAt
        updatedAt
        updatedBy
        deletedAt
        author
        body
        helpfulVotes
        unhelpfulVotes
        question
        userVotedHelpful
        userVotedUnhelpful
      }
      comments {
        id
        createdAt
        updatedAt
        updatedBy
        deletedAt
        author
        body
        question
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($data: UpdateQuestionInput!) {
    updateQuestion(data: $data) {
      body
      tags
      title
      id
    }
  }
`;

export const APPROVE_QUESTION = gql`
  mutation ApproveQuestion($id: String!) {
    approveQuestion(id: $id) {
      id
      status
    }
  }
`;

export const REJECT_QUESTION = gql`
  mutation RejectQuestion($id: String!) {
    rejectQuestion(id: $id) {
      id
      status
    }
  }
`;

export const UNREJECT_QUESTION = gql`
  mutation ResetQuestionStatus($id: String!) {
    resetQuestionStatus(id: $id) {
      id
      status
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($deleteQuestionId: String!) {
    deleteQuestion(id: $deleteQuestionId) {
      id
      deletedAt
    }
  }
`;

export const ADD_ANSWER = gql`
  mutation AddAnswer($data: AddAnswerInput!) {
    addAnswer(data: $data) {
      author
      body
      createdAt
      deletedAt
      helpfulVotes
      id
      question
      unhelpfulVotes
      updatedAt
      updatedBy
      userVotedHelpful
      userVotedUnhelpful
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation UpdateAnswer($data: UpdateAnswerInput!) {
    updateAnswer(data: $data) {
      author
      body
      createdAt
      deletedAt
      id
      helpfulVotes
      question
      unhelpfulVotes
      updatedAt
      updatedBy
      userVotedHelpful
      userVotedUnhelpful
    }
  }
`;

export const DELETE_ANSWER = gql`
  mutation DeleteAnswer($deleteAnswerId: String!) {
    deleteAnswer(id: $deleteAnswerId) {
      body
      deletedAt
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($data: AddCommentInput!) {
    addComment(data: $data) {
      id
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($deleteCommentId: String!) {
    deleteComment(id: $deleteCommentId) {
      id
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($data: UpdateCommentInput!) {
    updateComment(data: $data) {
      id
    }
  }
`;

export const DO_UPVOTE = gql`
  mutation upvoteQuestion($questionId: String!) {
    upvoteQuestion(id: $questionId) {
      upvotes
      userHasUpvoted
    }
  }
`;

export const UNDO_UPVOTE = gql`
  mutation removeQuestionUpvote($questionId: String!) {
    removeQuestionUpvote(id: $questionId) {
      upvotes
      userHasUpvoted
    }
  }
`;

export const MARK_ANSWER_HELPFUL = gql`
  mutation MarkAnswerHelpful($markAnswerHelpfulId: String!) {
    markAnswerHelpful(id: $markAnswerHelpfulId) {
      id
      helpfulVotes
      unhelpfulVotes
      userVotedHelpful
      userVotedUnhelpful
    }
  }
`;

export const MARK_ANSWER_UNHELPFUL = gql`
  mutation MarkAnswerUnhelpful($markAnswerUnhelpfulId: String!) {
    markAnswerUnhelpful(id: $markAnswerUnhelpfulId) {
      id
      helpfulVotes
      unhelpfulVotes
      userVotedHelpful
      userVotedUnhelpful
    }
  }
`;

export const UNMARK_ANSWER_HELPFUL = gql`
  mutation UnmarkAnswerHelpful($unmarkAnswerHelpfulId: String!) {
    unmarkAnswerHelpful(id: $unmarkAnswerHelpfulId) {
      id
      helpfulVotes
      unhelpfulVotes
      userVotedHelpful
      userVotedUnhelpful
    }
  }
`;

export const UNMARK_ANSWER_UNHELPFUL = gql`
  mutation UnmarkAnswerUnhelpful($unmarkAnswerUnhelpfulId: String!) {
    unmarkAnswerUnhelpful(id: $unmarkAnswerUnhelpfulId) {
      unhelpfulVotes
      userVotedHelpful
      userVotedUnhelpful
      id
      helpfulVotes
    }
  }
`;
