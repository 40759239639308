import { Badge } from "./badges/badge";
import { BadgeProps } from "@bphxd/ds-core-react";
import { DevOpsCapabilityIcon } from "./devops-capability-icon";

type CategoryBadgeProps = {
  category: string;
  color?: BadgeProps["color"];
};

export default function CategoryBadge({ category, color }: CategoryBadgeProps) {
  return category ? (
    <Badge
      color={color}
      icon={<DevOpsCapabilityIcon capabilityEnum={category.replace(/[- ]/g, "_")} />}
      label={category}
    />
  ) : null;
}
