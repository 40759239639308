import { components } from "react-select";
import { Down16, Remove16 } from "@bphxd/ds-core-react/lib/icons";
import classnames from "classnames";

// react-select internal component replacements

// ------------------------------------------------------------------

// Chip / Multi Value

// add color classes to chip, see chip-options.js for options array examples

const MultiValueContainer = ({ innerProps, ...props }) => {
  const { color, outline } = props.data;
  innerProps.className = classnames("chip", `chip${outline ? "-outline" : ""}-${color || "light"}`);

  return <components.MultiValueContainer {...props} innerProps={innerProps} />;
};

// add icon to chip label

const MultiValueLabel = ({ children, innerProps, ...props }) => {
  const { Icon, color, darkIcon } = props.data;
  innerProps.className = "chip-label";

  // dark icon override for default solid light chips
  const isSolidLight = color && color !== "dark" && !color.startsWith("darker") && !color.startsWith("outline");
  const iconClasses = classnames(isSolidLight && darkIcon && "text-dark-gray-500");

  return (
    <components.MultiValueLabel {...props} innerProps={innerProps}>
      {Icon && <Icon width="19" height="19" strokeWidth="2" className={iconClasses} />}
      {children}
    </components.MultiValueLabel>
  );
};

// replace X icon for chip

const MultiValueRemove = ({ innerProps, ...props }) => {
  innerProps.className = "chip-remove";
  return (
    <components.MultiValueRemove {...props} innerProps={innerProps}>
      <Remove16 />
    </components.MultiValueRemove>
  );
};

// ------------------------------------------------------------------

// replace clear indicator X icon

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Remove16 style={{ width: "20px", height: "20px" }} />
  </components.ClearIndicator>
);

// replace clear indicator X icon

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Down16 style={{ width: "20px", height: "20px" }} />
  </components.DropdownIndicator>
);

/* eslint-disable import/no-anonymous-default-export */
export default {
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  ClearIndicator,
  DropdownIndicator,
};

export const NoIndicatorSeparator = () => <></>;
