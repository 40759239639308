"use client";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { BtnData, NavBtns } from "../../components/button-groups/nav-btns";
import { TagBar } from "../../components/badges/tag-bar";
import { ExtractDocInfoV2 } from "../dac/helpers";
import { getAdjacentDocuments } from "../../api/khub/v2/adjacent";
import { adjacentDocsQueryKey } from "../../api/khub/v2/query-key";
import { useTags } from "../../hooks/khub/use-page-store";

export function uuidToHref(uuid?: string | null) {
  if (!uuid) {
    return "";
  } else {
    return `/knowledgehub/${uuid}`;
  }
}

type PageFooterProps = {
  isAuthenticated: boolean;
};
export function PageFooter({ isAuthenticated }: PageFooterProps) {
  const { uuid } = ExtractDocInfoV2();
  const contentTags = useTags();

  const [prevBtnData, setPrevBtnData] = useState<BtnData>();
  const [nextBtnData, setNextBtnData] = useState<BtnData>();

  const {
    isLoading: adjacentDocsAreLoading,
    isFetching,
    data,
  } = useQuery(
    adjacentDocsQueryKey(uuid),
    async () => {
      try {
        const adjacentDocs = await getAdjacentDocuments(uuid);
        return adjacentDocs;
      } catch (error: any) {
        throw Error(error);
      }
    },
    { enabled: isAuthenticated },
  );

  useEffect(() => {
    setNextBtnData(
      data?.next
        ? {
            href: uuidToHref(data.next.uuid),
            title: data.next.title,
          }
        : undefined,
    );
  }, [data?.next]);
  useEffect(() => {
    setPrevBtnData(
      data?.previous
        ? {
            href: uuidToHref(data.previous.uuid),
            title: data.previous.title,
          }
        : undefined,
    );
  }, [data?.previous]);

  const showTagbar = contentTags && contentTags.length > 0;
  return (
    <div className="kh-page-footer">
      <NavBtns isLoading={adjacentDocsAreLoading || isFetching} prevBtnData={prevBtnData} nextBtnData={nextBtnData} />
      {showTagbar && (
        <>
          <hr className="my-3" />
          <TagBar contentTags={contentTags ?? []} />
        </>
      )}
    </div>
  );
}
