"use client";
import { useState, useEffect } from "react";
import { useUser } from "../../hooks/use-user";
import { useWebsocket } from "../../hooks/use-websocket";
import {
  NEW_NOTIFICATION_ENUM,
  NOTIFICATION_LIST_ENUM,
  REQUEST_NOTIFICATION_LIST_ENUM,
  UPDATE_NOTIFICATION_READ_STATUS_ENUM,
  UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM,
} from "./notification-enums";
import styled from "@emotion/styled";
import { NotificationList } from "./notification-list";

const Container = styled.div`
  padding: 0 12px;

  .notification-tray {
    padding: 24px 18px;
    width: 100%;
    height: 100%;
  }

  .notification-tray__list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;

    button {
      padding: 0;
      margin-bottom: 8px;
    }
  }

  .no-messages,
  .notification-message {
    font-size: 16px;
    margin: 0 auto;
    min-height: 100%;
  }

  .no-messages {
    position: relative;
    pointer-events: none;
  }

  .loading-spinner {
    height: 75px;
    width: 75px;
    margin: auto;
    position: relative;
    bottom: 2rem;
    pointer-events: none;
  }

  .notification-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #fff;
    border: solid 2px #ededed;
    opacity: 0.85;

    &:hover {
      background-color: rgb(255, 255, 255, 0.8);
      opacity: 1;
    }
  }
  .notification-message__text {
    display: flex;
    flex-direction: column;
  }
  .notification-message__text__title {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62em;
    letter-spacing: 0.27px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 264px;

    white-space: initial;
    max-height: 4.86em;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .notification-message__text__date {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.25px;
    color: rgba(17, 17, 17, 0.7);
  }

  .notification-message__indicator {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .notification-message__indicator__dot {
    width: 10px;
    height: 10px;
    background-color: #4fc3f7;
    border-radius: 100%;
  }
`;

export default function LeftSideNotification() {
  const user = useUser();
  const websocket = useWebsocket();
  const [notifications, setNotifications] = useState([]);

  function requestNotifications() {
    websocket?.emit(REQUEST_NOTIFICATION_LIST_ENUM);
  }

  function parseNotificationList({ notifications }: { notifications: [] }) {
    setNotifications(notifications);
  }

  function handleNotificationClick(notificationId: string) {
    websocket?.emit(UPDATE_NOTIFICATION_READ_STATUS_ENUM, notificationId);
    websocket?.emit(UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM);
  }

  useEffect(() => {
    if (websocket && !!user?.email) {
      requestNotifications();
      websocket?.on(NEW_NOTIFICATION_ENUM, requestNotifications);
      websocket?.on(NOTIFICATION_LIST_ENUM, parseNotificationList);

      return () => {
        websocket?.off(NEW_NOTIFICATION_ENUM, requestNotifications);
        websocket?.off(NOTIFICATION_LIST_ENUM, parseNotificationList);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, websocket]);

  return (
    <Container>
      <div className="notification-tray">
        <div className="notification-tray__list">
          <NotificationList notifications={notifications} handleNotificationClick={handleNotificationClick} />
        </div>
      </div>
    </Container>
  );
}
