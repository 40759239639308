import { PropsWithChildren } from "react";
import { env } from "next-runtime-env";

export const HttpsRedirect = ({ children }: PropsWithChildren) => {
  if (
    process.env.NODE_ENV === "production" &&
    !env("NEXT_PUBLIC_HTTPS_REDIRECT_DISABLED") &&
    typeof window !== "undefined" &&
    window.location &&
    window.location.protocol === "http:"
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, "https");
    return null;
  }

  return <>{children}</>;
};
