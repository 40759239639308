"use client";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { trackEvent } from "../../utils/event-tracker";
import { Form, Row, Col } from "reactstrap";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Button } from "../../components/button";
import { Modal } from "../../components/modal";

const DATA_TESTIDS = {
  typeInput: "notification",
  submitButton: "notification-submit-button",
};

export type NotificationInputs = {
  notificationText: string;
  userEmail?: string;
  url?: string;
};

type SendNotificationModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: NotificationInputs) => void;
  loading: boolean;
  globalNotification: boolean;
};

const defaultValues = {
  notificationText: "",
  userEmail: "",
};

export function SendNotificationModal({
  show,
  onHide,
  onSubmit,
  loading,
  globalNotification,
}: SendNotificationModalProps) {
  const [isDirty, setIsDirty] = useState(false);

  const globalSchemaObject = {
    notificationText: yup.string().trim().required("Required"),
    url: yup.string().trim(),
  };

  const schemaObject = {
    notificationText: yup.string().trim().required("Required"),
    userEmail: yup.string().trim().required("Required"),
    url: yup.string().trim(),
  };

  const schema = yup.object(globalNotification ? globalSchemaObject : schemaObject).required();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<NotificationInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    values: {
      ...defaultValues,
    },
  });

  function serializeValues(data: NotificationInputs) {
    onSubmit(data);
    reset({
      ...defaultValues,
    });
  }

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `add-survey-modal-opened`,
      });
    }
  }, [show]);

  useEffect(() => {
    const subscription = watch(() => setIsDirty(true));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty}
      onClose={() => {
        setIsDirty(false);
        onHide();
      }}
      header="Send Notification To User"
    >
      <Form onSubmit={handleSubmit(serializeValues)}>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="notificationText">Notification Text</Label>
              <Controller
                name="notificationText"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="notificationText"
                    data-testid={DATA_TESTIDS.typeInput}
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
          {!globalNotification && (
            <Col xs="4">
              <FormGroup className="without-margin-top">
                <Label htmlFor="userEmail">User Email</Label>
                <Controller
                  name="userEmail"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="userEmail"
                      type="text"
                      {...field}
                      error={error?.message}
                      invalid={!!error}
                      makeRoomForError
                    />
                  )}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="url">URL</Label>
              <Controller
                name="url"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input id="url" type="text" {...field} error={error?.message} invalid={!!error} makeRoomForError />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col xs="4">
            <div className="text-end">
              <Button
                data-testid={DATA_TESTIDS.submitButton}
                name="submit-new-tag-type"
                type="submit"
                disabled={!isValid}
                loading={isSubmitting || loading}
                level="primary"
                theme="darker"
                rounded="pill"
              >
                Send Notification
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
