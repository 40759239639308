/* eslint-disable no-unused-vars */
export enum TESTID {
  tagSelect = "kh-tag-multi-select",
  saveButton = "kh-editor-save-btn",
  publishButton = "kh-editor-publish-btn",
  tagBar = "kh-content-tags",
  navBtns = "kh-navigation-section",
  gotoLink = "go-to-khub-repo",
  editPageBtn = "edit-markdown-cta",
  pageTitle = "dac-page-title",
  upvoteSection = "kh-upvote-section",
  toc = "dac-table-of-contents",
  tocLink = "dac-toc-link",
  rightHandPanel = "dac-right-hand-panel",
  leftHandNav = "kh-left-hand-navigation",
  navSkeleton = "kh-left-hand-nav-skeleton",
  pageBody = "dac-page-body",
  promoteBtn = "kh-promote-content-toggle",
  nextBtn = "khub-next-button",
  prevBtn = "khub-previous-button",
  titleInputLabel = "khub-title-input-label",
  titleInputField = "khub-title-input-field",
  titleInputError = "khub-title-input-error",
  breadcrumbs = "khub-breadcrumbs",
  reportAProBlemBtn = "report-a-problem-btn",
  activeNavItem = "kh-active-nav-tree-item",
  errorAlert = "kh-error-alert",
  legacyAlert = "khub-legacy-md-renderer-alert",
  basicLicenceCTA = "khub-request-ado-licence",
  toast = "toast",
  khubProductTour = "khub-product-tour",
  editProductTour = "edit-product-tour",
  helpfulButton = "helpful-button",
  unhelpfulButton = "unhelpful-button",
  feedbackPopoverSubmitBtn = "feedback-popover-submit-btn",
  feedbackPopoverTextField = "report-a-problem-textarea",
  feedbackSuccessModal = "report-a-problem-success-modal",
  watchDocButton = "kh-watch-doc-btn",
  editPageSuccessModalLink = "edit-page-from-success-modal",
  statusDropdown = "edit-page-status-dropdown",
  statusDropdownDisabled = "edit-page-status-dropdown-disabled",
  mdxProfilePic = "mdx-profile-pic",
  documentLocked = "edit-page-locked",
  documentDraft = "kh-draft-page",
  axelSuggestTagsButton = "axel-suggest-tags-button",
  axelInfoBanner = "axel-info-banner",
  documentTypeBadge = "document-type-badge",
  technicalDebtBadge = "technical-debt-badge",
  typeDropdown = "document-type-dropdown",
}

export enum ErrorCode {
  PRExists = "PR_EXISTS",
}

export enum IntroJsStep {
  PageTitle,
  TagBar,
}

export enum DocumentStatus {
  DRAFT = "DRAFT",
  UNDER_REVIEW = "UNDER_REVIEW",
  PUBLISHED = "PUBLISHED",
  RETIRED = "RETIRED",
  KDD_FINAL = "KDD_FINAL",
  KDD_UNDER_REVIEW = "KDD_UNDER_REVIEW",
  KDD_IN_PROGRESS = "KDD_IN_PROGRESS",
}

export enum DocumentType {
  GENERIC = "Generic",
  KDD = "kdd-template.md",
}
