type ModalHeaderProps = {
  header: string;
  subHeader?: string;
};

export function ModalHeader({ header, subHeader }: ModalHeaderProps) {
  return (
    <div className="d-flex align-items-center pb-5">
      <div className="position-absolute" id="top" style={{ top: 16 }}>
        <h3 className="mb-0 fw-light">{header}</h3>
        {subHeader ? <h6 className="text-capitalize ls-0">{subHeader}</h6> : null}
      </div>
    </div>
  );
}

export function ModalHeaderWithIcon(icon: JSX.Element, header: string) {
  return (
    <div className="d-flex align-items-center fs-3 fw-light">
      {icon} {header}
    </div>
  );
}
