"use client";
import { useState } from "react";

import { useLocation } from "../hooks/use-location";
import { trackEvent } from "../utils/event-tracker";
import { useAxelChats } from "../views/ask-axel/hooks/use-axel-chats";

import { Button as CoreButton, ButtonProps as CoreButtonProps, Spinner } from "@bphxd/ds-core-react";
import { AxelLogo24, AxelLogo24Prefix } from "../icons/axel";

export type bpCoreButtonProps = CoreButtonProps & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  name?: string;
  size?: string;
  loading?: boolean;
  disabled?: boolean;
  doNotTrack?: boolean;
  trackingEventProps?: Record<string, any>;
};

export function Button({
  className,
  onClick = () => {},
  children,
  name,
  loading = false,
  disabled = false,
  doNotTrack = false,
  trackingEventProps,
  ...rest
}: bpCoreButtonProps) {
  const handleClick = (e: any) => {
    /**
     * If we are tracking then we need a `name` property, either on the
     * Button el, itself, or in the trackingEventProps
     */
    if (!name && !doNotTrack && !trackingEventProps?.name) {
      throw new Error("required name is undefined");
    }

    if (doNotTrack) {
      return onClick(e);
    }

    trackEvent({ name: `button_${name}_click`, ...trackingEventProps });
    onClick(e);
  };

  return (
    <CoreButton className={className} onClick={handleClick} disabled={disabled || loading} {...rest}>
      {loading && <Spinner size="sm" className="btn-icon-prefix" />}
      {children}
    </CoreButton>
  );
}

export const BUTTON_TESTID = "help-me-improve-button";
export function HelpMeImproveMyCapabilityButton({
  capabilityName,
  size = "xs",
  onClick,
  trackingEventProps,
}: {
  capabilityName: string;
  onClick?: () => void;
  trackingEventProps?: Record<string, any>;
} & bpCoreButtonProps) {
  return (
    <AskAxelButton
      chatName={`Improving ${capabilityName} DORA score`}
      chatPrompt={`Help me improve my "${capabilityName}" DORA capability score`}
      onClick={onClick}
      trackingEventProps={trackingEventProps}
      size={size}
      data-testid={BUTTON_TESTID}
      name="help-me-improve-my-product-capbility"
    >
      Help me improve
    </AskAxelButton>
  );
}

export function AskAxelButton({
  chatName,
  chatPrompt,
  onClick,
  trackingEventProps,
  children,
  iconOnly,
  ...rest
}: {
  chatName?: string;
  chatPrompt: string;
  onClick?: () => void;
  trackingEventProps?: Record<string, any>;
  iconOnly?: boolean;
} & bpCoreButtonProps) {
  const { router } = useLocation();
  const { chats, chatsAreLoading } = useAxelChats();
  const [helpMeImproveIsLoading, setHelpMeImproveIsLoading] = useState(false);

  function openAxelChat({ chatName, chatPrompt }: { chatName?: string; chatPrompt: string }) {
    setHelpMeImproveIsLoading(true);

    const existingChatId = chatName ? chats?.find((chat) => chat.name === chatName)?.chatId : undefined;
    if (existingChatId) {
      onClick && onClick();
      router?.push(`/axel?chatId=${existingChatId}`);
      setHelpMeImproveIsLoading(false);
    } else {
      const request = chatPrompt;
      onClick && onClick();
      router?.push(`/axel?new-request=${request}` + (chatName ? `&chat-name=${chatName}` : ""));
      setHelpMeImproveIsLoading(false);
    }
  }

  const icon = iconOnly ? AxelLogo24 : AxelLogo24Prefix;
  return (
    <Button
      onClick={() => openAxelChat({ chatName, chatPrompt })}
      level="tertiary"
      theme="standard"
      rounded="pill"
      Icon={chatsAreLoading || helpMeImproveIsLoading ? undefined : icon}
      iconOnly={iconOnly}
      trackingEventProps={trackingEventProps}
      loading={chatsAreLoading || helpMeImproveIsLoading}
      {...rest}
    >
      {children}
    </Button>
  );
}
