import axios from "axios";

export const productPerceivedAndEmpiricalMetricsQuery = `
  query productPerceivedAndEmpiricalMetricsQuery {
    products {
      name
      repositoryIds
      perceivedMetrics {
        changeFailPercentage
        deployFrequency
        leadTime
        timeToRestore
        updatedDate
      }
      metrics {
        deploymentFrequency {
          periodDeploymentCount
          trendingPeriodDeploymentCount
          lastUpdatedDate
        }
        leadTime {
          leadTimeHours
          trendingLeadTimeHours
          lastUpdatedDate
        }
      }
    }
  }
`;

export type ProductsPerceivedAndEmpiricalMetricsType = {
  products: {
    name: string;
    repositoryIds: string[];
    perceivedMetrics: {
      changeFailPercentage: string | null;
      deployFrequency: string | null;
      leadTime: string | null;
      timeToRestore: string | null;
      updatedDate: number | null;
    };
    metrics: {
      deploymentFrequency: {
        periodDeploymentCount: number;
        trendingPeriodDeploymentCount: number;
        lastUpdatedDate: number;
      } | null;
      leadTime: {
        leadTimeHours: number | null;
        trendingLeadTimeHours: number | null;
        lastUpdatedDate: number;
      } | null;
    };
  }[];
};

export type ProductPerceivedAndEmpiricalMetricsFlatType = {
  name: string;
  repositoryIds: string;
  perceivedChangeFailPercentage: string;
  perceivedDeployFrequency: string;
  perceivedLeadTime: string;
  perceivedTimeToRestore: string;
  perceivedUpdatedDate: string;
  empiricalDeploymentCount: string;
  empiricalTrendingDeploymentCount: string;
  empiricalDeploymentCountLastUpdate: string;
  empiricalLeadTimeHours: string;
  empiricalTrendingLeadTimeHours: string;
  empiricalLeadTimeLastUpdated: string;
};

export const ProductPerceivedAndEmpricalMetricsFlatFields: (keyof ProductPerceivedAndEmpiricalMetricsFlatType)[] = [
  "name",
  "repositoryIds",
  "perceivedChangeFailPercentage",
  "perceivedDeployFrequency",
  "perceivedLeadTime",
  "perceivedTimeToRestore",
  "perceivedUpdatedDate",
  "empiricalDeploymentCount",
  "empiricalTrendingDeploymentCount",
  "empiricalDeploymentCountLastUpdate",
  "empiricalLeadTimeHours",
  "empiricalTrendingLeadTimeHours",
  "empiricalLeadTimeLastUpdated",
];

export const productQuizAssessmentsQuery = `
  query productQuizAssessmentsQuery (
    $limit: Int
    $skip: Int
  ) {
    productsPaginated (
      limit: $limit
      skip: $skip
    ) {
      items {
        id
        name
        numberOfWorkers
        newCapabilityResult {
          quizResults {
            lastUpdated
            score
            maxScore
            quiz {
              slug
            }
          }
        }
      }
      total
    }
  }
`;

export type ProductQuizAssessmentsQueryType = {
  productsPaginated: {
    items: {
      id: string;
      name: string;
      numberOfWorkers: number;
      newCapabilityResult: {
        quizResults: {
          lastUpdated: number;
          score: number;
          maxScore: number;
          quiz: {
            slug: string;
          };
        }[];
      };
    }[];
    total: number;
  };
};

export type ProductQuizAssessmentsFlatType = {
  quizSlug: string;
  productName: string;
  productId: string;
  score: string;
  maxScore: string;
  productNumberOfWorkers: string;
  lastUpdatedDate: string;
};

export const productQuizAssessmentsFlatFields: (keyof ProductQuizAssessmentsFlatType)[] = [
  "quizSlug",
  "productName",
  "productId",
  "score",
  "maxScore",
  "productNumberOfWorkers",
  "lastUpdatedDate",
];

export class ReportClient {
  constructor(private readonly hostname: string) {}

  private async sendRequest<T, V = string>({
    query,
    token,
    fn,
    variables,
  }: {
    query: string;
    token: string;
    fn: (data: T) => V;
    variables?: Object;
  }): Promise<V> {
    try {
      const response = await axios.post<{ data: T }>(
        this.hostname,
        {
          query,
          variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      return fn(response.data.data);
    } catch (e) {
      throw e;
    }
  }

  private productPerceivedAndEmpiricalMetricsFlatten({
    name,
    repositoryIds,
    perceivedMetrics,
    metrics: { deploymentFrequency, leadTime },
  }: ProductsPerceivedAndEmpiricalMetricsType["products"][0]): ProductPerceivedAndEmpiricalMetricsFlatType {
    return {
      name,
      repositoryIds: repositoryIds.join(";"),
      perceivedChangeFailPercentage: perceivedMetrics.changeFailPercentage ?? "",
      perceivedDeployFrequency: perceivedMetrics.deployFrequency ?? "",
      perceivedLeadTime: perceivedMetrics.leadTime ?? "",
      perceivedTimeToRestore: perceivedMetrics.timeToRestore ?? "",
      perceivedUpdatedDate:
        perceivedMetrics.updatedDate !== null ? new Date(perceivedMetrics.updatedDate).toISOString() : "",
      empiricalDeploymentCount: deploymentFrequency ? deploymentFrequency.periodDeploymentCount.toString() : "",
      empiricalTrendingDeploymentCount: deploymentFrequency
        ? deploymentFrequency.trendingPeriodDeploymentCount.toString()
        : "",
      empiricalDeploymentCountLastUpdate: deploymentFrequency
        ? new Date(deploymentFrequency.lastUpdatedDate).toISOString()
        : "",
      empiricalLeadTimeHours: leadTime?.leadTimeHours?.toString() ?? "",
      empiricalTrendingLeadTimeHours: leadTime?.trendingLeadTimeHours?.toString() ?? "",
      empiricalLeadTimeLastUpdated: leadTime ? new Date(leadTime.lastUpdatedDate).toISOString() : "",
    };
  }

  public async productPerceivedAndEmpiricalMetricsReport(token: string): Promise<string> {
    return await this.sendRequest<ProductsPerceivedAndEmpiricalMetricsType>({
      query: productPerceivedAndEmpiricalMetricsQuery,
      token,
      fn: ({ products }) => {
        let file = ProductPerceivedAndEmpricalMetricsFlatFields.join(",") + "\r\n";
        for (const product of products) {
          const flatProduct: ProductPerceivedAndEmpiricalMetricsFlatType =
            this.productPerceivedAndEmpiricalMetricsFlatten(product);
          const line: string[] = [];
          for (const key of ProductPerceivedAndEmpricalMetricsFlatFields) {
            line.push(flatProduct[key]);
          }
          file += line.join(",") + "\r\n";
        }
        return file;
      },
    });
  }

  private productQuizAssessmentsFlatten({
    id,
    name,
    numberOfWorkers,
    newCapabilityResult,
  }: ProductQuizAssessmentsQueryType["productsPaginated"]["items"][number]): ProductQuizAssessmentsFlatType[] {
    const flatProducts: ProductQuizAssessmentsFlatType[] = [];
    const { quizResults } = newCapabilityResult;
    for (const {
      quiz: { slug },
      score,
      maxScore,
      lastUpdated,
    } of quizResults) {
      flatProducts.push({
        quizSlug: slug,
        productId: id,
        productName: name,
        score: score.toString(),
        maxScore: maxScore.toString(),
        lastUpdatedDate: lastUpdated.toString(),
        productNumberOfWorkers: numberOfWorkers.toString(),
      });
    }
    return flatProducts;
  }

  public async productQuizAssessments(token: string): Promise<string> {
    let file = productQuizAssessmentsFlatFields.join(",") + "\r\n";
    const BATCH_SIZE = 10;
    let totalProducts = BATCH_SIZE;
    let productCount = 0;
    while (productCount <= totalProducts) {
      const batchProducts = await this.sendRequest<ProductQuizAssessmentsQueryType>({
        query: productQuizAssessmentsQuery,
        token,
        fn: ({ productsPaginated: { items, total } }) => {
          console.log("items:", items);
          if (totalProducts !== total) {
            totalProducts = total;
          }
          let batchFile = "";
          for (const product of items) {
            const flatProducts: ProductQuizAssessmentsFlatType[] = this.productQuizAssessmentsFlatten(product);
            for (const flat of flatProducts) {
              const line: string[] = [];
              for (const key of productQuizAssessmentsFlatFields) {
                line.push(flat[key]);
              }
              batchFile += line.join(",") + "\r\n";
            }
          }
          return batchFile;
        },
        variables: {
          limit: BATCH_SIZE,
          skip: productCount,
        },
      });
      file += batchProducts;
      productCount += BATCH_SIZE;
    }
    return file;
  }
}
