import { FC } from "react";
import { LinkButton } from "../link-button";
import Style from "./list.style";

const { CardWidthListContainer } = Style;

export type ListItem = {
  clickHandler?: () => void;
  index: number;
  link: string;
  Icon?: FC<{ className: string }>;
  icon?: {
    name: string;
    size: string | number;
  };
  title: string;
};

type CardProps = {
  title: string;
  subtext?: string;
  listData: ListItem[];
  padding?: number[] | number | string;
  borderRadius?: number | string;
  border?: boolean;
  className?: string;
  backgroundImage?: string;
};

const CardWidthList = ({
  title,
  subtext = undefined,
  listData,
  padding,
  border,
  borderRadius,
  className,
  backgroundImage,
  ...rest
}: CardProps) => {
  return (
    <CardWidthListContainer
      {...rest}
      className={className}
      // @ts-expect-error this component is weirdly typed
      border={border}
      borderRadius={borderRadius}
      padding={padding}
      backgroundImage={backgroundImage}
    >
      <p className="lead">{title}</p>
      <p className="fw-light">{subtext}</p>
      <div className="d-flex flex-column">
        {listData?.map((list, index) => {
          return (
            <div key={list.index} className={`d-flex flex-column ${index !== listData.length - 1 && "border-bottom"}`}>
              <LinkButton className="ps-0" href={list.link} Icon={list?.Icon} level="quartenary" theme="standard">
                {list.title}
              </LinkButton>
            </div>
          );
        })}
      </div>
    </CardWidthListContainer>
  );
};

export default CardWidthList;
