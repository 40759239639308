import { randLastName, randFirstName, randUuid, randJobTitle } from "@ngneat/falso";
import { ReactNode } from "react";

/**
 * Calculate the set-theoretic difference between two sets.
 * @returns setA \ setB (the set difference of setA and setB)
 */
export function setDiff<T>(setA: Iterable<T>, setB: Iterable<T>): Set<T> {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export function capitalize(str: string) {
  return str
    .split("")
    .map((char, index) => (index > 0 ? char.toLowerCase() : char.toUpperCase()))
    .join("");
}

export const textToSlug = (str?: string) => str?.toLowerCase().replace(/\s+/g, "-");

export const textToSlug2 = (str?: string | ReactNode) => {
  if (typeof str === "string") {
    return str?.replace(/\s+/g, "_");
  } else {
    return undefined;
  }
};

export function replaceComponentType(name: string) {
  return name
    .replace("CODE BLOCK", "Code snippet")
    .replace("cs", "Code snippet")
    .replace("ssa", "self-service automation")
    .replace("EXECUTABLE FUNCTION", "self-service automation")
    .replace("Code blocks", "Code snippets")
    .replace("Code block", "Code snippet")
    .replace("code block", "code snippet")
    .replace("executable function", "self-service automation")
    .replace("Executable functions", "Self-service automations")
    .replace("Executable Function", "Executable code");
}

export const slugToText = (text: string) => text.replace(/(-|_)+/gim, " ");

export function parseQueryString(queryString: string) {
  const query: { [key: string]: string; s: string } = { s: "" };
  const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  pairs.forEach((p) => {
    const pair = p.split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  });
  return query;
}

export function getComponentUrl({ component_type, name }: { component_type: string; name: string }) {
  return `/marketplace/components/type/${(component_type || "").toLowerCase()}/${encodeURIComponent(name) || ""}`;
}

export function convertKebabToSnakeCase(str: string) {
  return str.replace(/-/g, "_");
}

export function sanitizeAreaPath(path: string) {
  return path.replace(/[\s]/g, "_").replace(/[\\]/g, "!").replace(/[.]/g, "@");
}

export function generateRandomUser() {
  const surname = randLastName();
  const givenName = randFirstName();
  return {
    surname,
    givenName,
    email: `${givenName}.${surname}@bp.com`,
    userId: randUuid(),
    jobTitle: randJobTitle(),
    photo: null,
  };
}
