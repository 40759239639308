import { Steps } from "intro.js-react";
import { globalTour } from "../intro-js/steps/global";
import { DEFAULT_TOUR_OPTIONS } from "./local-product-tour";
import { trackEvent } from "../utils/event-tracker";
import { Event, TourAction, TourName } from "../telemetry/enums";

export const GLOBAL_TOUR_FLAG = "global_tour_shown";
const showGlobalTour = () => {
  // if the global tour flag isn't in the local storage,
  // it hasn't been shown yet, and thus we should show it.
  return localStorage.getItem(GLOBAL_TOUR_FLAG) === null;
};

export function GlobalProductTour() {
  return showGlobalTour() ? (
    <Steps
      enabled={true}
      steps={globalTour}
      initialStep={0}
      onBeforeExit={(stepIndex) => {
        let actionName = TourAction.EarlyExit;
        if (stepIndex === globalTour.length - 1) {
          actionName = TourAction.Finish;
        }
        trackEvent({
          name: Event.ProductTour,
          tour_name: TourName.Global,
          action: actionName,
          step_index: stepIndex,
        });
      }}
      onExit={() => {
        // this handler is fired regardless of whether the tour has completed or early exited...
        localStorage.setItem(GLOBAL_TOUR_FLAG, "1");
      }}
      onStart={() => {
        trackEvent({
          name: Event.ProductTour,
          tour_name: TourName.Global,
          action: TourAction.Start,
        });
      }}
      options={DEFAULT_TOUR_OPTIONS}
    />
  ) : null;
}
