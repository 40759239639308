"use client";
import { useCallback, useState } from "react";

export type useAsyncActionType<T> = {
  isLoading: boolean;
  error: Error | null;
  trigger: () => Promise<void>;
  data: T | null;
};

export function useAsyncAction<T>(fn: () => Promise<T>): useAsyncActionType<T> {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);

  const trigger = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    try {
      const result = await fn();
      setData(result);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, [fn]);

  return { isLoading, data, error, trigger };
}
