import { graphql } from "../../gql/gql";
import { ChatRequestQuery, ListChatsQuery } from "../../gql/graphql";
import { MDXRemoteSerializeResult } from "next-mdx-remote";

export type ListChatsResponse = ListChatsQuery["listChats"];
export type AxelChat = ListChatsResponse[number];

export const LIST_CHATS = graphql(`
  query ListChats {
    listChats {
      name
      chatId
      lastUpdate
    }
  }
`);

export const OPEN_CHAT = graphql(`
  query OpenChat($chatId: String!) {
    openChat(chatId: $chatId) {
      history {
        auditId
        request
        answer
        kendraSearch
        sourceDocs {
          title
          url
          score
        }
        probablePublicData
      }
      chatId
    }
  }
`);

export const CHAT_REQUEST = graphql(`
  query ChatRequest($chatMessage: ChatMessage!) {
    chatRequest(chatMessage: $chatMessage) {
      auditId
      request
      answer
      chatId
      chatName
      kendraSearch
      keywordSearchData {
        isKeywordSearch
        recommendations
      }
      sourceDocs {
        title
        url
        score
      }
      probablePublicData
    }
  }
`);

export const SUGGEST_QUESTIONS = graphql(`
  query GetSuggestions($requestSuggestions: RequestSuggestions!) {
    getSuggestions(requestSuggestions: $requestSuggestions) {
      questions
    }
  }
`);

export const SUGGEST_TAGS = graphql(`
  query RetrieveSuggestedTags($context: String!) {
    retrieveSuggestedTags(context: $context) {
      tags
    }
  }
`);

export const RENAME_CHAT = graphql(`
  mutation RenameChat($renameMessage: RenameMessage!) {
    renameChat(renameMessage: $renameMessage) {
      name
      chatId
      lastUpdate
    }
  }
`);

export const DELETE_CHAT = graphql(`
  mutation DeleteChat($chatId: String!) {
    deleteChat(chatId: $chatId) {
      name
      chatId
    }
  }
`);

export const NEW_CHAT = graphql(`
  mutation NewChat {
    newChat {
      chatId
    }
  }
`);

export const SUBMIT_ISSUE = graphql(`
  mutation SubmitAxelIssue($submitIssue: SubmitIssue!) {
    submitAxelIssue(submitIssue: $submitIssue) {
      auditId
    }
  }
`);

export type ChatRequestResponse = ChatRequestQuery["chatRequest"];
export type StreamedChatRequestResponse = Omit<ChatRequestResponse, "answer"> & {
  answer?: string | null;
  end?: boolean;
  renderedAnswer?: MDXRemoteSerializeResult;
};
