import { SVGProps } from "react";

export function Shield(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Diversity_and_inclusion"
      data-name="Diversity and inclusion"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <title>shield</title>
      <path d="M500,997.52a22.38,22.38,0,0,1-7.05-1.14c-99.2-33.07-182.06-83.13-246.29-148.8C167.86,767,119.1,664.46,101.71,542.75,81.44,400.88,81.52,261,101.92,159a22.26,22.26,0,0,1,21.84-17.91c127,0,217.93-35.55,271.8-65.37C454,43.37,482.84,10.53,483.13,10.2A22.36,22.36,0,0,1,500,2.5H500a22.29,22.29,0,0,1,16.87,7.75c.24.28,29.08,33.12,87.53,65.47,53.87,29.82,144.76,65.37,271.8,65.37A22.26,22.26,0,0,1,898.08,159c20.4,102,20.48,241.88.21,383.75C880.9,664.46,832.14,767,753.34,847.58,689.11,913.25,606.25,963.31,507,996.38A22.27,22.27,0,0,1,500,997.52ZM142.37,185.4c-15.85,94.66-14.69,224.09,3.44,351.05C182.07,790.24,356.8,902.31,500,951.72c143.2-49.41,317.93-161.47,354.18-415.27,18.14-127,19.3-256.39,3.45-351.05-128.12-3.32-220.84-40.49-276.93-71.9-38.27-21.44-65-43-80.7-57.33-15.7,14.33-42.43,35.89-80.7,57.33C363.21,144.91,270.49,182.08,142.37,185.4Z" />
    </svg>
  );
}
