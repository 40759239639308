import { PropsWithChildren } from "react";
import ContentLoader from "react-content-loader";

export function NavTreeSkeleton(props: PropsWithChildren<{ className?: string; "data-testid"?: string }>) {
  const boxHeight = 16;
  const boxWidth = (depth: number) => 200 - indentPx(depth) - 8;
  const indentPx = (depth: number) => depth * 15 + 8;
  const yDistance = 30;
  const lineDepths = [0, 2, 4, 4, 2, 2, 4, 7];
  const containerHeight = lineDepths.length * yDistance;
  return (
    <ContentLoader
      {...props}
      speed={2}
      width={256}
      height={containerHeight}
      viewBox={`0 0 200 ${containerHeight}`}
      backgroundColor="#11111117"
      foregroundColor="#11111157"
      uniqueKey={process.env.NODE_ENV === "test" ? "snapshot" : undefined}
    >
      {lineDepths.map((depth, index) => (
        <rect
          key={index}
          x={indentPx(depth)}
          y={index * yDistance}
          rx="5"
          ry="5"
          width={boxWidth(depth)}
          height={boxHeight}
        />
      ))}
    </ContentLoader>
  );
}
