type QueryParamsValue = string | string[] | boolean | number | undefined;

type QueryParams = Record<string, QueryParamsValue>;

export default function updateSearchParams(oldParams: Record<string, string>, newParams: Record<string, string>) {
  const newSearchParameters = new URLSearchParams(oldParams);
  for (const key in newParams) {
    const v = newParams[key];
    if (typeof v !== "undefined") {
      newSearchParameters.set(key, v);
    } else {
      newSearchParameters.delete(key);
    }
  }
  return newSearchParameters;
}

export function updateQueryParams(oldParams: QueryParams, newParams: QueryParams): QueryParams {
  const newQueryParams = { ...oldParams, ...newParams };
  for (const key in newQueryParams) {
    if (typeof newQueryParams[key as keyof QueryParams] === "undefined") {
      delete newQueryParams[key];
    }
  }
  return newQueryParams;
}

function queryParamsToSearchParams(params: QueryParams): URLSearchParams {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    const value = params[key as keyof QueryParams];
    if (Array.isArray(value)) {
      value.forEach((val) => searchParams.append(key, val));
    } else {
      searchParams.set(key, value as string);
    }
  }
  return searchParams;
}

export function updateQueryParamsAsSearchParams(oldParams: QueryParams, newParams: QueryParams): URLSearchParams {
  return queryParamsToSearchParams(updateQueryParams(oldParams, newParams));
}
