import { SVGProps } from "react";

export function Book(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>book</title>
      <g id="Glossary_book_" data-name="Glossary (book)">
        <path d="M975.24752,224.25743c-104.01067,0-187.82487-22.35072-255.17094-40.30883-46.39542-12.37237-86.46349-23.05751-121.06668-23.05751-47.75294,0-79.759,20.61514-99.0099,42.68835-19.25085-22.07321-51.256-42.68835-99.0099-42.68835-34.60222,0-74.67126,10.68514-121.06668,23.05751-67.34607,17.95811-151.1593,40.30883-255.17094,40.30883A22.27677,22.27677,0,0,0,2.47525,246.53465v570.297a22.2774,22.2774,0,0,0,22.27723,22.27723c109.84974,0,196.79184-23.18417,266.65087-41.81428,43.369-11.564,80.82263-21.55206,109.58675-21.55206,25.729,0,46.49984,7.86278,61.73422,23.36982A72.7669,72.7669,0,0,1,478.768,823.58447a22.106,22.106,0,0,0,42.46307,0,72.81326,72.81326,0,0,1,16.04463-24.47208c15.23438-15.507,36.00518-23.36982,61.73422-23.36982,28.76315,0,66.21867,9.988,109.58578,21.55206,69.86,18.63011,156.80113,41.81428,266.65184,41.81428a22.278,22.278,0,0,0,22.27723-22.27723v-570.297A22.27739,22.27739,0,0,0,975.24752,224.25743ZM400.9901,731.18812c-34.60222,0-74.67126,10.68417-121.06668,23.05654-62.471,16.65958-139.106,37.09778-232.89372,39.96752V268.49377c99.41213-2.8417,179.30751-24.1443,244.37365-41.4952,43.369-11.565,80.82263-21.553,109.58675-21.553,25.729,0,46.49984,7.86278,61.73422,23.36886a75.55168,75.55168,0,0,1,14.99845,21.89578V753.92136C458.84865,740.80013,433.59218,731.18812,400.9901,731.18812Zm551.9802,63.02406c-93.78868-2.86974-170.42273-23.30794-232.89372-39.96752-46.39542-12.37237-86.46349-23.05654-121.06668-23.05654-32.60147,0-57.85831,9.61213-76.73267,22.73336V250.70958a75.5809,75.5809,0,0,1,14.99845-21.89518c15.23438-15.50608,36.00518-23.36886,61.73422-23.36886,28.76315,0,66.21867,9.988,109.58578,21.553,65.06807,17.3509,144.96249,38.6535,244.37462,41.4952Z" />
      </g>
    </svg>
  );
}
