import { DateTime } from "luxon";
import formatTimestampToRelativeDate from "../../utils/formatTimestampToRelativeDate";

import { Badge } from "reactstrap";
import { Edit24, Trash24 } from "@bphxd/ds-core-react/lib/icons/index";
import { Button } from "../../components/button";
import { SurveyDetails } from "../../api/users/types";
import Pluralise from "../../utils/pluralise";

export const TESTID_ENUMS = {
  container: "survey-preview-card",
  surveyName: "survey-name",
  approvalBadge: "approval-badge",
  description: "survey-description",
  updateButton: "update-survey",
  deleteButton: "delete-survey",
  surveyColumns: "survey-columns",
};

type SurveyPreviewCardProps = {
  onConfirmClick: ({ type, id }: { type: string; id: string }) => void;
  onUpdateClick: () => void;
  survey: SurveyDetails;
};

export default function SurveyPreviewCard({ survey, onConfirmClick, onUpdateClick }: SurveyPreviewCardProps) {
  const isSurveyUpdated = new Date(survey.updatedDate).getTime() !== new Date(survey.createdDate).getTime();

  return (
    <div
      key={survey.surveyId}
      className="container-fluid w-100 mb-6 pb-2 fs-5 border rounded hover-shadow"
      data-testid={TESTID_ENUMS.container}
    >
      <div className="row align-items-center bg-secondary" data-testid={TESTID_ENUMS.surveyColumns}>
        <div className="col py-3">
          <p className="m-0 lead">{survey.author}</p>
        </div>
        <div className="col py-3" data-testid={TESTID_ENUMS.surveyName}>
          <p className="m-0 lead">{survey.surveyName}</p>
        </div>
        <div className="col py-1">
          <p className="m-0 lead">{`${survey.estimatedTime} minute${Pluralise(survey.estimatedTime)}`}</p>
        </div>
        <div className="col py-2" data-testid={TESTID_ENUMS.approvalBadge}>
          <Badge className="m-0 lead" color={survey?.approved ? "success" : "danger"}>
            {survey?.approved ? "approved" : "not approved"}
          </Badge>
        </div>
        <div className="col-3 py-3 border-start text-center">
          <Button
            name="moderator-delete-tag"
            data-testid={`${TESTID_ENUMS.updateButton}`}
            className="rounded-pill me-4"
            onClick={() => onUpdateClick()}
            Icon={Edit24}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="xs"
          >
            Update
          </Button>
          <Button
            name="moderator-delete-tag"
            data-testid={`${TESTID_ENUMS.deleteButton}`}
            onClick={() => onConfirmClick({ type: "delete", id: survey?.surveyId })}
            Icon={Trash24}
            level="tertiary"
            theme="standard"
            rounded="pill"
            size="xs"
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="row border-top pt-4 mb-4" data-testid={TESTID_ENUMS.description}>
        <div className="col-9">
          <h6 className="mb-0">Survey Description</h6>
          <p className="m-0" style={{ fontSize: "1rem" }}>
            {survey.description}
          </p>
        </div>
        <div className="col-3 border-start text-center">
          <p className="mb-0 fst-italic" style={{ fontSize: "1rem" }}>
            {`Survey created: ${DateTime.fromISO(survey.createdDate).toLocaleString(DateTime.DATE_FULL)}`}
          </p>
          <p className="mb-0 fst-italic" style={{ fontSize: "1rem" }}>
            {isSurveyUpdated &&
              `Updated ${formatTimestampToRelativeDate(survey.updatedDate)} by ${survey.lastUpdatedBy}`}
          </p>
        </div>
      </div>
    </div>
  );
}
