export enum Event {
  ButtonClick = "button_click",
  LinkClick = "link_click",
  AxelRequest = "axel_request",
  AxelResponse = "axel_response",
  ProductTour = "product_tour",
  NewSearchModal = "search_bar_new_search_modal",
  SearchSubmitted = "search_submitted",
}

export enum Feature {
  Breadcrumbs = "breadcrumbs",
}

export enum View {
  Axel = "axelGPT",
  DrawIO = "drawio",
  KHubEditor = "khub_editor",
  Khub = "khub",
  KhubDocument = "khub_document",
}

export enum ViewComponent {
  AxelResponse = "axel_response",
  ChatWindow = "chat_window",
  SideTab = "side_tab",
  DeleteChatModal = "delete_chat_modal",
  ChatList = "chat_list",
  DrawIOCreate = "drawio_create",
  DrawIOEdit = "drawio_edit",
  SearchBar = "search_bar",
  KhubRightHandPanel = "khub_right_hand_panel",
  HelpMeImproveMyCapabilityButton = "help_me_improve_my_capability_button",
  DocEditor = "doc_editor",
}

export enum TourName {
  Global = "global",
  SearchResults = "search_results",
  Knowledgehub = "knowledgehub",
  Axel = "axel",
  KnowledgehubEdit = "knowledgehub_edit",
}

export enum TourAction {
  Start = "start",
  Finish = "finish",
  EarlyExit = "early_exit",
}
