import { SVGProps } from "react";

export function DiagramBarUpwards(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>diagram-bar-upwards</title>
      <g id="Graph_up" data-name="Graph up">
        <path d="M24.75768,641.08916a22.19518,22.19518,0,0,0,15.04679-5.85551L274.54883,420.05149l231.64836-96.51918,235.08857-39.1824a22.26787,22.26787,0,0,0,12.09-6.221L953.14845,78.35626l.15,45.34815a22.27691,22.27691,0,0,0,22.27529,22.20375h.07542a22.27814,22.27814,0,0,0,22.20375-22.35071l-.32681-98.87454A22.27627,22.27627,0,0,0,975.24886,2.47917h-.07349L876.034,2.80791a22.27729,22.27729,0,0,0,.07155,44.55446h.07541l45.10075-.14975-194.362,194.36386L496.33681,280.00663a22.31647,22.31647,0,0,0-4.906,1.40973L253.807,380.42626a22.287,22.287,0,0,0-6.484,4.14217L9.69928,602.39021a22.2772,22.2772,0,0,0,15.0584,38.69895Z" />
        <path d="M499.0093,358.91094a22.278,22.278,0,0,0-22.27723,22.27723V975.24758a22.27723,22.27723,0,0,0,44.55446,0V381.18817A22.278,22.278,0,0,0,499.0093,358.91094Z" />
        <path d="M736.63306,319.307a22.278,22.278,0,0,0-22.27722,22.27723V975.24758a22.27723,22.27723,0,0,0,44.55445,0V341.58421A22.278,22.278,0,0,0,736.63306,319.307Z" />
        <path d="M975.24692,194.55257A22.278,22.278,0,0,0,952.9697,216.8298V975.24758a22.27723,22.27723,0,0,0,44.55445,0V216.8298A22.278,22.278,0,0,0,975.24692,194.55257Z" />
        <path d="M24.75188,678.71292A22.278,22.278,0,0,0,2.47465,700.99015V975.24758a22.27723,22.27723,0,0,0,44.55445,0V700.99015A22.278,22.278,0,0,0,24.75188,678.71292Z" />
        <path d="M263.36574,497.5248A22.278,22.278,0,0,0,241.08851,519.802V975.24758a22.27722,22.27722,0,0,0,44.55445,0V519.802A22.278,22.278,0,0,0,263.36574,497.5248Z" />
      </g>
    </svg>
  );
}
