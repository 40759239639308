import { BaseRequestArgs, PostRequestArgs } from "../../types/api-types";
import { ACCELERATE_APIS } from "../config";
import { instrumentedFetch } from "../instrumented-fetch";

/**
 * Core function for platform - search API requests. Its using get as a default function
 * passed to be executed within the current context started by the parent span.
 *
 * @param spanName {String} parent span name
 * @param query {String} resourceURI
 * @param spanAttributes {Object} additional attributes for telemetry
 * @return {Object}
 */
export async function getSearchAPI<T = Record<string, any>>({ spanName, query, spanAttributes = {} }: BaseRequestArgs) {
  return instrumentedFetch<T>({
    apiUrl: ACCELERATE_APIS.search.url ?? "",
    method: "GET",
    apiResource: query,
    spanName,
    spanAttributes,
  });
}

export async function postAdvancedSearchAPI<T = Record<string, any>>({
  spanName,
  query,
  spanAttributes = {},
  payload,
}: PostRequestArgs) {
  return instrumentedFetch<T>({
    apiUrl: ACCELERATE_APIS.search.url ?? "",
    method: "POST",
    apiResource: query,
    spanName,
    spanAttributes,
    payload,
  });
}

export async function putSearchAPI<T = Record<string, any>>({ spanName, query, spanAttributes = {} }: BaseRequestArgs) {
  return instrumentedFetch<T>({
    apiUrl: ACCELERATE_APIS.search.url ?? "",
    method: "PUT",
    apiResource: query,
    spanName,
    spanAttributes,
  });
}
