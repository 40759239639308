import { SVGProps } from "react";

export function Warning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>warning</title>
      <g id="Risk">
        <path d="M975.24725,938.11881H24.7522A22.27721,22.27721,0,0,1,5.41046,904.78806L480.658,73.10489a22.27761,22.27761,0,0,1,38.68348,0L994.589,904.78806a22.27722,22.27722,0,0,1-19.34174,33.33075ZM63.13981,893.56436H936.85964L499.99972,129.059Z" />
        <path d="M546.55239,634.52262q-3.42027,34.106-32.04772,34.09385H487.91123q-28.63956,0-32.04773-34.09385L434.72434,414.95875q-2.0461-21.127-2.04409-27.27447,0-46.363,68.18769-46.36751,68.17557,0,68.18768,46.36751,0,12.96075-1.36407,27.27447ZM500.1859,819.99467q-25.92453,0-45.00444-19.093-19.0991-19.08093-19.093-45.68648,0-26.59344,19.093-45.68446,19.08093-19.084,45.00444-19.093,26.59344,0,45.68446,19.093,19.08394,19.09606,19.093,45.68446,0,26.59344-19.093,45.68648Q526.77429,819.98256,500.1859,819.99467Z" />
      </g>
    </svg>
  );
}
