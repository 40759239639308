import { env } from "next-runtime-env";

export enum Environment {
  Int = "int",
  Prod = "prod",
  Perf = "perf",
}

const UUID_MAP: Record<Environment, Record<string, string | undefined>> = {
  int: {
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability": "fVzfHo",
    "Tooling/Grafana-Suite/Onboarding/Onboarding-to-bp-Grafana-Suite": "4xBp7p",
    "DevOps-Methodology": "eRX2B5",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/OpenTelemetry/Intro-to-OpenTelemetry": "7QfCvH",
    "Tooling/Grafana-Suite/Dashboards-and-Querying/Create-a-New-Dashboard": "axhtBK",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Three_Pillars_of_Observability": "fi2AtA",
    "Golden-Paths": "ZLaf5g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Endorsed_Observability_Solution/Endorsed-Observability-Solution":
      "knSeG2",
    "New-Joiner-Flow": "NzdEp9",
    Help: "6UHcYt",
    "Help/Markdown-Cheatsheet": "FhN64D",
    "The-Product-Wiki": "3prnfV",
    "New-Joiner-Flow/Application-Setup": "TBggmJ",
    Architecture_Artefacts: "hzRjcG",
    "Architecture_Artefacts/How_to_Create_KDDs": "Fs6Z2g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Observability-Impact-and-Value":
      "8zEXo7",
    "Tooling/Grafana-Suite/Alerting/Create-Alerts-In-Grafana": "bjHbLL",
    "Digital-Standards": "NesTRb",
    "Help/Template-for-Key-Design-Decision-documents": "EyAevU",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Dashboards-Best-Practice":
      "MNfgUF",
    Myrge: "hBkaQV",
    Tooling: "UujJXV",
    "Digital-Security": "j8DmCP",
    "Tooling/Azure-DevOps-ADO/Azure-DevOps-(ADO)---Getting-started-and-request-forms": "m6kEuN",
    "Help/How-to-edit-content": "TR7q3i",
    "Help/How-to-create-pages-and-folders": "2zZkwQ",
    TRM: "WBhy4S",
    Create: "TFnaZX",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Alerts-Best-Practice": "Yh4Zy5",
    "New-Joiner-Flow/Deploy-Application/Setup-Application-Pipelines": "2PZ96t",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Monitoring_and_Observability-Introduction":
      "MA98u9",
    "DevOps-Methodology/Cultural/Job_Satisfaction": "idAH33",
    "DevOps-Methodology/Cultural/Learning_Culture": "P4ynsF",
    "DevOps-Methodology/Cultural/Transformational_Leadership": "X2FqUF",
    "DevOps-Methodology/Cultural/Westrum_Organizational_Culture": "5s9kY8",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability": "D8aozc",
    "DevOps-Methodology/Measurement/Monitoring_Systems_to_Inform_Business_Decisions": "26Vyf2",
    "DevOps-Methodology/Measurement/Proactive_Failure_Notification": "N9N7fY",
    "DevOps-Methodology/Measurement/Visual_Management": "ANzxyY",
    "DevOps-Methodology/Measurement/Work_in_Process_Limits": "oG7VyW",
    "DevOps-Methodology/Process/Customer_Feedback": "eZG4eF",
    "DevOps-Methodology/Process/Streamline_Change_Approval": "76yn6U",
    "DevOps-Methodology/Process/Team_Experimentation": "2jmQvA",
    "DevOps-Methodology/Process/Visibility-of-Work": "5qXstx",
    "DevOps-Methodology/Process/Working_in_Small_Batches": "bV6KN2",
    "DevOps-Methodology/Technical/Cloud_Infrastructure": "NnfAHp",
    "DevOps-Methodology/Technical/Code_Maintainability": "SCKjEW",
    "DevOps-Methodology/Technical/Continuous-Delivery": "dRjDy5",
    "DevOps-Methodology/Technical/Continuous_Integration": "Ngh8W4",
    "DevOps-Methodology/Technical/Continuous_Testing": "8oXmNw",
    "DevOps-Methodology/Technical/Database_Change_Management": "etCLf7",
    "DevOps-Methodology/Technical/Deployment_Automation": "M3k3nc",
    "DevOps-Methodology/Technical/Empower_Teams_to_Choose_Tools": "kHwKhh",
    "DevOps-Methodology/Technical/Loosely_Coupled_Architecture": "DiJzA2",
    "DevOps-Methodology/Technical/Shift_Left_On_Security": "SiuP8w",
    "DevOps-Methodology/Technical/Test_Data_Management": "JAoZXZ",
    "DevOps-Methodology/Technical/Trunk-Based-Development": "a3Kzu6",
    "DevOps-Methodology/Technical/Version-Control": "2Z65v4",
    "DevOps-Methodology/How_To": "BXPeSB",
  },
  prod: {
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability": "fVzfHo",
    "Tooling/Grafana-Suite/Onboarding/Onboarding-to-bp-Grafana-Suite": "4xBp7p",
    "DevOps-Methodology": "eRX2B5",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/OpenTelemetry/Intro-to-OpenTelemetry": "7QfCvH",
    "Tooling/Grafana-Suite/Dashboards-and-Querying/Create-a-New-Dashboard": "axhtBK",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Three_Pillars_of_Observability": "fi2AtA",
    "Golden-Paths": "ZLaf5g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Endorsed_Observability_Solution/Endorsed-Observability-Solution":
      "knSeG2",
    "New-Joiner-Flow": "NzdEp9",
    Help: "6UHcYt",
    "Help/Markdown-Cheatsheet": "FhN64D",
    "The-Product-Wiki": "3prnfV",
    "New-Joiner-Flow/Application-Setup": "TBggmJ",
    Architecture_Artefacts: "hzRjcG",
    "Architecture_Artefacts/How_to_Create_KDDs": "Fs6Z2g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Observability-Impact-and-Value":
      "8zEXo7",
    "Tooling/Grafana-Suite/Alerting/Create-Alerts-In-Grafana": "bjHbLL",
    "Digital-Standards": "NesTRb",
    "Help/Template-for-Key-Design-Decision-documents": "EyAevU",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Dashboards-Best-Practice":
      "MNfgUF",
    Myrge: "hBkaQV",
    Tooling: "UujJXV",
    "Digital-Security": "j8DmCP",
    "Tooling/Azure-DevOps-ADO/Azure-DevOps-(ADO)---Getting-started-and-request-forms": "m6kEuN",
    "Help/How-to-edit-content": "TR7q3i",
    "Help/How-to-create-pages-and-folders": "2zZkwQ",
    TRM: "WBhy4S",
    Create: "TFnaZX",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Alerts-Best-Practice": "Yh4Zy5",
    "New-Joiner-Flow/Deploy-Application/Setup-Application-Pipelines": "2PZ96t",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Monitoring_and_Observability-Introduction":
      "MA98u9",
    "DevOps-Methodology/Cultural/Job_Satisfaction": "idAH33",
    "DevOps-Methodology/Cultural/Learning_Culture": "P4ynsF",
    "DevOps-Methodology/Cultural/Transformational_Leadership": "X2FqUF",
    "DevOps-Methodology/Cultural/Westrum_Organizational_Culture": "5s9kY8",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability": "D8aozc",
    "DevOps-Methodology/Measurement/Monitoring_Systems_to_Inform_Business_Decisions": "26Vyf2",
    "DevOps-Methodology/Measurement/Proactive_Failure_Notification": "N9N7fY",
    "DevOps-Methodology/Measurement/Visual_Management": "ANzxyY",
    "DevOps-Methodology/Measurement/Work_in_Process_Limits": "oG7VyW",
    "DevOps-Methodology/Process/Customer_Feedback": "eZG4eF",
    "DevOps-Methodology/Process/Streamline_Change_Approval": "76yn6U",
    "DevOps-Methodology/Process/Team_Experimentation": "2jmQvA",
    "DevOps-Methodology/Process/Visibility-of-Work": "5qXstx",
    "DevOps-Methodology/Process/Working_in_Small_Batches": "bV6KN2",
    "DevOps-Methodology/Technical/Cloud_Infrastructure": "NnfAHp",
    "DevOps-Methodology/Technical/Code_Maintainability": "SCKjEW",
    "DevOps-Methodology/Technical/Continuous-Delivery": "dRjDy5",
    "DevOps-Methodology/Technical/Continuous_Integration": "Ngh8W4",
    "DevOps-Methodology/Technical/Continuous_Testing": "8oXmNw",
    "DevOps-Methodology/Technical/Database_Change_Management": "etCLf7",
    "DevOps-Methodology/Technical/Deployment_Automation": "M3k3nc",
    "DevOps-Methodology/Technical/Empower_Teams_to_Choose_Tools": "kHwKhh",
    "DevOps-Methodology/Technical/Loosely_Coupled_Architecture": "DiJzA2",
    "DevOps-Methodology/Technical/Shift_Left_On_Security": "SiuP8w",
    "DevOps-Methodology/Technical/Test_Data_Management": "JAoZXZ",
    "DevOps-Methodology/Technical/Trunk-Based-Development": "a3Kzu6",
    "DevOps-Methodology/Technical/Version-Control": "2Z65v4",
    "DevOps-Methodology/How_To": "FBMHMW",
  },
  perf: {
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability": "fVzfHo",
    "Tooling/Grafana-Suite/Onboarding/Onboarding-to-bp-Grafana-Suite": "4xBp7p",
    "DevOps-Methodology": "eRX2B5",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/OpenTelemetry/Intro-to-OpenTelemetry": "7QfCvH",
    "Tooling/Grafana-Suite/Dashboards-and-Querying/Create-a-New-Dashboard": "axhtBK",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Three_Pillars_of_Observability": "fi2AtA",
    "Golden-Paths": "ZLaf5g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Endorsed_Observability_Solution/Endorsed-Observability-Solution":
      "knSeG2",
    "New-Joiner-Flow": "NzdEp9",
    Help: "6UHcYt",
    "Help/Markdown-Cheatsheet": "FhN64D",
    "The-Product-Wiki": "3prnfV",
    "New-Joiner-Flow/Application-Setup": "TBggmJ",
    Architecture_Artefacts: "hzRjcG",
    "Architecture_Artefacts/How_to_Create_KDDs": "Fs6Z2g",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Observability-Impact-and-Value":
      "8zEXo7",
    "Tooling/Grafana-Suite/Alerting/Create-Alerts-In-Grafana": "bjHbLL",
    "Digital-Standards": "NesTRb",
    "Help/Template-for-Key-Design-Decision-documents": "EyAevU",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Dashboards-Best-Practice":
      "MNfgUF",
    Myrge: "hBkaQV",
    Tooling: "UujJXV",
    "Digital-Security": "j8DmCP",
    "Tooling/Azure-DevOps-ADO/Azure-DevOps-(ADO)---Getting-started-and-request-forms": "m6kEuN",
    "Help/How-to-edit-content": "TR7q3i",
    "Help/How-to-create-pages-and-folders": "2zZkwQ",
    TRM: "WBhy4S",
    Create: "TFnaZX",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Dashboards_and_Alerts/Alerts-Best-Practice": "Yh4Zy5",
    "New-Joiner-Flow/Deploy-Application/Setup-Application-Pipelines": "2PZ96t",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability/Intro_to_Monitoring_and_Observability/Monitoring_and_Observability-Introduction":
      "MA98u9",
    "DevOps-Methodology/Cultural/Job_Satisfaction": "idAH33",
    "DevOps-Methodology/Cultural/Learning_Culture": "P4ynsF",
    "DevOps-Methodology/Cultural/Transformational_Leadership": "X2FqUF",
    "DevOps-Methodology/Cultural/Westrum_Organizational_Culture": "5s9kY8",
    "DevOps-Methodology/Measurement/Monitoring_and_Observability": "D8aozc",
    "DevOps-Methodology/Measurement/Monitoring_Systems_to_Inform_Business_Decisions": "26Vyf2",
    "DevOps-Methodology/Measurement/Proactive_Failure_Notification": "N9N7fY",
    "DevOps-Methodology/Measurement/Visual_Management": "ANzxyY",
    "DevOps-Methodology/Measurement/Work_in_Process_Limits": "oG7VyW",
    "DevOps-Methodology/Process/Customer_Feedback": "eZG4eF",
    "DevOps-Methodology/Process/Streamline_Change_Approval": "76yn6U",
    "DevOps-Methodology/Process/Team_Experimentation": "2jmQvA",
    "DevOps-Methodology/Process/Visibility-of-Work": "5qXstx",
    "DevOps-Methodology/Process/Working_in_Small_Batches": "bV6KN2",
    "DevOps-Methodology/Technical/Cloud_Infrastructure": "NnfAHp",
    "DevOps-Methodology/Technical/Code_Maintainability": "SCKjEW",
    "DevOps-Methodology/Technical/Continuous-Delivery": "dRjDy5",
    "DevOps-Methodology/Technical/Continuous_Integration": "Ngh8W4",
    "DevOps-Methodology/Technical/Continuous_Testing": "8oXmNw",
    "DevOps-Methodology/Technical/Database_Change_Management": "etCLf7",
    "DevOps-Methodology/Technical/Deployment_Automation": "M3k3nc",
    "DevOps-Methodology/Technical/Empower_Teams_to_Choose_Tools": "kHwKhh",
    "DevOps-Methodology/Technical/Loosely_Coupled_Architecture": "DiJzA2",
    "DevOps-Methodology/Technical/Shift_Left_On_Security": "SiuP8w",
    "DevOps-Methodology/Technical/Test_Data_Management": "JAoZXZ",
    "DevOps-Methodology/Technical/Trunk-Based-Development": "a3Kzu6",
    "DevOps-Methodology/Technical/Version-Control": "2Z65v4",
  },
};

// infer environment from public api url
export function inferEnv() {
  const pattern = /accelerate\.?(\w*)\.bpglobal/;
  const apiUrl = env("NEXT_PUBLIC_API_URL") ?? "";
  const matchedEnv = apiUrl.match(pattern);

  if (matchedEnv === null || matchedEnv[1].includes("dev")) {
    /**
     * when pattern doesn't match - likely to be using local development
     * therefore default to the INT env mappings
     * -- JP
     */
    return Environment.Int;
  } else if (matchedEnv[1] === "") {
    return Environment.Prod;
  } else {
    return matchedEnv[1] as Environment;
  }
}

export function khubHrefMapper(href: string, env = inferEnv()) {
  const output = UUID_MAP[env][href];
  if (!output) {
    console.warn(`Cannot find UUID for ${href} in ${env} environment`);
    return `/dac/${href}`;
  }
  return `/knowledgehub/${output}`;
}
