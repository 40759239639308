import { SVGProps } from "react";

export function Slack({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
      <g fill="#111" fillRule="nonzero">
        <path d="M4.166 0a1 1 0 1 0 0 2h1.001V1a1 1 0 0 0-1-1m0 2.667H1.5a1 1 0 0 0 0 2h2.666a1 1 0 1 0 0-2zM10.5 3.666a1 1 0 0 0-2 0v1h1a1 1 0 0 0 1-1zm-2.667 0V1a1 1 0 0 0-2 0v2.666a1 1 0 1 0 2 0zM6.833 10a1 1 0 1 0 0-2h-1v1a1 1 0 0 0 1 1zm0-2.667H9.5a1 1 0 0 0 0-2H6.834a1 1 0 0 0 0 2zM.5 6.333a1 1 0 0 0 2 0v-1h-1a1 1 0 0 0-1 1zm2.667 0V9a1 1 0 0 0 2 0V6.334a1 1 0 0 0-2 0" />
      </g>
    </svg>
  );
}
