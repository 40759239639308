import { Filter32 } from "@bphxd/ds-core-react/lib/icons";
import { Modal } from "../modal";
import { ModalHeaderWithIcon } from "../modal-header";
import { SearchForm } from "./modal-form";
import { useAppActions, useIsSearchFormOpen } from "../../hooks/use-app-store";

export function SearchModal() {
  const { setIsSearchFormOpen } = useAppActions();
  const isSearchFormOpen = useIsSearchFormOpen();

  function onClose() {
    setIsSearchFormOpen(false);
  }

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={isSearchFormOpen}
      hasCancelDialog={false}
      onClose={onClose}
      // @ts-expect-error ModalHeader is a JSX element and header is expected to be a string
      header={ModalHeaderWithIcon(<Filter32 />, "Refine Your Search")}
    >
      <SearchForm />
    </Modal>
  );
}
