import { SVGProps } from "react";

export function TickBox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>tick-box</title>
      <g id="Tick">
        <path d="M992.76764,70.39952a22.27712,22.27712,0,0,0-31.279-3.76122c-46.32339,36.37829-112.35968,93.45824-187.0261,166.00391a93.604,93.604,0,0,0-26.8759-19.89469,120.03892,120.03892,0,0,0-49.56683-12.25247H103.9604c-40.80291,0-72.49188,18.43866-89.23268,51.91832A120.03892,120.03892,0,0,0,2.47525,301.9802V896.0396c0,40.80291,18.43866,72.49188,51.91831,89.23268a120.039,120.039,0,0,0,49.56684,12.25247H698.0198c40.80291,0,72.49188-18.43866,89.23268-51.91831A120.039,120.039,0,0,0,799.505,896.0396V301.9802a118.45377,118.45377,0,0,0-3.23885-28.038C903.3527,169.4238,982.52316,106.77055,989.00642,101.67853A22.27539,22.27539,0,0,0,992.76764,70.39952ZM747.401,925.68069c-9.18162,18.36325-25.33455,27.28961-49.38119,27.28961H103.9604a77.50876,77.50876,0,0,1-29.64109-7.54951C55.95606,936.23917,47.0297,920.08625,47.0297,896.0396V301.9802a77.5088,77.5088,0,0,1,7.54951-29.64109c9.18162-18.36324,25.33454-27.28961,49.38119-27.28961H698.0198a77.5088,77.5088,0,0,1,29.64109,7.54951,48.8789,48.8789,0,0,1,15.03145,11.34492C634.69891,371.80321,512.72,508.75667,407.45088,660.3651c-35.22586-43.11572-100.85667-115.567-148.979-163.63127a22.27759,22.27759,0,1,0-31.48592,31.5246c61.74389,61.66847,144.28953,155.14774,163.21163,182.59978a22.27736,22.27736,0,0,0,18.342,9.63413h.11216a22.27528,22.27528,0,0,0,18.35744-9.81977C532.67979,553.99678,652.86926,418.32312,754.9505,315.00824V896.0396A77.50876,77.50876,0,0,1,747.401,925.68069Z" />
      </g>
    </svg>
  );
}
