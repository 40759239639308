import React from "react";
import { create } from "zustand";
import { AllFilters } from "../views/search/search-filters";
import { ResultItemWithExtra } from "../api/search/types";
import { TagOption } from "../api/common-tags-functionality";

type AppState = {
  userNetworkIsConfigured: boolean;
  toasts: React.JSX.Element[];
  isHelpAndSupportModalOpen: boolean;
  /* Search */
  searchBarInput: string;
  searchChatId: string;
  searchIsActive: boolean;
  isSearchFormOpen: boolean;
  filters: AllFilters[];
  kendraResults: ResultItemWithExtra[];
  filteredKendraResults: ResultItemWithExtra[];
  paginatedKendraResults: ResultItemWithExtra[];
  faqResults: ResultItemWithExtra[];
  currentPage: number;
  isUserAxelBetaTester: boolean;
  includeRetiredDocs: boolean;
  documentTypeValues: TagOption[];
  actions: {
    setUserNetworkIsConfigured: (val: boolean) => void;
    setToasts: (val: React.JSX.Element[]) => void;
    setIsHelpAndSupportModalOpen: (val: boolean) => void;
    /* Search */
    setSearchBarInput: (val: string) => void;
    setSearchChatId: (val: string) => void;
    setSearchIsActive: (val: boolean) => void;
    setIsSearchFormOpen: (val: boolean) => void;
    setFilters: (val: AllFilters[]) => void;
    setKendraResults: (val: ResultItemWithExtra[]) => void;
    setFilteredKendraResults: (val: ResultItemWithExtra[]) => void;
    setPaginatedKendraResults: (val: ResultItemWithExtra[]) => void;
    setFaqResults: (val: ResultItemWithExtra[]) => void;
    setCurrentPage: (val: number) => void;
    setIsUserAxelBetaTester: (val: boolean) => void;
    setIncludeRetiredDocs: (val: boolean) => void;
    setDocumentTypeValues: (val: TagOption[]) => void;
  };
};

const useAppStore = create<AppState>()((set) => ({
  toasts: [],
  isHelpAndSupportModalOpen: false,
  userNetworkIsConfigured: false,
  /* Search */
  searchBarInput: "",
  searchChatId: "",
  searchIsActive: false,
  isSearchFormOpen: false,
  filters: [],
  resultCounts: undefined,
  kendraResults: [],
  filteredKendraResults: [],
  paginatedKendraResults: [],
  faqResults: [],
  currentPage: 1,
  isUserAxelBetaTester: false,
  includeRetiredDocs: false,
  documentTypeValues: [],
  actions: {
    setUserNetworkIsConfigured: (userNetworkIsConfigured) => set(() => ({ userNetworkIsConfigured })),
    setToasts: (toasts) => set(() => ({ toasts })),
    setIsHelpAndSupportModalOpen: (isHelpAndSupportModalOpen) => set(() => ({ isHelpAndSupportModalOpen })),
    /* Search */
    setSearchBarInput: (searchQuery) => set(() => ({ searchBarInput: searchQuery })),
    setSearchChatId: (searchChatId) => set(() => ({ searchChatId })),
    setSearchIsActive: (searchIsActive) => set(() => ({ searchIsActive })),
    setIsSearchFormOpen: (isSearchFormOpen) => set(() => ({ isSearchFormOpen })),
    setFilters: (filters) => set(() => ({ filters })),
    setKendraResults: (kendraResults) => set(() => ({ kendraResults })),
    setFilteredKendraResults: (filteredKendraResults) => set(() => ({ filteredKendraResults })),
    setPaginatedKendraResults: (paginatedKendraResults) => set(() => ({ paginatedKendraResults })),
    setFaqResults: (faqResults) => set(() => ({ faqResults })),
    setCurrentPage: (currentPage) => set(() => ({ currentPage })),
    setIsUserAxelBetaTester: (isUserAxelBetaTester) => set(() => ({ isUserAxelBetaTester })),
    setIncludeRetiredDocs: (includeRetiredDocs) => set(() => ({ includeRetiredDocs })),
    setDocumentTypeValues: (documentTypeValues) => set(() => ({ documentTypeValues })),
  },
}));

export const useToasts = () => useAppStore((state) => state.toasts);
export const useIsHelpAndSupportModalOpen = () => useAppStore((state) => state.isHelpAndSupportModalOpen);
/* Search */
export const useSearchBarInput = () => useAppStore((state) => state.searchBarInput);
export const useSearchChatId = () => useAppStore((state) => state.searchChatId);
export const useSearchIsActive = () => useAppStore((state) => state.searchIsActive);
export const useIsSearchFormOpen = () => useAppStore((state) => state.isSearchFormOpen);
export const useFilters = () => useAppStore((state) => state.filters);
export const useKendraResults = () => useAppStore((state) => state.kendraResults);
export const useFilteredKendraResults = () => useAppStore((state) => state.filteredKendraResults);
export const usePaginatedKendraResults = () => useAppStore((state) => state.paginatedKendraResults);
export const useFaqResults = () => useAppStore((state) => state.faqResults);
export const useCurrentPage = () => useAppStore((state) => state.currentPage);
export const useIncludeRetiredDocs = () => useAppStore((state) => state.includeRetiredDocs);
export const useDocumentTypeValues = () => useAppStore((state) => state.documentTypeValues);

export const useAppActions = () => useAppStore((state) => state.actions);
