"use client";
import { useEffect } from "react";

import { trackEvent } from "../../utils/event-tracker";

import { Modal } from "../../components/modal";
import { Button } from "../../components/button";
import { InputErrorMessage } from "../../components/Input";
import { ComponentConfirmationReply } from "./models";

export const TESTID_ENUMS = {
  prompt: "moderation-component-confirmation-modal-prompt",
  confirmButton: "moderation-component-confirmation-modal-submit",
};
export default function ComponentModerationConfirmationModal({
  show,
  onConfirm,
  onHide,
  type,
  target,
  loading,
  error,
}: {
  show: boolean;
  onConfirm: (message: ComponentConfirmationReply) => void;
  onHide: () => void;
  type: string;
  target: number;
  loading: boolean;
  error?: string;
}) {
  useEffect(() => {
    if (show) {
      trackEvent({
        name: `confirm-${type}-modal-opened`,
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={false}
      onClose={() => {
        onHide();
      }}
      header="Confirmation"
    >
      <h4 className="text-center" data-testid={TESTID_ENUMS.prompt}>
        You're sure you want to {type} this component?
      </h4>
      <div className="text-center mb-8">
        <Button
          name={`moderator-confirm-component-${type}`}
          data-testid={TESTID_ENUMS.confirmButton}
          loading={loading}
          className="text-capitalize"
          onClick={() => onConfirm({ type, id: target })}
          level="primary"
          theme="darker"
          rounded="pill"
          size="lg"
        >
          {type}
        </Button>
      </div>
      {error && (
        <div className="text-center mb-8">
          <InputErrorMessage>Operation failed: {error}</InputErrorMessage>
        </div>
      )}
    </Modal>
  );
}

ComponentModerationConfirmationModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  onHide: () => {},
  loading: false,
};
