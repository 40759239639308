import styled from "@emotion/styled";

const StepCircle = styled.div`
  text-align: center;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  margin: 0 1rem 0 0;
  border: 1px solid black;
  border-radius: 999%;
  line-height: 1.4;
`;

type FormHeadingProps = {
  currentStep?: number;
  totalSteps?: number;
  title: string;
  infoLink?: string;
};
type FormHeadingV2Props = {
  currentStep: number;
  totalSteps: number;
  title: string;
};

export function FormHeadingV2({ currentStep, totalSteps, title }: FormHeadingV2Props) {
  return (
    <div className="d-flex align-items-center pb-5 border-bottom mb-5">
      <StepCircle className="bg-black text-white">{currentStep}</StepCircle>
      <div>
        <p className="fw-light mb-0" data-testid="step-title">
          {title}
        </p>
        <p data-testid="step-count" className="small fw-light mb-0">
          {`Step ${currentStep} of ${totalSteps}`}
        </p>
      </div>
    </div>
  );
}

export function FormHeading({ currentStep, totalSteps, title = "", infoLink = undefined }: FormHeadingProps) {
  return (
    <div className="d-flex align-items-center">
      {currentStep && <StepCircle>{currentStep}</StepCircle>}
      <div>
        <p className="lead mb-0" data-testid="step-title">
          {title}
        </p>
        {currentStep && (
          <p data-testid="step-count" className="small">
            {`Step ${currentStep} of ${totalSteps}`}
          </p>
        )}
        {infoLink && (
          <div data-testid="info-container">
            (for further details click{" "}
            <a href={infoLink} target="_blank" rel="noreferrer">
              here
            </a>
            )
          </div>
        )}
      </div>
    </div>
  );
}
