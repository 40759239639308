import { graphql } from "../../gql";

export const GET_TAG_DETAILS = graphql(`
  query GetTagDetails($tags: [String!]!) {
    getTagDetails(tagDetails: { tags: $tags }) {
      tagDetails {
        id
        tagName
        tagType
        kHubOverviewPage
        icon
        highlighted
        description
      }
    }
  }
`);

export const GET_RESOURCES_FOR_TAG = graphql(`
  query GetResourcesForTag($tag: String!) {
    getResourcesForTag(tagName: $tag) {
      resources {
        sourceId
        resourceType
        resourceName
        tags
      }
      relatedTags {
        tagName
        tagType
        description
        icon
      }
    }
  }
`);

export const GET_TAG_DETAILS_AND_RELATED_TAGS = graphql(`
  query GetTagDetailsAndRelatedTags($tags: [String!]!) {
    getRelatedTagDetails(tagDetails: { tags: $tags }) {
      tagDetails {
        id
        tagName
        tagType
        kHubOverviewPage
        highlighted
        description
        icon
        relatedTags {
          id
          tagName
          tagType
          kHubOverviewPage
          highlighted
          description
          icon
        }
      }
    }
  }
`);

export const GET_ALL_TAG_DETAILS = graphql(`
  query GetAllTagDetails {
    getAllTagDetails {
      tagDetails {
        id
        tagName
        tagType
        kHubOverviewPage
        icon
        highlighted
        description
      }
    }
  }
`);

export const DELETE_GLOBAL_TAG = graphql(`
  mutation DeleteGlobalTag($tag: String!) {
    deleteGlobalTag(tagName: $tag) {
      tagName
      tagType
    }
  }
`);

export const ADD_GLOBAL_TAG = graphql(`
  mutation AddGlobalTag($command: TagSubmissionType!) {
    addGlobalTag(command: $command) {
      tagName
      tagType
      description
      icon
      highlighted
      kHubOverviewPage
    }
  }
`);
export const ADD_TAG_TYPE = graphql(`
  mutation AddTagType($tagType: String!) {
    addTagType(tagType: { tagType: $tagType }) {
      tagType
      id
      updatedDate
    }
  }
`);

export const GET_ALL_TAG_TYPES = graphql(`
  query GetAllTagTypes {
    getAllTagTypes {
      tagType
      id
      updatedDate
    }
  }
`);

export const DELETE_TAG_TYPE = graphql(`
  mutation DeleteTagType($tagType: String!) {
    deleteUnusedTagType(tagType: $tagType) {
      tagType
      id
      updatedDate
    }
  }
`);
