import { UncontrolledAlert } from "reactstrap";
import { Alert24 } from "@bphxd/ds-core-react/lib/icons";
import styled from "@emotion/styled";

const ErrorContainer = styled.div`
  display: block;
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 9999;
`;

type ErrorAlertProps = {
  errorMessage?: string;
  onClose?: () => void;
};

export function ErrorAlert({ onClose, errorMessage }: ErrorAlertProps) {
  return (
    <ErrorContainer>
      <UncontrolledAlert color="danger" className="shadow-sm justify-content-center" id="alert" onClick={onClose}>
        <Alert24 strokeWidth="2" className="text-default" />
        <div>Sorry something went wrong. Please try again later.</div>
        {errorMessage && <p>{errorMessage}</p>}
      </UncontrolledAlert>
    </ErrorContainer>
  );
}
