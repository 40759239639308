import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form, Row, Col, Progress } from "reactstrap";
import { FormGroup } from "../../components/form-group";
import Input, { InputErrorMessage } from "../../components/Input";
import { Button } from "../../components/button";
import { AccelerateTeam } from "./wizard";

type Step2Data = {
  tooling_team: string;
};

type Step2FormProps = {
  options: AccelerateTeam[];
  isSubmitting: boolean;
  onSubmit: (data: Step2Data) => Promise<void> | void;
  onBackClick: (data: Step2Data) => Promise<void> | void;
};

export function Step2Form({ options, isSubmitting, onSubmit, onBackClick, ...data }: Step2FormProps) {
  const schemaObject = {
    tooling_team: yup.string().required("Required"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<Step2Data>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  function serializeValues(values: Step2Data) {
    onSubmit(values);
  }

  function handleBackClick() {
    onBackClick(getValues());
  }

  return (
    <Form onSubmit={handleSubmit(serializeValues)} data-testid="form-step-2">
      <Controller
        name="tooling_team"
        control={control}
        render={({ field }) => (
          <>
            {options.map((type) => (
              <Row key={type.value}>
                <Col xs="12">
                  <FormGroup check>
                    <Input
                      {...field}
                      id={`tooling-team-${type.value}`}
                      data-testid={`form-step-2-radio-${type.value}`}
                      trackingName="help-and-support-team"
                      type="radio"
                      value={type.value}
                      checked={type.value === control._formValues.tooling_team}
                    />
                    <label htmlFor={`tooling-team-${type.value}`}>
                      <span className="font-normal">{type.label}</span> - {type.description}
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            ))}
          </>
        )}
      />
      <Row>
        <Col xs="12">
          <InputErrorMessage>{errors.root?.message}</InputErrorMessage>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="2">
          <Button
            data-testid="form-step-2-back"
            name="help-and-support-step-2-back"
            onClick={handleBackClick}
            type="button"
            level="tertiary"
            theme="darker"
            rounded="pill"
          >
            Back
          </Button>
        </Col>
        <Col xs="8">
          <div className="m-auto">
            <Progress color="dark" value={50} style={{ height: 2 }} />
          </div>
        </Col>
        <Col xs="2">
          <div className="text-right">
            <Button
              data-testid="form-step-2-submit"
              name="help-and-support-step2-next"
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              level="primary"
              theme="darker"
              rounded="pill"
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
