import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form, Row, Col, Progress } from "reactstrap";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Button } from "../../components/button";
import { Step3Data } from "./wizard";

type Step3FormProps = {
  isSubmitting: boolean;
  onSubmit: (data: Step3Data) => Promise<void>;
  onBackClick: (data: Step3Data) => Promise<void>;
};

export function Step3Form({ isSubmitting, onBackClick, onSubmit }: Step3FormProps) {
  const schemaObject = {
    incident_url: yup.string().url("Must be a valid URL"),
    description: yup.string().required("Required"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = useForm<Step3Data>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  function handleBackClick() {
    onBackClick(getValues());
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="form-step-3">
      <Row>
        <Col xs="12">
          <FormGroup>
            <Label htmlFor="incident_url">
              <span>Incident url</span>
              <span className="optional">optional</span>
            </Label>
            <Controller
              name="incident_url"
              control={control}
              defaultValue={typeof window !== "undefined" ? window?.location?.href : ""}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  id="incident_url"
                  data-testid="form-step-3-incident-url"
                  trackingName="help-and-support-incident-url"
                  makeRoomForError
                  invalid={!!error}
                  error={error?.message}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <FormGroup>
            <Label htmlFor="description">Description</Label>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  id="description"
                  data-testid="form-step-3-description"
                  trackingName="help-and-support-description"
                  type="textarea"
                  style={{ minHeight: "8rem", lineHeight: "unset" }}
                  {...field}
                  invalid={!!error}
                  error={error?.message}
                  makeRoomForError
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row className="align-items-center">
        <Col xs="2">
          <Button
            data-testid="form-step-3-back"
            name="help-and-support-step-3-back"
            onClick={handleBackClick}
            type="button"
            level="tertiary"
            theme="darker"
            rounded="pill"
          >
            Back
          </Button>
        </Col>
        <Col xs="8">
          <div className="m-auto">
            <Progress color="dark" value={75} style={{ height: 2 }} />
          </div>
        </Col>
        <Col xs="2">
          <div className="text-right">
            <Button
              data-testid="form-step-3-submit"
              name="help-and-support-submit"
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              level="primary"
              theme="darker"
              rounded="pill"
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
