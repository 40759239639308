import { Fragment, ReactNode } from "react";
import { LinkButton } from "./link-button";
import { Button } from "./button";
import styled from "@emotion/styled";
import { Avatar24 } from "@bphxd/ds-core-react/lib/icons";
import CategoryBadge from "./category-badge";

type ContainerProps = {
  bgColor: string;
  level: 1 | 2;
  subtitle: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${(props) => (props.level === 1 ? "197px" : "265px")};
  padding-top: ${(props) => (props.level === 1 ? "24px" : "71px;")};
  padding-bottom: 56px;

  .bp-hero__main {
    display: flex;
    width: ${(props) => (props.level === 1 ? "" : "100%")};
    height: 100%;
    position: relative;
  }

  .bp-hero__left {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: ${(props) => (props.level === 1 ? "" : "100%")};
  }

  .bp-hero__right {
    max-width: 360px;
    position: ${(props) => (props.level === 1 ? "" : "absolute")};
    right: ${(props) => (props.level === 1 ? "" : "20px")};
    top: ${(props) => (props.level === 1 ? "" : (props) => (props.subtitle ? "-10px" : "-40px"))};
  }

  .bp-hero__right img {
    object-fit: contain;
    height: ${(props) => (props.level === 1 ? "100%" : "185px")};
  }

  .bp-hero__right__image-loading {
    width: 362px;
  }

  .bp-hero__right__image-loaded {
    width: 100%;
  }

  .bp-hero__heading {
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;

    letter-spacing: 0.2px;
    color: #111;
    margin-bottom: 0;

    font-size: ${(props) => (props.level === 1 ? "18px" : "26px")};
    line-height: ${(props) => (props.level === 1 ? "1.78" : "1.5")};
  }

  .bp-hero__card-subtitle {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.25px;
    color: rgba(17, 17, 17, 0.7);
  }

  .bp-hero__cta {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: fit-content;
  }

  .bp-hero__cta a {
    margin-top: 12px;
    width: fit-content;
  }

  .bp-hero__description__wrapper {
    margin-top: ${(props) => (props.level === 1 ? "12px" : "51px")};
    padding: ${(props) => (props.level === 1 ? "0" : "24px 0 24px 30px")};
    background-color: ${(props) =>
      props.level === 1 ? "none" : (props) => (props.bgColor ? props.bgColor : "#fffbea")};
    width: 100%;
  }

  .bp-hero__description {
    margin-top: 0;
    font-size: 23px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: ${(props) => (props.level === 1 ? "1.77" : "1.75")};
    letter-spacing: ${(props) => (props.level === 1 ? "0.25px" : "0.2px")};
    color: rgba(17, 17, 17, 0.7);
    width: ${(props) => (props.level === 1 ? "100%" : "calc(100% - 360px)")};
  }

  .bp-hero__rectangle {
    width: fit-content;
    display: inline-flex;
    min-height: 27px;
    margin: 12px 14px 0 0;
    padding: 3px 8px;
    background-color: #f2f2f2;
    align-items: center;

    .bp-hero__rectangle-icon {
      margin-right: 10px;
      display: flex;

      img {
        height: 16px;
      }
    }
  }

  .bp-hero-card__user-details {
    display: flex;
    justify-content: end;
    align-items: center;

    .component-card__user-photo {
      width: 32px;
      height: auto;
      border-radius: 50%;
    }
    .component-card__user-name {
      padding-left: 5px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.75;
      letter-spacing: 0.25px;
      color: #111;
    }
  }

  .bp-hero__rectangle-text {
    font-family: Arial;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.1;
    letter-spacing: 1px;
    color: #111;
  }
`;

const ctaButtonStyle: {
  "data-testid": string;
  name: string;
  level: "primary";
  theme: "darker";
  rounded: "pill";
  size: "md";
} = {
  "data-testid": "hero-cta",
  name: "hero cta",
  level: "primary",
  theme: "darker",
  rounded: "pill",
  size: "md",
};

type HeroProps = {
  bgColor: string;
  heading: ReactNode;
  body: ReactNode;
  imageUrl: string;
  ctaOnClick: () => void;
  ctaText: string;
  ctaUrl: string;
  level: 1 | 2;
  subtitle: string;
  categories: { name: string }[];
  author: { name: string; photo_url?: string };
};

export function Hero({
  bgColor = "",
  heading = "",
  body = "",
  imageUrl = "",
  ctaOnClick = () => {},
  ctaText = "",
  ctaUrl = "",
  level = 1,
  subtitle = "",
  categories = [],
  author,
}: Partial<HeroProps>) {
  return (
    <Container bgColor={bgColor} level={level} subtitle={!!subtitle}>
      <div className="bp-hero__main">
        <div className="bp-hero__left">
          <h1 className="bp-hero__heading">{heading}</h1>

          <span className="bp-hero__card-subtitle">{subtitle.toLowerCase()}</span>

          {categories?.map(({ name }) => (
            <div className="mt-2" key={name}>
              <CategoryBadge category={name} />
            </div>
          ))}

          {body && (
            <div className="bp-hero__description__wrapper col-xs-12">
              <div className="bp-hero__description">{body}</div>
            </div>
          )}

          <div className="bp-hero__cta">
            {ctaUrl ? (
              <LinkButton href={ctaUrl} {...ctaButtonStyle} onClick={ctaOnClick}>
                {ctaText}
              </LinkButton>
            ) : ctaText ? (
              <Button {...ctaButtonStyle} onClick={ctaOnClick}>
                {ctaText}
              </Button>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        {imageUrl && (
          <div className="bp-hero__right">
            <img
              src={imageUrl}
              alt="illustration"
              className="bp-hero__right__image-loading"
              onLoad={(ev) => {
                ev.currentTarget.className = "bp-hero__right__image-loaded";
              }}
            />
          </div>
        )}
      </div>

      {author?.name?.trim() && (
        <div className="bp-hero-card__user-details">
          {author?.photo_url ? (
            <img className="component-card__user-photo" alt="profile" src={author.photo_url} />
          ) : (
            <Avatar24 />
          )}
          <div className="component-card__user-name">{author.name}</div>
        </div>
      )}
    </Container>
  );
}
