import { getKHubAPI } from "../../rest-api";
export const endpoint = (uuid: string) => `v2/documents/${uuid}/breadcrumb`;

export type BreadcrumbData = {
  uuid: string | null;
  title: string;
  path: string;
};
export async function getBreadcrumbs(uuid: string) {
  try {
    const response = await getKHubAPI<{ breadcrumbs: BreadcrumbData[] }>({
      spanName: "Get khub document breadcrumbs",
      query: endpoint(uuid),
    });
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
}
