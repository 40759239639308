import { SVGProps } from "react";

export function PeopleThreeEqual(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>people-three-equal</title>
      <g id="IP_organisation" data-name="IP organisation">
        <path d="M500,210.90656A104.02035,104.02035,0,1,0,395.97966,106.88622,104.13929,104.13929,0,0,0,500,210.90656Zm0-163.48623a59.46589,59.46589,0,1,1-59.46589,59.46589A59.53363,59.53363,0,0,1,500,47.42033Z" />
        <path d="M556.87075,222.13219H443.12925A104.1393,104.1393,0,0,0,339.10891,326.15254v73.569a63.22086,63.22086,0,0,0,63.14975,63.14976H597.74134a63.22023,63.22023,0,0,0,63.14975-63.14976v-73.569A104.1393,104.1393,0,0,0,556.87075,222.13219Zm59.46588,177.58934a18.617,18.617,0,0,1-18.59529,18.5953H402.25866a18.617,18.617,0,0,1-18.59529-18.5953v-73.569a59.53363,59.53363,0,0,1,59.46588-59.46589h113.7415a59.53363,59.53363,0,0,1,59.46588,59.46589Z" />
        <path d="M641.61123,739.24234,522.27662,654.09661l-.03227-114.49845a22.27678,22.27678,0,0,0-22.27722-22.27143h-.00581a22.27679,22.27679,0,0,0-22.27142,22.283l.03227,114.453-119.3421,85.23372A22.27778,22.27778,0,1,0,384.27444,775.555l115.70272-82.63508,115.75613,82.59253a22.27773,22.27773,0,1,0,25.87794-36.27011Z" />
        <path d="M836.63366,745.1694A104.02035,104.02035,0,1,0,732.61332,641.14906,104.1393,104.1393,0,0,0,836.63366,745.1694Zm0-163.48623a59.46589,59.46589,0,1,1-59.46588,59.46589A59.53363,59.53363,0,0,1,836.63366,581.68317Z" />
        <path d="M893.50441,756.395H779.76292A104.1393,104.1393,0,0,0,675.74257,860.41538v73.569a63.22023,63.22023,0,0,0,63.14976,63.14975H934.375a63.22023,63.22023,0,0,0,63.14975-63.14975v-73.569A104.13929,104.13929,0,0,0,893.50441,756.395ZM952.9703,933.98438a18.61706,18.61706,0,0,1-18.5953,18.59529H738.89233a18.61706,18.61706,0,0,1-18.5953-18.59529v-73.569a59.53363,59.53363,0,0,1,59.46589-59.46589H893.50441a59.53363,59.53363,0,0,1,59.46589,59.46589Z" />
        <path d="M163.36634,745.56A104.02034,104.02034,0,1,0,59.346,641.53968,104.13866,104.13866,0,0,0,163.36634,745.56Zm0-163.48623a59.46589,59.46589,0,1,1-59.46589,59.46589A59.53363,59.53363,0,0,1,163.36634,582.07379Z" />
        <path d="M220.23708,756.78566H106.49559A104.13867,104.13867,0,0,0,2.47525,860.806v73.569A63.22,63.22,0,0,0,65.624,997.52475H261.10864A63.22,63.22,0,0,0,324.25743,934.375V860.806A104.13867,104.13867,0,0,0,220.23708,756.78566ZM279.703,934.375a18.61623,18.61623,0,0,1-18.59433,18.5953H65.624A18.61623,18.61623,0,0,1,47.0297,934.375V860.806a59.53364,59.53364,0,0,1,59.46589-59.46589H220.23708A59.53363,59.53363,0,0,1,279.703,860.806Z" />
      </g>
    </svg>
  );
}
