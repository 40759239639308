const PaddingSizes = [
  "NONE", // 0
  "XS", // 16px
  "S", // 24px
  "M", // 40px
  "L", // 56px
  "XL", // 64px
  "JUMBO", // 96px
] as const;

type PaddingSize = (typeof PaddingSizes)[number];

/** convert categorical "PaddingSize" to the numerical value used in the bootstrap classes (e.g. py-4) */
const PaddingSizeMap = (size?: PaddingSize) => {
  switch (size) {
    case "NONE":
      return 0;
    case "XS":
      return 4;
    case "S":
      return 5;
    case "M":
      return 7;
    case "L":
      return 9;
    case "XL":
      return 10;
    case "JUMBO":
      return 12;
    default:
      return 0;
  }
};

type DividerProps = {
  pTop?: PaddingSize;
  pBottom?: PaddingSize;
};

export function Divider({ pTop, pBottom }: DividerProps) {
  return (
    <div className={`d-flex pt-${PaddingSizeMap(pTop)} pb-${PaddingSizeMap(pBottom)}`}>
      <div className="border-top border-primary w-100 h-1px"></div>
    </div>
  );
}
