export const NEW_NOTIFICATION_ENUM = "NEW_NOTIFICATION";
export const REQUEST_NOTIFICATION_LIST_ENUM = "REQUEST_NOTIFICATION_LIST";
export const NOTIFICATION_LIST_ENUM = "NOTIFICATION_LIST";
export const UPDATE_NOTIFICATION_READ_STATUS_ENUM = "UPDATE_NOTIFICATION_READ_STATUS";
export const UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM = "UPDATE_NOTIFICATION_TRAY_READ_STATUS";

export const COMPONENT_APPROVED_EVENT_ENUM = "COMPONENT_APPROVED";
export const USER_ADDED_TO_TEAM = "USER_ADDED_TO_TEAM";
export const USER_REMOVED_FROM_TEAM = "USER_REMOVED_FROM_TEAM";
export const KNOWLEDGEHUB_DOCUMENTS_ISSUE_CREATED = "KNOWLEDGEHUB_DOCUMENTS_ISSUE_CREATED";
export const KNOWLEDGEHUB_DOCUMENTS_CONTENT_PUBLISHED = "KNOWLEDGEHUB_DOCUMENTS_CONTENT_PUBLISHED";
export const KNOWLEDGEHUB_GAP_ISSUE_CREATED = "KNOWLEDGEHUB_GAP_ISSUE_CREATED";
export const KNOWLEDGEHUB_GENERATED_DOCUMENTS_CREATED = "KNOWLEDGEHUB_GENERATED_DOCUMENTS_CREATED";

export const SUCCESSFUL_SUPPORT_GROUP_ONBOARDING = "SUPPORT_GROUP_ONBOARDED_SUCCESSFULLY";

export const PRODUCT_NOT_LINKED_TO_L3 = "PRODUCT_NOT_LINKED_TO_L3";
export const PRODUCT_NOT_LINKED_TO_REPO = "PRODUCT_NOT_LINKED_TO_REPO";
export const REPOS_HAVE_NO_PROD_STAGE = "REPOS_HAVE_NO_PROD_STAGE";

export const CUSTOM_ADMIN_NOTIFICATION = "CUSTOM_ADMIN_NOTIFICATION";

export const ADDED_TO_PRODUCT = "ADDED_TO_PRODUCT";
export const REMOVED_FROM_PRODUCT = "REMOVED_FROM_PRODUCT";
