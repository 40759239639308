const SAXEL_URL = "/axel";

export function sAxelUrlAndQuery(searchQuery = "", chatId?: string) {
  const encodedSearchQuery = encodeURIComponent(searchQuery);
  return `${SAXEL_URL}${chatId ? `?chatId=${chatId}&s=${encodedSearchQuery}` : `?s=${encodedSearchQuery}`}`;
}

export function sAxelUrl(chatId: string) {
  return `${SAXEL_URL}${`?chatId=${chatId}`}`;
}
