"use client";
import { useEffect } from "react";

import { trackEvent } from "../../utils/event-tracker";

import { Modal } from "../../components/modal";
import { Button } from "../../components/button";
import { InputErrorMessage } from "../../components/Input";

const TESTID_ENUMS = {
  prompt: "moderation-tag-type-confirmation-modal-prompt",
  confirmButton: "moderation-tag-type-confirmation-modal-submit",
};

type TagTypeDeleteModalProps = {
  show: boolean;
  onConfirm: (id: string) => void;
  onHide: () => void;
  target: string;
  loading: boolean;
  error?: string;
  associatedTags: string[];
};

export function TagTypeDeleteModal({
  show,
  onConfirm,
  onHide,
  target,
  loading,
  error,
  associatedTags = [],
}: TagTypeDeleteModalProps) {
  useEffect(() => {
    if (show) {
      trackEvent({
        name: `confirm-tag-type-delete-modal-opened`,
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={false}
      onClose={onHide}
      header={associatedTags.length ? "" : "Confirmation"}
    >
      <h4 className="text-center" data-testid={TESTID_ENUMS.prompt}>
        {associatedTags.length
          ? `Sorry, you cannot delete the tag type "${target}"`
          : `You're sure you want to delete the "${target}" tag type?`}
      </h4>
      {error ? (
        <InputErrorMessage className="fs-6 text-center mt-4 mb-6 w-100">
          {error && `Operation failed: ${error}`}
        </InputErrorMessage>
      ) : null}
      {associatedTags.length ? (
        <div className="mx-auto" style={{ width: "fit-content" }}>
          Please delete the following tags before you can delete this tag type:
          <ol>
            {associatedTags.map((tag) => (
              <li key={tag}>{tag}</li>
            ))}
          </ol>
        </div>
      ) : null}
      {associatedTags.length === 0 ? (
        <div className="text-center">
          <Button
            name="moderator-confirm-tag-type-delete"
            data-testid={TESTID_ENUMS.confirmButton}
            loading={loading}
            disabled={!!error}
            className="text-capitalize"
            onClick={() => onConfirm(target)}
            level="primary"
            theme="darker"
            rounded="pill"
            size="lg"
          >
            Delete
          </Button>
        </div>
      ) : null}
    </Modal>
  );
}
