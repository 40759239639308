import { Box } from "..";
import { LinkBadge } from "../link-badge";
import { TESTID } from "../../views/khub/enums";

export function TagBar({ contentTags }: { contentTags: string[] }) {
  return contentTags?.length > 0 ? (
    <Box dataTestid={TESTID.tagBar} flex gap="4" padding={[16, 0, 30, 0]} className="flex-wrap">
      {contentTags.map((tag) => (
        <LinkBadge key={tag} href={`/axel?s=${tag}`} name={`kh-tag-${tag}`}>
          {tag}
        </LinkBadge>
      ))}
    </Box>
  ) : null;
}
