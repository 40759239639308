/* istanbul ignore file */

import { Tree, getParents } from "@minoru/react-dnd-treeview";
import styled from "@emotion/styled";
import { Nav } from "reactstrap";
import { isNodeOrParentInvisible, useNavTreeQuery } from "../../api/khub/v2/documents/tree";
import { NavTreeItem } from "./nav-tree-item";
import { NavTreeSkeleton } from "../../components/skeletons/nav-tree";
import { TESTID } from "./enums";
import { useIsAuthenticated } from "../../hooks/use-is-authenticated";

const TreeContainer = styled(Nav)({
  height: "100%",
  width: 256,
  ul: {
    listStyle: "none",
  },
  ".treeRoot": {
    height: "100%",
    width: "inherit",
    paddingLeft: 0,
  },
  ".draggingSource": {
    opacity: 0.66,
  },
  ".placeholderContainer": {
    position: "relative",
  },
  ".dropTarget": {
    backgroundColor: "#e8f0fe",
  },
});

export function NavTree({ docUuid }: { docUuid: string }) {
  const { email } = useIsAuthenticated();
  const {
    isLoading,
    isSuccess,
    data: treeData,
  } = useNavTreeQuery({
    uuid: docUuid,
    email: email || "",
  });

  if (isLoading || !isSuccess || !treeData) {
    return <NavTreeSkeleton className="pt-3" data-testid={TESTID.navSkeleton} />;
  }

  // initialise the tree to have all parents of the active node as "open"
  const openNodes = getParents(treeData, docUuid).map(({ id }) => id);
  openNodes.push(docUuid);

  return (
    <TreeContainer>
      <Tree
        canDrag={() => false}
        canDrop={() => false}
        onDrop={() => {}}
        tree={treeData}
        rootId="0"
        render={(node, { depth, isOpen, onToggle, hasChild }) => {
          if (isNodeOrParentInvisible(node, treeData)) {
            return <></>;
          }

          return (
            <NavTreeItem
              active={node.id === docUuid}
              draggable={false}
              node={node}
              hasChild={hasChild}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
            />
          );
        }}
        classes={{
          root: "treeRoot",
          draggingSource: "draggingSource",
          placeholder: "placeholderContainer",
        }}
        listComponent="div"
        listItemComponent="div"
        sort={false}
        initialOpen={openNodes}
      />
    </TreeContainer>
  );
}
