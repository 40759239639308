"use client";
import { useState } from "react";
import { Box, Divider, ResponsiveImage } from "..";

import S from "./hero-panel.style";
import T from "./hero-panel.type";

const HeroPanel = ({ info: { title, body, link, path, id }, trackingName, dataTestid }) => {
  const [hide, setHide] = useState(localStorage.getItem(`banner-${id}`) === "true");

  const handleClick = () => {
    localStorage.setItem(`banner-${id}`, true);
    setHide(true);
  };

  return hide ? null : (
    <>
      <Box gap={30} display="grid" dataTestid={dataTestid} padding={[30, 0, 0, 0]}>
        <Box width={255}>
          <ResponsiveImage width={255} height={143} path={path} borderRadius={8} />
        </Box>

        <Box vertical gap={16} fullWidth>
          <Box gap={16}>
            <div className="d-flex flex-grow-1 fs-6 text-truncate my-0 py-0 w-100 text-uppercase ls-1-5">{title}</div>

            <Box absolute={[-12, 0]}>
              <S.Button
                name={`${trackingName}-dismiss`}
                size="md"
                onClick={handleClick}
                data-testid="dismiss-button"
                color="light-tertiary"
              >
                Dismiss
              </S.Button>
            </Box>
          </Box>

          <Box vertical gap={8}>
            <div className="d-flex align-items-center mb-0">{body}</div>

            {link?.href && (
              <S.Link target={link.target} title={link.title} href={link.href} dataTestid="learn-more-link">
                {link.text}
              </S.Link>
            )}
          </Box>
        </Box>
      </Box>

      <Divider />
    </>
  );
};

HeroPanel.defaultProps = T.defaultProps;

export default HeroPanel;
