import { downloadCSV } from "../utils/download-csv";
import { ACCELERATE_APIS } from "../api/config";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { type useAsyncActionType, useAsyncAction } from "./use-async-action";
import { ReportClient } from "@accelerate/reports/lib/report-client";

async function getToken(): Promise<string> {
  const token = await requestAccessToken(ACCELERATE_APIS.apollo.scopes);
  if (!token) {
    throw new Error("Could not get Apollo Token!");
  }
  return token;
}

function createReportClient(): ReportClient {
  const host = ACCELERATE_APIS.apollo.url;
  if (!host) {
    throw new Error("Could not find Accelerate Graphql hostname!");
  }
  return new ReportClient(host);
}

function useReportHook(method: keyof ReportClient, filenamePrefix: string): useAsyncActionType<void> {
  return {
    ...useAsyncAction(async () => {
      const token = await getToken();
      const client = createReportClient();
      const file = await client[method](token);
      downloadCSV(file, filenamePrefix);
    }),
    data: undefined,
  };
}

export function usePerceivedAndEmpiricalsReport(): useAsyncActionType<void> {
  return useReportHook("productPerceivedAndEmpiricalMetricsReport", "product-perceived-and-empirical-metrics");
}

export function useProductQuizAssessmentReport(): useAsyncActionType<void> {
  return useReportHook("productQuizAssessments", "product-quiz-assessment");
}
