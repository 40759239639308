export function downloadCSV(content: string, filenamePrefix: string): void {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement("a");
  const currentDateTime = new Date().toISOString().replace(/:/g, "-");

  const filename = `${filenamePrefix}-${currentDateTime}.csv`;
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}
