/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConsoleSpanExporter, SimpleSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { Attributes, Span, SpanStatusCode } from "@opentelemetry/api";
import packageJson from "../../package.json";
import { W3CTraceContextPropagator } from "@opentelemetry/core";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { msalInstance } from "../utils/helpers/msal-helper";
import { hash } from "../utils/sha256";
import { FetchWithAuthArgs, fetchWithAuth } from "../api/fetch-with-auth";
import { env } from "next-runtime-env";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: packageJson.name,
    [SemanticResourceAttributes.SERVICE_NAMESPACE]: "accelerate-platform",
  }),
});

const OPEN_TELEMETRY_COLLECOR_URL = env("NEXT_PUBLIC_OPEN_TELEMETRY_COLLECTOR_URL");

const oltpTraceExporter = new OTLPTraceExporter({
  url: OPEN_TELEMETRY_COLLECOR_URL,
});

if (process.env.NODE_ENV !== "test" && OPEN_TELEMETRY_COLLECOR_URL) {
  const printTracesToDevConsoleInLocalHost = () => {
    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
    // provider.addSpanProcessor(new BatchSpanProcessor(oltpTraceExporter));
    /*
    NOTE: SimpleSpanProcessor in use for now as AWS#AWSManagedRulesCommonRuleSet#SizeRestrictions_BODY
    WAF rule blocks batched telemetry events because the body size becomes too big. TODO: Reinstate
    BatchSpanProcessor once the exemptions are in place for the spokes and a custom ALB web ACL
    has been created that omits the AWS#AWSManagedRulesCommonRuleSet#SizeRestrictions_BODY rule.
    */
    provider.addSpanProcessor(new SimpleSpanProcessor(oltpTraceExporter));
  };
  printTracesToDevConsoleInLocalHost();
}

// /**
//  * Initialize the OpenTelemetry APIs to use the BasicTracerProvider bindings.
//  *
//  * This registers the tracer provider with the OpenTelemetry API as the global
//  * tracer provider. This means when you call API methods like
//  * `opentelemetry.trace.getTracer`, they will use this tracer provider. If you
//  * do not register a global tracer provider, instrumentation which calls these
//  * methods will receive no-op implementations.
//  */
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new W3CTraceContextPropagator(),
});

// Only register web instrumentations on the client
if (typeof window !== "undefined" && process.env.NODE_ENV !== "test") {
  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: /(localhost|accelerate)/g,
      }),
      new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls: /(localhost|accelerate)/g,
      }),
    ],
  });
}

export const tracer = provider.getTracer("accelerate-platform-frontend", packageJson.version);

function enrichSpanWithUid(span: Span) {
  try {
    const account = msalInstance.getAllAccounts()[0];
    const uid = hash(account.username.trim().toLowerCase());
    return span.setAttributes({ uid });
  } catch (error) {
    return span;
  }
}

/**
 * Executes a user defined function within the current active context of a parent span.
 * @param {String} spanName Name of the parent span
 * @param {Attributes} spanAttributes Additional key-value attributes to apply to the parent span
 * @param {args} args arguments passed to the fn function
 */
//TODO: This feels like it should be migrated to some sort of middleware layer
export function wrapRequestWithTrace<T = Record<string, any>>(
  spanName: string,
  spanAttributes: Attributes,
  args: FetchWithAuthArgs,
) {
  return tracer.startActiveSpan(spanName, async (parentSpan) => {
    parentSpan.setAttributes(spanAttributes);
    enrichSpanWithUid(parentSpan);
    try {
      const data = await fetchWithAuth<T>({
        ...args,
        uid: (parentSpan as unknown as { attributes: { uid?: string } }).attributes?.uid,
        requestId: parentSpan?.spanContext().traceId,
        traceId: parentSpan?.spanContext().traceId,
      });
      parentSpan.setStatus({ code: SpanStatusCode.OK }).end();
      return data;
    } catch (error: any) {
      parentSpan
        .setAttributes({ ...error })
        .setStatus({ code: SpanStatusCode.ERROR })
        .end();
      throw new Error(error);
    }
  });
}
