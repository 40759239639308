import { Button } from "../../components/button";
import { TagTypeDetails } from "../../api/metadata/types";
import { Trash24 } from "@bphxd/ds-core-react/lib/icons/index";

export const TESTID_ENUMS = {
  container: "tag-type-preview-card",
  highLighted: "highlighted-tag",
  tagType: "tagType-tag",
  deleteButton: "delete-moderation-tag-type",
};

type TagTypePreviewCardProps = {
  tagTypeDetail: TagTypeDetails;
  onDeleteClick: ({ type, id }: { type: string; id: string }) => void;
};

export default function TagTypePreviewCard({ tagTypeDetail, onDeleteClick }: TagTypePreviewCardProps) {
  const { tagType } = tagTypeDetail;
  return (
    <div
      data-testid={TESTID_ENUMS.container}
      className="w-100 hover-shadow d-flex align-items-center justify-content-between border rounded-4 px-5 py-4"
    >
      <p className="fs-5 mb-0">{tagType}</p>
      <Button
        name="moderator-delete-tag"
        data-testid={`${TESTID_ENUMS.deleteButton}-${tagType}`}
        onClick={() => onDeleteClick({ type: "delete", id: tagType })}
        Icon={Trash24}
        level="tertiary"
        theme="standard"
        rounded="pill"
        size="xs"
      >
        Delete
      </Button>
    </div>
  );
}
