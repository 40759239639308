import { createElement } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yLight } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { useAnchor } from "../hooks/use-anchor";
import { Link } from "./Link";

const sanitizeSchema = {
  ...defaultSchema,
  tagNames: [...defaultSchema.tagNames, "style", "img"],
  attributes: {
    ...defaultSchema.attributes,
    "*": [...defaultSchema.attributes["*"], "className"],
  },
};

export const allyLightModified = a11yLight
  ? {
      ...a11yLight,
      hljs: {
        ...a11yLight.hljs,
        fontWeight: 400,
        color: "black",
      },
    }
  : {};

function Heading({ children, ...props }) {
  const text = children;
  const slug = String(text)?.toLowerCase()?.replace(/\W/g, "-");

  return createElement(props.node.tagName, { ...props, node: null, id: slug }, text);
}

function Anchor({ children, ...props }) {
  return (
    <Link doNotTrack {...props}>
      {children}
    </Link>
  );
}

function HighlightedCode({ inline, className, children, ...props }) {
  const match = /language-(\w+)/.exec(className || "");

  return (
    <SyntaxHighlighter
      style={allyLightModified}
      language={!inline ? (match?.length > 0 ? match[1] : "") : ""}
      PreTag={!inline ? "div" : undefined}
      showLineNumbers={!inline}
      showInlineLineNumbers={!inline}
      customStyle={inline ? { display: "inline" } : undefined}
      {...props}
    >
      {!inline ? String(children).replace(/\n$/, "") : children}
    </SyntaxHighlighter>
  );
}

export default function MarkdownRenderer({ className = "", content = "", components = {}, ...props }) {
  // setup effect to scroll to any anchors in the page URL
  useAnchor();
  return (
    <ReactMarkdown
      className={`md-content ${className ? className : ""}`.trim()}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeSanitize.bind(this, sanitizeSchema)]}
      components={{
        a: Anchor,
        h1: Heading,
        h2: Heading,
        h3: Heading,
        h4: Heading,
        h5: Heading,
        h6: Heading,
        code: HighlightedCode,
        ...components,
      }}
      {...props}
    >
      {content}
    </ReactMarkdown>
  );
}
