import urljoin from "url-join";
import { getKHubAPI } from "../../rest-api";
import { DndTreeData } from "../../../../views/khub/dnd-tree-types";
import { Dispatch, SetStateAction } from "react";
import { useQuery } from "react-query";
import { navTreeQueryKey } from "../query-key";
import { getParents } from "@minoru/react-dnd-treeview";
import { DocumentStatus } from "../../../../views/khub/enums";

export const endpoint = (uuid: string) => `v2/documents/tree/${uuid}`;
export const flatEndpoint = (uuid: string) => urljoin(endpoint(uuid), "flat");

type NodeBase = {
  uuid: string;
  page: string;
  title: string;
  status?: string;
  owner?: string;
};

type FlatNavTreeNode = NodeBase & {
  parent: string | null;
};

async function getFlattenedDocTree(uuid: string) {
  if (typeof uuid === "undefined") {
    throw Error("uuid cannot be undefined");
  }

  try {
    const response = await getKHubAPI<{
      pages: FlatNavTreeNode[];
      content_owners?: string[];
      detail?: string;
    }>({
      spanName: "Get khub document tree",
      query: flatEndpoint(uuid),
    });

    if (response.body.detail) {
      throw new Error(response.body.detail);
    }

    return response.body;
  } catch (error: any) {
    throw new Error(error);
  }
}

export function isNodeVisible(email: string, status?: string, contentOwners?: string[], pageOwner?: string) {
  if (status !== DocumentStatus.DRAFT && status !== DocumentStatus.RETIRED) {
    return true;
  } else if (email.toLowerCase() == pageOwner?.toLowerCase()) {
    return true;
  } else {
    return !!contentOwners?.map((i) => i.toLowerCase()).includes(email.toLowerCase());
  }
}

export const isNodeOrParentInvisible = (node: DndTreeData, treeData: DndTreeData[]) => {
  if (node.data && !node.data.visible) {
    return true;
  }

  return getParents(treeData, node.id).some((parent) => parent.data && !parent.data.visible);
};

function transformResponse(pages: FlatNavTreeNode[], email: string, contentOwners?: string[]): DndTreeData[] {
  return pages.map(({ page, uuid, title, parent, status, owner }) => ({
    id: uuid,
    text: title,
    parent: parent ?? "0",
    droppable: true,
    data: {
      path: page
        .split(/\/|(\.md$)/)
        .filter(Boolean)
        .slice(1, -1)
        .join("/"),
      visible: isNodeVisible(email, status, contentOwners, owner),
    },
  }));
}

type NavTreeQueryParams = {
  uuid: string;
  email?: string;
  setTreeData?: Dispatch<SetStateAction<DndTreeData[] | undefined>>;
  postTransform?: (treeData: DndTreeData[]) => void;
};
export function useNavTreeQuery({ uuid, email, setTreeData, postTransform }: NavTreeQueryParams) {
  return useQuery({
    queryKey: navTreeQueryKey(uuid),
    queryFn: async () => {
      const tree = await getFlattenedDocTree(uuid);
      const newTreeData = transformResponse(tree.pages, email || "", tree?.content_owners);
      if (setTreeData) {
        setTreeData(newTreeData);
      }
      if (postTransform) {
        postTransform(newTreeData);
      }
      return newTreeData;
    },
  });
}
