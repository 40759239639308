import { env } from "next-runtime-env";
import { useLocation } from "../../hooks/use-location";
import { useUuid } from "../../hooks/khub/use-page-store";

// default the group id to the "Accelerate Content Administrators (PROD)" group in AAD
// this is the ID used by any application deployed in an AWS-environment
export const KH_CONTENT_ADMIN_GROUP_ID =
  env("NEXT_PUBLIC_KH_CONTENT_ADMIN_GROUP_ID") ?? "d2513588-ace2-499b-b495-18000ae9bb5d";

/**
 * Created an edit page href from a given pathname. removes any anchors from the path name if any exists
 * @param pathname
 * @returns editPageHref
 */
export function getEditPageHref(pathname: string) {
  return pathname.split("#")[0].split("/edit")[0] + "/edit";
}
export function ExtractDocInfoV2() {
  const { search } = useLocation();
  // this should be set at src/pages/knowledgehub/[uuid]/*.page.tsx
  const uuid = useUuid();
  const editPageHref = uuid ? `/knowledgehub/${uuid}/edit` : "/knowledgehub/invalid_uuid/edit";

  return { uuid, search, editPageHref };
}
