import {
  CleanCity,
  ComputerCloud,
  FileTileCode,
  RoadTankerMed,
  ArrowTransform,
  Checklist,
  AdminOperatingModel,
  AdminTransform,
  ArrowOverlapTwist,
  ArrowSign,
  Book,
  Brain,
  ChatLines,
  ChecklistInspection,
  Database,
  Design,
  DiagramBarUpwards,
  DiagramFlow,
  DocumentIndex,
  EyeSimple,
  HotDrink,
  PeopleIdea,
  PeopleThree,
  PeopleThreeEqual,
  Plant,
  Shield,
  TickBox,
  Warning,
} from "../icons/brand/";

export function DevOpsCapabilityIcon(props: { [x: string]: unknown; capabilityEnum: string }) {
  const { capabilityEnum, ...attributes } = props;
  switch (capabilityEnum) {
    case "ARCHITECTURE":
      return <CleanCity {...attributes} />;
    case "CLOUD_INFRASTRUCTURE":
    case "infrastructure-v1":
      return <ComputerCloud {...attributes} />;
    case "CODE_MAINTAINABILITY":
    case "code-platform-reuse-v1":
      return <FileTileCode {...attributes} />;
    case "CONTINUOUS_DELIVERY":
      return <RoadTankerMed {...attributes} />;
    case "CONTINUOUS_INTEGRATION":
    case "continuous-integration-v1":
      return <ArrowTransform {...attributes} />;
    case "CONTINUOUS_TESTING":
    case "quality-v1":
      return <Checklist {...attributes} />;
    case "CUSTOMER_FEEDBACK":
      return <ChatLines {...attributes} />;
    case "design-engineering-v1":
      return <Design {...attributes} />;
    case "DATABASE_CHANGE_MANAGEMENT":
    case "data-risks-controls-v1":
      return <Database {...attributes} />;
    case "DEPLOYMENT_AUTOMATION":
      return <AdminTransform {...attributes} />;
    case "EMPOWERING_TEAMS_TO_CHOOSE_TOOLS":
    case "EMPOWERING_TEAMS":
      return <AdminOperatingModel {...attributes} />;
    case "JOB_SATISFACTION":
      return <HotDrink {...attributes} />;
    case "LEARNING_CULTURE":
      return <Brain {...attributes} />;
    case "MONITORING_AND_OBSERVABILITY":
    case "MONITORING_OBSERVABILITY":
    case "monitoring-observability-v1":
      return <Book {...attributes} />;
    case "MONITORING_SYSTEMS_TO_INFORM_BUSINESS_DECISIONS":
    case "MONITORING_SYSTEMS":
    case "data-decision-making-v1":
      return <DiagramBarUpwards {...attributes} />;
    case "PROACTIVE_FAILURE_NOTIFICATION":
    case "PROACTIVE_FAILURE":
      return <Warning {...attributes} />;
    case "SHIFT_LEFT_ON_SECURITY":
    case "SHIFTING_LEFT":
    case "security-v1":
      return <Shield {...attributes} />;
    case "STREAMLINING_CHANGE_APPROVAL":
    case "STREAMLINING_APPROVAL":
      return <TickBox {...attributes} />;
    case "TEAM_EXPERIMENTATION":
      return <PeopleThree {...attributes} />;
    case "TEST_DATA_MANAGEMENT":
      return <ChecklistInspection {...attributes} />;
    case "TRANSFORMATIONAL_LEADERSHIP":
      return <DiagramFlow {...attributes} />;
    case "TRUNK_BASED_DEVELOPMENT":
      return <ArrowOverlapTwist {...attributes} />;
    case "VERSION_CONTROL":
    case "version-control-v1":
      return <ArrowSign {...attributes} />;
    case "VISIBILITY_OF_WORK_IN_VALUE_STREAM":
    case "VALUE_VISIBLITY":
      return <PeopleIdea {...attributes} />;
    case "VISUAL_MANAGEMENT_CAPABILITIES":
    case "VISUAL_MANAGEMENT":
      return <EyeSimple {...attributes} />;
    case "WESTRUM_ORGANISATIONAL_CULTURE":
    case "WESTRUM":
    case "team-v1":
      return <Plant {...attributes} />;

    case "WORK_IN_PROCESS_LIMITS":
    case "WORK_PROCESS_LIMITS":
      return <DocumentIndex {...attributes} />;
    case "WORKING_IN_SMALL_BATCHES":
    case "WORK_SMALL_BATCHES":
      return <PeopleThreeEqual {...attributes} />;
    default:
      return <PeopleThreeEqual {...attributes} />;
  }
}
