import { gql } from "@apollo/client";

export const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket(
    $description: String!
    $supportType: SupportType = null
    $title: String = null
    $urgency: Urgency = null
    $serviceName: ServiceName = null
    $incidentUrl: String = null
    $incidentId: String = null
  ) {
    createSupportTicket(
      description: $description
      supportType: $supportType
      title: $title
      urgency: $urgency
      serviceName: $serviceName
      incidentUrl: $incidentUrl
      incidentId: $incidentId
    ) {
      description
      supportType
      serviceName
      incidentUrl
    }
  }
`;
