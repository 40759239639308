import { Dispatch, SetStateAction } from "react";
import { ACCELERATE_APIS } from "../api/config";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { NotificationInputs } from "../forms/moderation/send-notification-modal";

export type SendAdminNotification = {
  user_id?: string;
  timestamp: string;
  data: {
    text: string;
    url?: string;
  };
};

async function createGenericNotification(setLoading: Dispatch<SetStateAction<boolean>>, input: NotificationInputs) {
  try {
    const token = await requestAccessToken(
      ACCELERATE_APIS.notifications.scopes, // We are standardizing on this app reg
    );
    const data: SendAdminNotification = {
      timestamp: new Date().toISOString(),
      data: {
        text: input.notificationText,
        url: input.url,
      },
    };
    if (input.userEmail) {
      data.user_id = input.userEmail;
    }
    const res = await fetch("/api/notifications", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const result = await res.json();
    setLoading(false);
    return result;
  } catch (error) {
    setLoading(false);
    console.error("Error creating global notification:", error);
    throw error;
  }
}

export async function createNotification(setLoading: Dispatch<SetStateAction<boolean>>, input: NotificationInputs) {
  return await createGenericNotification(setLoading, input);
}
