const defaultProps = {
  padding: [0],
  children: null,
  gap: 0,
  vertical: false,
  oneLiner: false,
  width: 0,
  fullWidth: false,
  display: "flex",
};

export default defaultProps;
