import { Link, isUrlInternal } from "./Link";
import { ExternalLink24 } from "@bphxd/ds-core-react/lib/icons";
import { Badge } from "./badges/badge";
import { PropsWithChildren } from "react";

type LinkBadgeProps = PropsWithChildren<{
  href: string;
  name: string;
  hideExternalLinkIcon?: boolean;
  tabIndex?: number;
}>;

export function LinkBadge({
  href = "",
  name = "",
  hideExternalLinkIcon = false,
  children,
  tabIndex = 0,
  ...badgeProps
}: LinkBadgeProps) {
  return (
    <Link tabIndex={-1} href={href} name={name} hideExternalLinkIcon>
      <Badge
        {...badgeProps}
        tabIndex={tabIndex}
        label={children}
        icon={!hideExternalLinkIcon && !isUrlInternal(href) ? <ExternalLink24 /> : undefined}
      />
    </Link>
  );
}
