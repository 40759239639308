import { SVGProps } from "react";

export function PeopleThree(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>people-three</title>
      <g id="Meeting">
        <path d="M499.99963,643.3033c-78.40637,0-142.19524-63.78984-142.19524-142.19717s63.78887-142.19524,142.19524-142.19524c78.40733,0,142.1962,63.7879,142.1962,142.19524S578.407,643.3033,499.99963,643.3033Zm0-239.838a97.64175,97.64175,0,1,0,97.64174,97.64078A97.75169,97.75169,0,0,0,499.99963,403.46535Z" />
        <path d="M653.59454,997.52475H346.40568c-47.69879,0-86.5041-36.89085-86.5041-82.23623l-.001-107.92659c0-78.40734,67.61776-142.19717,150.73194-142.19717H589.36767c83.1132,0,150.73,63.78983,150.73,142.19717V915.28852C740.09767,960.6339,701.29333,997.52475,653.59454,997.52475Zm-242.962-287.80554c-58.54638,0-106.17749,43.80222-106.17749,97.64272l.001,107.92659c0,20.77854,18.81865,37.68178,41.94964,37.68178H653.59454c23.131,0,41.94964-16.90324,41.94964-37.68178V807.36193c0-53.8405-47.63014-97.64272-106.17651-97.64272Z" />
        <path d="M242.57388,286.86765c-78.40636,0-142.19523-63.78983-142.19523-142.19717S164.16752,2.47525,242.57388,2.47525c78.40734,0,142.19621,63.7879,142.19621,142.19523S320.98122,286.86765,242.57388,286.86765Zm0-239.838a97.64175,97.64175,0,1,0,97.64175,97.64078A97.7517,97.7517,0,0,0,242.57388,47.0297Z" />
        <path d="M301.97982,641.08911H88.97994c-47.69879,0-86.5041-36.89086-86.5041-82.23623l-.001-107.9266c0-78.40733,67.61777-142.19716,150.73194-142.19716H331.94192a156.935,156.935,0,0,1,57.35323,10.74218,22.27719,22.27719,0,1,1-16.21481,41.49907,112.54452,112.54452,0,0,0-41.13842-7.6868H153.20681c-58.54637,0-106.17748,43.80221-106.17748,97.64271l.001,107.9266c0,20.77854,18.81865,37.68177,41.94964,37.68177H301.97982a22.27723,22.27723,0,0,1,0,44.55446Z" />
        <path d="M757.42634,286.86765c-78.40734,0-142.19621-63.78983-142.19621-142.19717S679.019,2.47525,757.42634,2.47525s142.19523,63.7879,142.19523,142.19523S835.83367,286.86765,757.42634,286.86765Zm0-239.838a97.64175,97.64175,0,1,0,97.64078,97.64078A97.75253,97.75253,0,0,0,757.42634,47.0297Z" />
        <path d="M911.02125,641.08911H698.01943a22.27723,22.27723,0,0,1,0-44.55446H911.02125c23.132,0,41.94964-16.90323,41.94964-37.68177V450.92628c0-53.8405-47.63111-97.64271-106.17652-97.64271H668.0583a112.55341,112.55341,0,0,0-41.13939,7.6868,22.27719,22.27719,0,0,1-16.2148-41.49907,156.94762,156.94762,0,0,1,57.35419-10.74218H846.79437c83.11418,0,150.731,63.78983,150.731,142.19716v107.9266C997.52535,604.19825,958.72,641.08911,911.02125,641.08911Z" />
      </g>
    </svg>
  );
}
