"use client";
import { useEffect, useState } from "react";

export const useTimeoutToggle = (value: boolean) => {
  const [show, toggle] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      toggle(false);
    }, 8000);
    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return { show, toggle };
};
