import styled from "@emotion/styled";
import { Badge } from "@bphxd/ds-core-react";
import { createElement } from "react";

import { Box } from "../box";
import { Divider } from "../divider";
import { Link } from "../Link";
import MarkdownRenderer from "../MarkdownRenderer";
import { Timestamp } from "./timestamp";
import { Info16, Check16, Avatar32 } from "@bphxd/ds-core-react/lib/icons";

const QUESTION_STATUS = {
  rejected: 2,
  approved: 1,
  inReview: 0,
};

const TruncatedTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: var(--word-break, "none");
  overflow: hidden;
  hyphens: auto;
  fontweight: 400;
  fontsize: 18px;
`;

const TruncatedBody = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: var(--word-break, "none");
  overflow: hidden;
  hyphens: auto;
`;

function ParagraphAsHeader({ children, ...props }) {
  return createElement("p", { ...props, node: null }, children);
}

export function FAQUserInfo({ author, createdAt, updatedAt }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Avatar32 />
        <p className="small mb-0">{author}</p>
      </div>
      <Timestamp createdAt={createdAt} updatedAt={updatedAt} />
    </div>
  );
}

export default function FAQCard({
  id,
  title,
  body,
  tags,
  author,
  createdAt,
  updatedAt,
  upvotes,
  comments,
  answer,
  status,
}) {
  return (
    <Link name="faq-card" href={`/best-practices/faq/question/${id}`} className="no-hover-color">
      <Box padding={[16]} flex gap="10" border="1px solid #d8d8d8" borderRadius="12" className="hover-shadow">
        <Box flex flexDirection="column" width={120} justify="center">
          {!!answer && status !== QUESTION_STATUS.inReview && (
            <Badge color="success" className="text-uppercase">
              <Check16 />
              answered
            </Badge>
          )}
          {status === QUESTION_STATUS.inReview && (
            <Badge color="info" className="text-uppercase">
              <Info16 />
              Pending
            </Badge>
          )}
          <div style={{ color: "rgba(17, 17, 17, 0.64)", marginTop: 16 }}>
            <Box flex flexDirection="column" align="center" padding={[0, 0, 8, 0]}>
              <div style={{ fontSize: 16 }} data-testid="faq-question-upvote-count">
                {upvotes}
              </div>
              <div style={{ fontSize: 13 }}>upvotes</div>
            </Box>
            <Divider />
            <Box flex flexDirection="column" align="center" padding={[8, 0, 0, 0]}>
              <div style={{ fontSize: 16 }}>{comments?.length || 0}</div>
              <div style={{ fontSize: 13 }}>comments</div>
            </Box>
          </div>
        </Box>
        <Box flex flexDirection="column" fullWidth>
          <Box flex flexDirection="row" padding={[0]}>
            <Box>
              <TruncatedTitle>{title}</TruncatedTitle>
            </Box>
            <Box flex flexDirection="row"></Box>
          </Box>
          <div className="" style={{ fontSize: 13 }}>
            <TruncatedBody>
              <MarkdownRenderer
                content={body}
                className=""
                components={{
                  h1: ParagraphAsHeader,
                  h2: ParagraphAsHeader,
                  h3: ParagraphAsHeader,
                  h4: ParagraphAsHeader,
                  h5: ParagraphAsHeader,
                  h6: ParagraphAsHeader,
                }}
              />
            </TruncatedBody>
          </div>
          {tags?.length > 0 && (
            <div className="d-flex gap-2 flex-wrap">
              {tags.map((tag) => (
                <Badge key={tag.id}>{tag}</Badge>
              ))}
            </div>
          )}
          <FAQUserInfo author={author} createdAt={createdAt} updatedAt={updatedAt} />
        </Box>
      </Box>
    </Link>
  );
}
