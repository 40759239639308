import { Buffer } from "buffer";

function buildIcon(iconB64) {
  if (!iconB64) {
    return null;
  }
  const svgStr = Buffer.from(iconB64, "base64");
  const svg = new Blob([svgStr], { type: "image/svg+xml" });
  try {
    return URL.createObjectURL(svg);
  } catch {
    return svg;
  }
}

export default buildIcon;
