import { SVGProps } from "react";

export function ArrowOverlapTwist(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>arrow-overlap-twist</title>
      <g id="Interface">
        <path d="M543.47194,379.5032a22.2717,22.2717,0,0,0,31.07982-5.15548c37.25635-52.07109,127.68023-140.64047,314.9037-149.10079h37.84131L837.96451,329.69271a22.278,22.278,0,0,0,33.86061,28.96039L992.17823,217.93722a22.2791,22.2791,0,0,0,0-28.9604L871.82512,48.26093a22.278,22.278,0,1,0-33.86061,28.9604l88.49868,103.47115H888.95654l-.98816.02127c-42.89333,1.90671-107.944,9.578-174.25936,35.31288-74.09886,28.75155-133.10837,73.29827-175.39256,132.39674A22.276,22.276,0,0,0,543.47194,379.5032Z" />
        <path d="M457.9618,604.801a22.27968,22.27968,0,0,0-30.75881,6.81467c-36.39968,57.11827-126.91445,154.21759-324.386,163.13621H24.75288a22.27723,22.27723,0,0,0,0,44.55446h79.05805l.49408-.02127c44.99923-1.99954,113.0434-10.25875,181.6619-38.25805,77.21419-31.50526,137.3743-80.44748,178.80956-145.4672A22.27665,22.27665,0,0,0,457.9618,604.801Z" />
        <path d="M871.82512,641.833a22.278,22.278,0,0,0-33.86061,28.9604l88.91541,103.95846H889.45546c-54.993-2.49072-135.9201-14.26168-210.10017-56.07209-84.48523-47.61757-138.81691-122.838-161.48476-223.57093C457.13994,225.23146,208.42108,185.33937,104.305,180.71375c-.32874-.01354-.65845-.02127-.98816-.02127h-78.564a22.27723,22.27723,0,1,0,0,44.55445h78.06505c54.994,2.49072,135.91913,14.26168,210.10114,56.07209C397.40333,328.9366,451.735,404.15705,474.40286,504.89c60.73059,269.8774,309.44849,309.7695,413.56552,314.39512.32874.01353.65942.02127.98816.02127h37.92338L837.96451,923.2648a22.278,22.278,0,0,0,33.86061,28.9604L992.17823,811.50931a22.2791,22.2791,0,0,0,0-28.9604Z" />
      </g>
    </svg>
  );
}
