import { SVGProps } from "react";

export function ArrowTransform(props: SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M180.9,38.7c-4.4-4.4-11.5-4.4-15.9,0s-4.4,11.5,0,15.9l38.8,38.8h-44.6c-41.9,0-81.3,16.4-111.1,46.2S1.9,208.9,1.9,250.8&#10;&#9;s16.4,81.3,46.2,111.1c29.8,29.8,69.2,46.2,111.1,46.2h47.5c6.2,0,11.2-5,11.2-11.2s-5-11.2-11.2-11.2h-47.5&#10;&#9;c-35.8,0-69.7-14.1-95.2-39.6c-25.5-25.5-39.6-59.4-39.6-95.2s14.1-69.7,39.6-95.2s59.3-39.6,95.2-39.6h42.8L165,153&#10;&#9;c-4.4,4.4-4.4,11.5,0,15.9c2.2,2.2,5.1,3.3,8,3.3s5.8-1.1,8-3.3l57.1-57.1c2.2-2.2,3.3-5.1,3.3-8c0-2.9-1.1-5.8-3.3-8L180.9,38.7z&#10;&#9; M452.4,361.9c29.8-29.8,46.2-69.2,46.2-111.1s-16.4-81.3-46.2-111.1s-69.2-46.2-111.1-46.2h-43.1c-6.2,0-11.2,5-11.2,11.2&#10;&#9;s5,11.2,11.2,11.2h43.1c35.9,0,69.7,14.1,95.2,39.6s39.6,59.4,39.6,95.2s-14.1,69.7-39.6,95.2c-25.5,25.5-59.4,39.6-95.2,39.6h-36.4&#10;&#9;l38-38c4.4-4.4,4.4-11.5,0-15.9s-11.5-4.4-15.9,0l-57.1,57.1c-2.2,2.2-3.3,5.1-3.3,8c0,2.9,1.1,5.8,3.3,8l57.1,57.1&#10;&#9;c2.2,2.2,5.1,3.3,8,3.3s5.8-1.1,8-3.3c4.4-4.4,4.4-11.5,0-15.9L305.1,408h36.2C383.2,408,422.6,391.6,452.4,361.9z"
      />
    </svg>
  );
}
