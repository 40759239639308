export enum KHubComponent {
  NavTree = "NavTree",
  Breadcrumbs = "Breadcrumbs",
  Votes = "Votes",
  AdjacentDocs = "AdjacentDocs",
  WatchDoc = "WatchDoc",
  Owner = "Owner",
}
export type QueryKeyParams = {
  component?: KHubComponent;
  uuid: string;
};
function khubQueryKey(args: QueryKeyParams): ["khub", QueryKeyParams] {
  return ["khub", args];
}

export function breadcrumbsQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.Breadcrumbs,
  });
}

export function navTreeQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.NavTree,
  });
}

export function votesQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.Votes,
  });
}

export function adjacentDocsQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.AdjacentDocs,
  });
}

export function watchDocQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.WatchDoc,
  });
}

export function checkIfDocumentOwnerQueryKey(uuid: string) {
  return khubQueryKey({
    uuid,
    component: KHubComponent.Owner,
  });
}
