import { SVGProps } from "react";

export function ChatLines(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>chat-lines</title>
      <g id="Communication">
        <path d="M994.95477,628.22814c-.67489-1.286-67.23971-129.906-35.91623-243.87763,42.12369-153.25844-6.46078-248.1339-39.17273-291.0485C875.87022,35.58039,809.33248,2.47589,737.31438,2.47589H457.52825c-154.90892,0-280.93672,114.69872-280.93672,255.6834v82.05446c0,5.1423.16872,10.31929.4751,15.47138a229.88854,229.88854,0,0,0-96.80091,74.25054c-32.711,42.9146-81.29544,137.79007-39.17176,291.0485C72.41745,834.95578,5.85359,963.57583,5.17677,964.8618h.001a22.27422,22.27422,0,0,0,26.59828,31.571c100.94562-32.83764,214.84762-67.41569,231.29448-68.62624H542.60328C697.51317,927.80657,823.54,813.104,823.54,672.11543V614.3792c66.19837,19.86785,143.94386,45.13568,144.81552,45.41994a22.28282,22.28282,0,0,0,26.59924-31.571ZM778.98555,672.11543c0,55.70274-24.37345,108.3733-68.6301,148.30987-44.89674,40.51478-104.47092,62.82681-167.75217,62.82681H262.81813c-19.7285,0-123.99927,32.11247-198.03914,55.73562,19.5999-52.9819,43.08477-143.18147,19.276-229.81127C56.60584,609.306,67.844,519.72914,115.70044,456.94487,151.198,410.37348,204.82,383.664,262.81813,383.664H542.60328c59.50408,0,115.7287,19.73067,159.55666,55.76825.02453.0203.04846.04121.07312.06139q4.13492,3.40287,8.12432,6.9933c44.25472,39.93464,68.62817,92.60327,68.62817,148.306ZM821.926,567.4115c-5.35659-45.579-23.92916-87.60721-52.14843-122.85447h11.41055a22.27722,22.27722,0,1,0,0-44.55445H724.365c-49.0302-37.95287-112.503-60.893-181.76173-60.893H262.81813a231.12984,231.12984,0,0,0-41.61244,3.77535c-.01112-.8899-.05971-1.78331-.05971-2.67116V258.15929c0-55.70274,24.37345-108.37136,68.62914-148.306C334.67089,69.34045,394.247,47.03035,457.52825,47.03035H737.31438c57.99814,0,111.62012,26.70947,147.11865,73.28086,47.85543,62.78427,59.0946,152.36115,31.6445,252.23159-23.82039,86.66847-.30168,176.91445,19.30306,229.88668C903.1417,592.18536,859.513,578.51627,821.926,567.4115Z" />
        <path d="M781.18814,160.39668H364.8515a22.27723,22.27723,0,0,0,0,44.55446H781.18814a22.27723,22.27723,0,1,0,0-44.55446Z" />
        <path d="M781.18814,277.22837H364.8515a22.27723,22.27723,0,0,0,0,44.55445H781.18814a22.27722,22.27722,0,1,0,0-44.55445Z" />
        <path d="M634.65348,495.05015H218.31685a22.27722,22.27722,0,1,0,0,44.55445H634.65348a22.27723,22.27723,0,1,0,0-44.55445Z" />
        <path d="M634.65348,611.88183H218.31685a22.27723,22.27723,0,0,0,0,44.55446H634.65348a22.27723,22.27723,0,1,0,0-44.55446Z" />
        <path d="M634.65348,734.656H218.31685a22.27723,22.27723,0,1,0,0,44.55446H634.65348a22.27723,22.27723,0,0,0,0-44.55446Z" />
      </g>
    </svg>
  );
}
