import { useQuery } from "@apollo/client";
import { GET_USER_ROLES } from "../api/profiles/gql";

export function useGetUserRoles() {
  const { data, loading } = useQuery(GET_USER_ROLES);
  return {
    loading,
    ...(data?.currentUserProfile ?? {
      loading,
      isOwner: false,
      isDelegate: false,
      isTechLead: false,
      isDesignLead: false,
    }),
  };
}
