import { Fragment } from "react";
import { Box } from "../box";
import { Badge } from "@bphxd/ds-core-react";
import { Edit24, Alert24, Alert16, Check24, Search16 } from "@bphxd/ds-core-react/lib/icons";
import MarkdownRenderer from "../MarkdownRenderer";

import { QUESTION_STATUS } from "../../views/moderation/moderation-page";
import { Button } from "../button";
import { FAQUserInfo } from "./FAQCard";

export const TESTID_ENUMS = {
  container: "faq-preview-card",
  editButton: "edit-faq-answer",
  approveButton: "approve-faq-answer",
  rejectButton: "reject-faq-answer",
  unrejectButton: "unreject-faq-answer",
};

type FAQPreviewCardProps = {
  id: string;
  title: string;
  body: string;
  tags: Array<string>;
  author: string;
  createdAt: Date;
  updatedAt: Date;
  status: number;
  onEditClick: (id: string) => void;
  onConfirmClick: ({ type, id }: { type: string; id: string }) => void;
};

export function FAQPreviewCard({
  id,
  title,
  body,
  tags,
  author,
  createdAt,
  updatedAt,
  status,
  onEditClick,
  onConfirmClick,
}: FAQPreviewCardProps) {
  return (
    <div data-testid={TESTID_ENUMS.container}>
      <Box padding={[16]} flex gap="10" border="1px solid #d8d8d8" borderRadius="12">
        <Box flex flexDirection="column" width={120} gap={16}>
          {status === QUESTION_STATUS["in-review"] && (
            <Badge color="info">
              <Search16 />
              Needs review
            </Badge>
          )}
          {status === QUESTION_STATUS.rejected && (
            <Badge color="danger">
              <Alert16 />
              Rejected
            </Badge>
          )}
          <Button
            name="moderator-edit-faq-answer"
            data-testid={TESTID_ENUMS.editButton}
            onClick={() => onEditClick(id)}
            Icon={Edit24}
            color="light"
            rounded="pill"
            size="xs"
          >
            Edit
          </Button>
          {status === QUESTION_STATUS.rejected ? (
            <Button
              name="moderator-unreject-faq-answer"
              data-testid={TESTID_ENUMS.unrejectButton}
              onClick={() => onConfirmClick({ type: "unreject", id })}
              Icon={Alert24}
              color="warning"
              rounded="pill"
              size="xs"
            >
              Unreject
            </Button>
          ) : (
            <Fragment>
              <Button
                name="moderator-approve-faq-answer"
                data-testid={TESTID_ENUMS.approveButton}
                onClick={() => onConfirmClick({ type: "approve", id })}
                Icon={Check24}
                color="success"
                rounded="pill"
                size="xs"
              >
                Approve
              </Button>
              <Button
                name="moderator-reject-faq-answer"
                data-testid={TESTID_ENUMS.rejectButton}
                onClick={() => onConfirmClick({ type: "reject", id })}
                Icon={Alert24}
                color="danger"
                rounded="pill"
                size="xs"
              >
                Reject
              </Button>
            </Fragment>
          )}
        </Box>
        <Box flex flexDirection="column" fullWidth>
          <div>
            <FAQUserInfo author={author} createdAt={createdAt} updatedAt={updatedAt} />
            <h3>{title}</h3>
            <MarkdownRenderer content={body} />
          </div>
          <div className="d-flex gap-2">{tags?.length > 0 && tags.map((tag) => <Badge key={tag}>{tag}</Badge>)}</div>
        </Box>
      </Box>
    </div>
  );
}
