import { SVGProps } from "react";

export function DocumentIndex(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>document-index</title>
      <g id="Index">
        <path d="M762.89379,997.52475H284.13644c-74.78148,0-135.62229-60.83887-135.62229-135.62036V658.41584a22.27723,22.27723,0,1,1,44.55445,0V861.90439a91.16975,91.16975,0,0,0,91.06784,91.06591H762.89379a91.16974,91.16974,0,0,0,91.0659-91.06784V197.50155a91.16809,91.16809,0,0,0-91.0659-91.06591H329.15694a22.27724,22.27724,0,0,1,0-44.55445H762.89379c74.78148,0,135.62036,60.83887,135.62036,135.62036V861.90246C898.51415,936.68394,837.67527,997.52475,762.89379,997.52475Z" />
        <path d="M170.79138,581.68317A81.68317,81.68317,0,1,1,252.47455,500,81.77621,81.77621,0,0,1,170.79138,581.68317Zm0-118.81188A37.12871,37.12871,0,1,0,207.92009,500,37.17056,37.17056,0,0,0,170.79138,462.87129Z" />
        <path d="M170.79138,373.76238a81.68317,81.68317,0,1,1,81.68317-81.68317A81.77622,81.77622,0,0,1,170.79138,373.76238Zm0-118.81188a37.12871,37.12871,0,1,0,37.12871,37.12871A37.17055,37.17055,0,0,0,170.79138,254.9505Z" />
        <path d="M170.79138,165.84158a81.68317,81.68317,0,1,1,81.68317-81.68316A81.77621,81.77621,0,0,1,170.79138,165.84158Zm0-118.81188a37.12872,37.12872,0,1,0,37.12871,37.12872A37.17056,37.17056,0,0,0,170.79138,47.0297Z" />
        <path d="M764.85078,314.35644H329.20722a22.27723,22.27723,0,0,1,0-44.55446H764.85078a22.27723,22.27723,0,1,1,0,44.55446Z" />
        <path d="M764.85078,522.27723H329.20722a22.27723,22.27723,0,0,1,0-44.55446H764.85078a22.27723,22.27723,0,1,1,0,44.55446Z" />
      </g>
    </svg>
  );
}
