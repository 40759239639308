"use client";
import { useEffect, useState } from "react";
import { Hints, Step, Steps } from "intro.js-react";
import { Webpage24 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "./button";
import { Event, TourAction, TourName } from "../telemetry/enums";
import { trackEvent } from "../utils/event-tracker";
import { Options } from "intro.js/src/option";

export enum Feature {
  ProductTourButton = "product_tour_button",
  ProductTourHint = "product_tour_hint",
}

export enum Component {
  Pulse = "pulse",
  DismissButton = "dismiss_button",
}

type TourOptions = Partial<Omit<Options, "overlayOpacity">>;
export const DEFAULT_TOUR_OPTIONS: TourOptions = {
  hidePrev: false,
  helperElementPadding: 10,
  showProgress: true,
  showBullets: false,
  exitOnOverlayClick: false,
  exitOnEsc: true,
  showStepNumbers: false,
};

type Props = {
  dataTestId?: string;
  steps?: Step[];
  showButton?: boolean;
  buttonText?: string;
  tourName: TourName;
};

export function LocalProductTour({
  dataTestId = "local-product-tour",
  steps = [],
  showButton = true,
  buttonText = "What can I do on this page?",
  tourName,
}: Props) {
  const HINT_FLAG = "disable_hint_" + dataTestId;
  const [showSteps, setShowSteps] = useState(false);
  const [showHint, setShowHint] = useState(localStorage.getItem(HINT_FLAG) === null);

  // this is a bit hacky: we need to listen to click events on the 'Dismiss' button in the hint tooltip...
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          const a = document.querySelector(
            "div.introjs-tooltipReferenceLayer.introjs-hintReference > div > div.introjs-tooltiptext > a",
          );
          if (a) {
            a.addEventListener("click", () => {
              // this is where we register that the user is dismissing the hint
              localStorage.setItem(HINT_FLAG, "1");
              setShowHint(false);
              trackEvent({
                name: Event.ButtonClick,
                feature: Feature.ProductTourHint,
                component: Component.DismissButton,
                tour_name: tourName,
              });
            });
          }
        }
      });
    });

    // register the observer
    observer.observe(document.body, { childList: true, subtree: true });

    // this is necessary to avoid memory leaks
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Hints
        options={{
          tooltipClass: "customHintClass",
          hintButtonLabel: "Dismiss",
        }}
        onClick={() => {
          trackEvent({
            name: Event.ButtonClick,
            feature: Feature.ProductTourHint,
            tour_name: tourName,
            component: Component.Pulse,
          });
        }}
        enabled={showHint}
        hints={[
          {
            element: `[data-testid="${dataTestId}"]`,
            hintPosition: "middle-right",
            hint: "This button will take you on a product tour.",
          },
        ]}
      />
      {showButton && (
        <Button
          trackingEventProps={{
            name: Event.ButtonClick,
            feature: Feature.ProductTourButton,
            tour_name: tourName,
          }}
          data-testid={dataTestId}
          onClick={(e) => {
            e.preventDefault();
            setShowSteps(true);
            setShowHint(false);
          }}
          Icon={Webpage24}
          theme="standard"
          level="quartenary"
          size="sm"
          className="w-max-content"
        >
          {buttonText}
        </Button>
      )}

      <Steps
        enabled={showSteps}
        steps={steps}
        initialStep={0}
        onBeforeExit={(stepIndex) => {
          if (stepIndex === -1) {
            // this will fire when the component is rendered and then unmounted which shouldn't trigger tracking.
            // Also, -1 is a non-sense index.
            return;
          }
          let actionName = TourAction.EarlyExit;
          if (stepIndex === steps.length - 1) {
            actionName = TourAction.Finish;
          }
          trackEvent({
            name: Event.ProductTour,
            tour_name: tourName,
            action: actionName,
            step_index: stepIndex,
          });
        }}
        onExit={() => {
          setShowSteps(false);
        }}
        onStart={() => {
          trackEvent({
            name: Event.ProductTour,
            tour_name: tourName,
            action: TourAction.Start,
          });
        }}
        options={DEFAULT_TOUR_OPTIONS}
      />
    </>
  );
}
