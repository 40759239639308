import { forwardRef } from "react";
import ReactSelect, { createFilter } from "react-select";
import { trackEvent } from "../utils/event-tracker";
import ReactSelectOverrides, { NoIndicatorSeparator } from "../utils/ReactSelectOverrides";

/**
 * @type React.ForwardRefRenderFunction<ReactSelect>
 */
const Select = forwardRef(({ trackingName, onChange, ...props }, ref) => {
  const style = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotateX(180deg)" : "rotateX(0deg)",
    }),
    control: (provided, { selectProps: { invalid } }) => ({
      ...provided,
      borderColor: invalid ? "#a80000" : provided.borderColor,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      // position: 'unset', // if we leave this uncommented dropdowns will push content down
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Arial",
      fontSize: "15px !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.53",
      letterSpacing: "0.38px",
      color: "#111",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Arial",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.53",
      letterSpacing: "1px",
      color: "#111",
    }),
  };

  function handleChange(e) {
    if (trackingName) {
      trackEvent({ name: `input_${trackingName}_selected`, value: e.value });
    }
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <ReactSelect
      components={{
        ...ReactSelectOverrides,
        IndicatorSeparator: NoIndicatorSeparator,
      }}
      classNamePrefix="react-select"
      isSearchable
      filterOption={createFilter({ ignoreAccents: false })}
      {...props}
      onChange={handleChange}
      ref={ref}
      styles={style}
    />
  );
});

Select.displayName = "Select";

export default Select;
