"use client";
import { PropsWithChildren, useContext, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AuthContext } from "../pages/_app.page";
import mixpanel from "mixpanel-browser";
import { hash } from "../utils/sha256";

const CANARY_USER_EMAIL = "accelerate-canary001@bp.com"; // hashes to 20506865-b01d-5c2e-b1ba-77d14ef88923

export function AuthenticationWrapper({ children }: PropsWithChildren) {
  const { email, isBPEmployee, isAdmin, isModerator, isContentAdmin, isOwner, isDelegate, isTechLead, isDesignLead } =
    useContext(AuthContext);

  useEffect(() => {
    if (typeof isBPEmployee === "boolean" && email) {
      const canonicalEmail = email.trim().toLowerCase();
      // bypass mixpanel for our canary user
      if (canonicalEmail === CANARY_USER_EMAIL) {
        mixpanel.opt_out_tracking();
        return;
      }
      const distinctId = hash(canonicalEmail);
      console.debug("Mixpanel distinctId:", distinctId);
      try {
        mixpanel.identify(distinctId);
        mixpanel.people.set({
          bpEmployee: isBPEmployee,
          isAdmin,
          isModerator,
          isContentAdmin,
          isOwner,
          isDelegate,
          isTechLead,
          isDesignLead,
        });
      } catch (error) {
        // console.error("Mixpanel error -- have you forgot to set NEXT_PUBLIC_MIXPANEL_ID?", error);
      }
    }
  }, [email, isBPEmployee]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate />
    </>
  );
}
