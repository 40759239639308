"use client";
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from "react";
import { DateTime } from "luxon";
import { Badge } from "@bphxd/ds-core-react";

import { useIntersectionObserver, useLocalStorage } from "usehooks-ts";
import { hideNewFlagAfterDelay } from "../../pages/_app.page";

type NewFlagProps = {
  id: string;
  startDate: string;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * Flag will disappear after 60 seconds have passed from `props.startDate`.
 */
export function NewFlag({ id, startDate, className, style }: NewFlagProps) {
  const now = DateTime.now();
  const start = DateTime.fromISO(startDate);
  const localStorageKey = `new-flag-${id}`;

  if (now.diff(start, "weeks").weeks > 1) {
    // process.env.NODE_ENV !== 'test' &&
    //   console.warn(
    //     `NewFlag ${id} is older than 1 week. Clearing it from local storage.`
    //   );
    try {
      window.localStorage.removeItem(localStorageKey);
    } catch {
      console.error(`Local storage does not exist in this environment`);
    }
    return null;
  }

  const { isIntersecting: isVisible, ref } = useIntersectionObserver();
  const [isShowing, setIsShowing] = useLocalStorage(localStorageKey, true);

  useEffect(() => {
    if (isVisible) {
      hideNewFlagAfterDelay(setIsShowing);
    }
  }, [isVisible]);

  return isShowing ? (
    <div className="position-relative" ref={ref}>
      <Badge
        color="success"
        className={`d-flex bounce z-3 position-absolute px-2 py-0 fw-medium ${className ?? ""}`}
        style={style}
      >
        New!
      </Badge>
    </div>
  ) : null;
}
