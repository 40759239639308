"use client";
import { useEffect, useState } from "react";

/**
 * Simple hook for debouncing a query/function.
 * Source: https://github.com/uidotdev/usehooks
 * @param value The value that you want to debounce.
 * @param delay Debounce delay in milliseconds.
 */
export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
