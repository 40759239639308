import { Fragment, PropsWithChildren } from "react";

import { MapEventToNotification } from "./notification-mapper";

import { DropdownItem } from "reactstrap";
import { Link } from "../Link";

function Notification({
  title,
  relativeDate,
  isRead,
  index,
}: {
  title: string;
  relativeDate: string;
  isRead: boolean;
  index: number;
}) {
  return (
    <Fragment key={index}>
      <div className="notification-message__text">
        <div className="notification-message__text__title">{title}</div>
        {relativeDate && <div className="notification-message__text__date">{relativeDate}</div>}
      </div>
      <div className="notification-message__indicator">
        {!isRead && <div className="notification-message__indicator__dot" />}
      </div>
    </Fragment>
  );
}

function LinkIf({ href, children, ...rest }: PropsWithChildren<{ href?: string; [key: string]: any }>) {
  return href ? (
    <Link href={href} {...rest}>
      {children}
    </Link>
  ) : (
    <div {...rest}>{children}</div>
  );
}

export function NotificationList({
  notifications = [],
  handleNotificationClick,
}: {
  notifications: any[];
  handleNotificationClick: (id: string) => void;
}) {
  function handleClick(id: string) {
    return () => handleNotificationClick(id);
  }

  return notifications?.length ? (
    <>
      {notifications.map(MapEventToNotification).map((notification, index) => {
        const { id, url }: { id: string; url?: string } = notification;
        return (
          <DropdownItem key={id || index} style={{ borderRadius: "5px" }}>
            <LinkIf href={url} className="notification-message" onClick={handleClick(id)}>
              <Notification {...notification} index={index} />
            </LinkIf>
          </DropdownItem>
        );
      })}
    </>
  ) : (
    <div data-testid="no-notification-message" className="no-messages">
      No notifications yet
    </div>
  );
}
