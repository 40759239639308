import { create } from "zustand";

export enum KHubModalAction {
  Create = "Create",
  Delete = "Delete",
  Move = "Move",
  Unsaved = "Unsaved",
}

type ModalStore = {
  isKHubPageModalOpen: boolean;
  kHubModalAction: KHubModalAction;
  kHubModalPath: string;
  kHubModalUuid: string;
  actions: {
    setIsKHubPageModalOpen: (val: boolean) => void;
    setKHubModalAction: (val: KHubModalAction) => void;
    setKHubModalPath: (val: string) => void;
    setKHubModalUuid: (val: string) => void;
  };
};

const useModalStore = create<ModalStore>()((set) => ({
  isKHubPageModalOpen: false,
  kHubModalAction: KHubModalAction.Create,
  kHubModalPath: "",
  kHubModalUuid: "",
  actions: {
    setIsKHubPageModalOpen: (isKHubPageModalOpen) => set(() => ({ isKHubPageModalOpen })),
    setKHubModalAction: (kHubModalAction) => set(() => ({ kHubModalAction })),
    setKHubModalPath: (kHubModalPath) => set(() => ({ kHubModalPath })),
    setKHubModalUuid: (kHubModalUuid) => set(() => ({ kHubModalUuid })),
  },
}));

export const useKHubPageModalOpen = () => useModalStore((state) => state.isKHubPageModalOpen);
export const useKHubModalAction = () => useModalStore((state) => state.kHubModalAction);
export const useKHubModalPath = () => useModalStore((state) => state.kHubModalPath);
export const useKHubModalUuid = () => useModalStore((state) => state.kHubModalUuid);
export const useModalActions = () => useModalStore((state) => state.actions);
