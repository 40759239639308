"use client";
import { useState, Fragment } from "react";
import { CREATE_SUPPORT_TICKET } from "../../api/support/gql";

import { Row, Col } from "reactstrap";
import { FormHeading } from "../../components/form-heading";

import { Step1Form } from "./step1";
import SubmissionResults from "./submission-results";
import { Step2Form } from "./step2";
import { Step3Form } from "./step3";
import { useMutation } from "@apollo/client";

export type AccelerateTeam = {
  value: string;
  label: string;
  description: string;
};

export type Step1Data = {
  team: string;
};

type Step2Data = {
  tooling_team: string;
};

export type Step3Data = {
  incident_url: string;
  description: string;
};
export const TEAMS: AccelerateTeam[] = [
  {
    value: "TOOLING",
    label: "Tooling",
    description:
      "Azure DevOps (ADO), Jenkins, Splunk, SonarQube, Artifactory, Ansible Tower, App Center and Visual Studio with GitHub subscriptions.",
  },
  {
    value: "KNOWLEDGE_HUB",
    label: "Knowledge Hub",
    description:
      "Any issue that relates to the creation, moderation, and visibility of documents in the Knowledge Hub area of Accelerate (except for Golden Paths).",
  },
  {
    value: "GOLDEN_PATHS",
    label: "Golden Paths",
    description: "Any issue relating to material in the Golden Paths section of Accelerate.",
  },
  {
    value: "CORE",
    label: "Accelerate Site & Navigation",
    description:
      "Any issue that relates to the Overview, Best Practices and What's Next areas of Accelerate, as well as general issues with the functionality or availability of Accelerate itself.  ",
  },
  {
    value: "SEARCH",
    label: "Accelerate Search",
    description:
      "Any issue that relates to Accelerate's search functionality (accessed through the search bar at the top of each page).",
  },
  {
    value: "COMMUNITY",
    label: "Accelerate Community & Code Hub",
    description:
      "Any issue that relates to the Code Hub (Code Library and My Code Contributions), Q and A, and Gamification functionality in Accelerate.",
  },
  {
    value: "DEVOPS_IDENTITY",
    label: "Accelerate DevOps Identity",
    description: "Any issue relating to DevOps Quizzes, and the Accelerate Profile page",
  },
];

export const TOOLING_TEAMS: AccelerateTeam[] = [
  {
    value: "TOOLING_AZURE_DEVOPS",
    label: "Azure DevOps (ADO)",
    description: "projects, boards, repos, CI/CD pipelines",
  },
  {
    value: "TOOLING_JENKINS",
    label: "Jenkins",
    description: "CI/CD pipelines, infrastructure automations",
  },
  {
    value: "TOOLING_SPLUNK",
    label: "Splunk",
    description: "observability, logs, dashboards, alerts",
  },
  {
    value: "TOOLING_SONARQUBE",
    label: "SonarQube",
    description: "code quality scanning",
  },
  {
    value: "TOOLING_ARTIFACTORY",
    label: "Artifactory",
    description: "artifacts, binaries, packages, files, versioning",
  },
  {
    value: "TOOLING_ANSIBLE",
    label: "Ansible Tower",
    description: "configuration deployment",
  },
  {
    value: "TOOLING_APPCENTER",
    label: "App Centre",
    description: "mobile app development",
  },
  {
    value: "TOOLING",
    label: "Other",
    description: "DevOps Toolchain tools not listed",
  },
];

type HelpAndSupportFormWizardProps = {
  step?: number;
  onStepChange: (step: number) => void;
  onDirty: () => void;
};

export function HelpAndSupportFormWizard({ step = 1, onStepChange, onDirty }: HelpAndSupportFormWizardProps) {
  const [currentStep, setCurrentStep] = useState<number>(step);
  const [step1Data, setStep1Data] = useState<Step1Data>({
    team: "",
  });
  const [step2Data, setStep2Data] = useState<Step2Data>({
    tooling_team: "",
  });
  const [step3Data, setStep3Data] = useState<Step3Data>({
    incident_url: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState<string | undefined>();

  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET, {
    onCompleted() {
      goToNextStep();
    },
    onError: handleError,
  });

  function isToolingSelected(team: string) {
    const found = TEAMS.find(({ label }) => label === "Tooling");
    return found ? team === found.value : false;
  }

  function goToNextStep() {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    onStepChange(nextStep);
  }

  function goToPreviousStep() {
    const previousStep = currentStep - 1;
    setCurrentStep(previousStep);
    onStepChange(previousStep);
  }

  async function handleStep1Submit(data: Step1Data) {
    setStep1Data(data);
    if (isToolingSelected(data.team)) {
      setCurrentStep((current) => {
        const nextStep = current + 1;
        onStepChange(nextStep);
        return nextStep;
      });
    } else {
      // Correctly handling the skip to a future step
      setCurrentStep((current) => {
        const skippedStep = current + 2;
        onStepChange(skippedStep);
        return skippedStep;
      });
    }
  }

  async function handleStep2Back(data: Step2Data) {
    setStep2Data(data);
    goToPreviousStep();
  }

  async function handleStep2Submit(data: Step2Data) {
    setStep2Data(data);
    goToNextStep();
  }

  async function handleStep3Back(data: Step3Data) {
    setStep3Data(data);

    if (isToolingSelected(step1Data?.team)) {
      setCurrentStep((current) => {
        const previousStep = current - 1;
        onStepChange(previousStep);
        return previousStep;
      });
    } else {
      setCurrentStep((current) => {
        const skippedBackStep = current - 2;
        onStepChange(skippedBackStep);
        return skippedBackStep;
      });
    }
  }

  async function handleStep3Submit(data: Step3Data) {
    setStep3Data(data);

    const { description, incident_url } = data;
    let service_name = step1Data?.team;
    if (isToolingSelected(step1Data?.team)) {
      service_name = step2Data.tooling_team;
    }
    setIsSubmitting(true);

    const step3DataVariables = {
      description,
      incidentUrl: incident_url,
      serviceName: service_name,
      supportType: "INCIDENT",
    };

    await createSupportTicket({ variables: step3DataVariables });

    setIsSubmitting(false);
  }

  function handleError(error: { message: string }) {
    setIsSubmitting(false);
    setRequestError(`Error: ${error.message}`);
    goToNextStep();
  }

  let formTitle = "Specify product area";
  if (currentStep === 2) {
    formTitle = "Specify tooling area";
  } else if (currentStep === 3) {
    formTitle = "Specify incident";
  }

  return (
    <Fragment>
      {currentStep < 4 && (
        <Fragment>
          <Row>
            <Col>
              <FormHeading currentStep={currentStep} totalSteps={3} title={formTitle} />
            </Col>
          </Row>
        </Fragment>
      )}
      <div style={{ display: currentStep === 1 ? "block" : "none" }}>
        <Step1Form
          {...step1Data}
          teamOptions={TEAMS}
          onDirty={onDirty}
          onSubmit={handleStep1Submit}
          isSubmitting={isSubmitting}
        />
      </div>
      <div style={{ display: currentStep === 2 ? "block" : "none" }}>
        <Step2Form
          {...step2Data}
          options={TOOLING_TEAMS}
          onSubmit={handleStep2Submit}
          onBackClick={handleStep2Back}
          isSubmitting={isSubmitting}
        />
      </div>
      <div style={{ display: currentStep === 3 ? "block" : "none" }}>
        <Step3Form
          {...step3Data}
          onSubmit={handleStep3Submit}
          onBackClick={handleStep3Back}
          isSubmitting={isSubmitting}
        />
      </div>

      <div style={{ display: currentStep === 4 ? "block" : "none" }}>
        <SubmissionResults failure={!!requestError} />
      </div>
    </Fragment>
  );
}
