import { Spinner, SpinnerProps } from "@bphxd/ds-core-react";

export function Loader(props: SpinnerProps) {
  return (
    <div className="mx-auto">
      <LoadingSpinner {...props} />
    </div>
  );
}

export function LoadingSpinner(props: SpinnerProps) {
  const color = "brand";
  return (
    <div data-testid="loading-spinner" className="d-flex align-items-center">
      <Spinner color={color} {...props} />
    </div>
  );
}
