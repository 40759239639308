import { env } from "next-runtime-env";

import { Logo } from "@bphxd/ds-core-react";
import { Col, Row } from "reactstrap";
import { PageSection } from "./page-template";
import { Link } from "./Link";
import { FooterContent } from "./footer.data";
import { Newsletter, Slack } from "../icons/media";

function FooterItems({ title, links }: { title: string; links: { name: string; url: string }[] }) {
  return (
    <Col xs="2">
      <p>{title}</p>
      {links?.map((link) => (
        <Link key={link.url} href={link.url} className="d-block mb-3">
          {link.name}
        </Link>
      ))}
    </Col>
  );
}

export function Footer() {
  let buildString = "";
  if (env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT") && env("NEXT_PUBLIC_DEPLOY_VERSION")) {
    const environment = env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT")?.replace(/platform-frontend?./, "");

    buildString = `v${env("NEXT_PUBLIC_DEPLOY_VERSION")}-Next.js ${environment}`;
  }

  return (
    <div className="border-primary border-top">
      <PageSection>
        <Row className="py-10">
          <Col xs="5">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center ms-n3">
                <Logo height={80} />
                <span className="ps-2 text-brand-primary fs-6">Accelerate</span>
              </div>

              <p className="fw-light">{FooterContent.TEXT}</p>
              <div className="d-flex gap-5">
                <Link hideExternalLinkIcon={true} href={FooterContent.SLACK_ICON_URL}>
                  <Slack />
                </Link>
                <Link hideExternalLinkIcon={true} href={FooterContent.NEWSLETTER_ICON_URL}>
                  <Newsletter />
                </Link>
              </div>
            </div>
          </Col>
          <Col xs="1" />
          {FooterContent.columns.map(({ title, links }, index) => {
            return <FooterItems key={index} title={title} links={links} />;
          })}
        </Row>
      </PageSection>
      <div className="bg-tertiary">
        <PageSection>
          <div className="py-9 d-flex gap-4">
            <div>Accelerate</div>
            <div className="text-secondary">{buildString ? buildString : "Next.js local build"}</div>
          </div>
        </PageSection>
      </div>
    </div>
  );
}
