import { getKHubAPI } from "../rest-api";

export const endpoint = (uuid: string) => `v1/documents/${uuid}/adjacent`;

type AdjacentDoc = {
  path: string;
  title: string;
  uuid: string;
};

export async function getAdjacentDocuments(uuid: string) {
  try {
    const response = await getKHubAPI<{
      next?: AdjacentDoc;
      previous?: AdjacentDoc;
    }>({
      spanName: "Get adjacent documents for khub page",
      query: endpoint(uuid),
    });

    return response.body;
  } catch (error: any) {
    throw new Error(error);
  }
}
