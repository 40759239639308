/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponseData } from "../types/api-types";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { getApiScopes } from "./config";

export type FetchWithAuthArgs = {
  method?: string;
  additionalHeaders?: {
    [headerKey: string]: string;
  };
  payload?: any;
  uri?: string;
  uid?: string;
  requestId?: string;
  traceId?: string;
  tokenRequester?: typeof requestAccessToken;
};
export async function fetchWithAuth<T = Record<string, any>>({
  method = "GET",
  additionalHeaders = {},
  uri = "",
  payload = undefined,
  uid = "",
  requestId = "",
  traceId = "",
  tokenRequester = requestAccessToken,
}: FetchWithAuthArgs): Promise<ResponseData<T>> {
  let r = 0;
  const maxRetries = 1;
  let forceRefreshToken = false;

  // Allows us to define per-API scopes (even if there are multiple scopes per API)
  const scopes = getApiScopes(uri);
  let response;
  while (response === undefined && r <= maxRetries) {
    try {
      const accessToken = await tokenRequester(scopes, forceRefreshToken);
      if (!accessToken) {
        throw new Error("Failed to retrieve access token. Try again.");
      }

      const res = await fetch(uri, {
        method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-application-tag": "accelerate-frontend",
          "x-user-id": uid,
          "x-request-id": requestId,
          "x-trace-id": traceId,
          ...additionalHeaders,
        },
        body: payload ? JSON.stringify(payload) : null,
      });

      if (res.status === 403 && r < maxRetries) {
        throw new Error("Fetch returned a 403. Try again.");
      }

      let body;
      const response_type = res.headers.get("Content-Type");
      if (response_type && response_type.startsWith("image/")) {
        body = await res.blob();
      } else {
        body = await res.json();
      }

      const { status, statusText, ok } = res;
      response = {
        ok,
        status,
        statusText,
        body,
      };
    } catch {
      forceRefreshToken = true;
    } finally {
      r += 1;
    }
  }

  if (!response) {
    throw new Error("Fetch failed to return a response");
  }
  return response;
}
