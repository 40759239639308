"use client";
import { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Socket, io } from "socket.io-client";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { ACCELERATE_APIS } from "../api/config";

const sockets: { [namespace: string]: Socket | undefined } = {};

export function useWebsocket(namespace: `/${string}` = "/"): Socket | undefined {
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState<string>();

  const { url, scopes } = ACCELERATE_APIS.notifications;
  const namespacedUrl = new URL(namespace, url!).toString();

  useEffect(() => {
    if (!namespacedUrl || !token || sockets[namespace]) {
      return;
    }
    const newSocket = io(namespacedUrl, {
      transports: ["websocket"],
      autoConnect: true,
      query: {
        token,
      },
      auth: {
        token,
      },
    });
    newSocket.on("connect", () => console.log(`${namespace} websocket connection established`));
    newSocket.on("connect_error", (e) => console.log(`${namespace} websocket connection error occurred`, e));
    newSocket.on("disconnect", () => console.log(`${namespace} websocket connection closed`));
    newSocket.onAny((event, ...args) => console.log(`${namespace} websocket received event:`, event, args));
    sockets[namespace] = newSocket;
    return () => {
      newSocket.close();
      delete sockets[namespace];
    };
  }, [namespace, token, namespacedUrl]);

  async function getToken() {
    const bearerToken = await requestAccessToken(scopes);
    if (bearerToken) {
      setToken(bearerToken);
    }
  }

  useEffect(() => {
    if (!namespacedUrl) {
      return;
    }

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, namespacedUrl]);
  return sockets[namespace];
}
