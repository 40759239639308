import { Check24, Check16, Alert24, Avatar32, Search16, Alert16 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "../../components/button";
import { Badge } from "@bphxd/ds-core-react";
import { Component, ComponentConfirmationReply } from "./models";

export const TESTID_ENUMS = {
  container: "component-preview-card",
  componentStatus: "status-component",
  componentApproveButton: "component-approve-button",
  componentRejectButton: "component-reject-button",
};

const COMPONENT_STATUS = {
  inReview: "In Review",
  approved: "Published",
  rejected: "Rejected",
};

export function ComponentPreviewCard({
  component,
  onApproveClick,
  onRejectClick,
}: {
  component: Component;
  onApproveClick: (message: ComponentConfirmationReply) => void;
  onRejectClick: (message: ComponentConfirmationReply) => void;
}) {
  let Icon = Check16;
  switch (component?.status) {
    case COMPONENT_STATUS.approved:
      Icon = Check16;
      break;
    case COMPONENT_STATUS.inReview:
      Icon = Search16;
      break;
    case COMPONENT_STATUS.rejected:
      Icon = Alert16;
      break;
  }

  return (
    <div data-testid={TESTID_ENUMS.container}>
      <div className="d-flex p-4 gap-4 rounded-5 border border-primary">
        <div
          className="d-flex flex-column gap-3"
          style={{
            width: "120px",
            minHeight: "100px",
          }}
        >
          <Badge color={component.status ? "info" : "danger"} data-testid={TESTID_ENUMS.componentStatus}>
            <Icon />
            {component?.status ? component.status : "Undefined"}
          </Badge>
          {component?.status !== COMPONENT_STATUS.approved && (
            <Button
              name="moderator-approve-component"
              data-testid={`${TESTID_ENUMS.componentApproveButton}-${component.name}`}
              onClick={() => onApproveClick({ type: "approve", id: component?.id })}
              color="success"
              Icon={Check24}
              level="tertiary"
              theme="standard"
              rounded="pill"
              size="xs"
            >
              Approve
            </Button>
          )}
          {component?.status !== COMPONENT_STATUS.rejected && (
            <Button
              name="moderator-reject-component"
              data-testid={`${TESTID_ENUMS.componentRejectButton}-${component.name}`}
              onClick={() => onRejectClick({ type: "reject", id: component?.id })}
              color="danger"
              Icon={Alert24}
              level="tertiary"
              theme="standard"
              rounded="pill"
              size="xs"
            >
              Discard
            </Button>
          )}
        </div>
        <div className="d-flex w-100">
          <div className="w-50">
            <div className="d-flex align-items-center">
              <Avatar32 />
              <p className="small mb-0">{component.authorEmail}</p>
            </div>
            <h4>{component?.name}</h4>

            <div>{component?.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
