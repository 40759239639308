"use client";
import { useEffect } from "react";

import { trackEvent } from "../../utils/event-tracker";

import { Modal } from "../../components/modal";
import { Button } from "../../components/button";
import { InputErrorMessage } from "../../components/Input";

export const TESTID_ENUMS = {
  prompt: "survey-confirmation-modal-prompt",
  confirmButton: "survey-confirmation-modal-submit",
};

type SurveyConfirmationModalProps = {
  show: boolean;
  onConfirm: ({ id }: { id: string }) => void;
  onHide: () => void;
  type: string;
  target: string;
  loading: boolean;
  error: string | null;
  surveyName: string;
};

export default function SurveyConfirmationModal({
  show,
  onConfirm,
  onHide,
  type,
  target,
  loading,
  error,
  surveyName,
}: SurveyConfirmationModalProps) {
  useEffect(() => {
    if (show) {
      trackEvent({
        name: `confirm-${type}-modal-opened`,
      });
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={false}
      onClose={() => {
        onHide();
      }}
      header="Confirmation"
    >
      <h4 className="text-center" data-testid={TESTID_ENUMS.prompt}>
        Are you sure that you want to {type} the <span className="fst-italic fw-normal">"{surveyName}"</span> survey?
      </h4>
      <div className="text-center mb-8">
        <Button
          name={`moderator-confirm-${type}`}
          data-testid={TESTID_ENUMS.confirmButton}
          loading={loading}
          className="text-capitalize"
          onClick={() => onConfirm({ id: target })}
          level="primary"
          theme="darker"
          rounded="pill"
          size="lg"
        >
          {type}
        </Button>
      </div>
      {error && (
        <div className="text-center mb-8">
          <InputErrorMessage>Operation failed: {error}</InputErrorMessage>
        </div>
      )}
    </Modal>
  );
}

SurveyConfirmationModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  onHide: () => {},
  loading: false,
};
