import { Step } from "intro.js-react";
import { IntroJsStep } from "../../views/search/enums";
import { TourName } from "../../telemetry/enums";

export const globalTour: Step[] = [
  {
    tooltipClass: "global-product-tour-intro",
    intro: `<span class="header">Your first time here?</span><p class="body mt-5">We'd love to introduce you to Accelerate.<br/><br/>Accelerate is bp's platform for enabling better product outcomes by helping teams deliver software and boost performance.<br/><br/>Take the tour now by hitting 'Next'. This message won't pop up again, but you can still take the tour any time using the button on the Accelerate home page.</p>`,
  },
  {
    element: "div.bp-search-bar",
    title: "Ask Accelerate",
    intro: `Ask about documents, code, and tools across Accelerate. Your AI assistant, Axel, will automatically answer you. <a href="https://accelerate.bpglobal.com/knowledgehub/hHJLmA?ref=${TourName.Global}&step=search_accelerate" target="_blank">Learn more.</a>`,
  },
  {
    element: `[introjs-step="${IntroJsStep.FilterIcon}"]`,
    title: "Advanced search filters",
    intro: "User filters to refine your search.",
  },
  {
    element: `[introjs-step="${IntroJsStep.AxelHistory}"]`,
    title: "Chat history",
    intro: "Review past interactions and conversations with Axel for reference and insights.",
  },
  {
    element: 'button[data-testid="navbar-support-button"]',
    title: "Need more help?",
    intro: "Can't find what you're looking for? Site issues? Technical Queries?",
  },
  {
    element: '[data-testid="navItem-Products"]',
    title: "Products 1/5",
    intro: `<img src="/images/setup-product.png" style="max-width: 550px;"/><br/><br/>Set up your product in Accelerate to describe the great things that it does, and access perception and empirical product metrics.`,
  },
  {
    element: '[data-testid="navItem-Products"]',
    title: "Products 2/5",
    intro: `Manage your products and teams. <a href="https://accelerate.bpglobal.com/knowledgehub/H9CR3E?ref=${TourName.Global}&step=products" target="_blank"/>Learn more.</a>`,
  },
  {
    element: '[data-testid="navItem-Products"]',
    title: "Products 3/5",
    intro: `<img src="/images/product-metrics.png" style="max-width: 550px;"/><br/><br/>Explore your product metrics, benchmarking yourself against Google DORA's indicators of high-performing technical product teams.`,
  },
  {
    element: '[data-testid="navItem-Products"]',
    title: "Products 4/5",
    intro: `<img src="/images/metric-insights.png" style="max-width: 550px;"/><br/><br/>Gain actionable insight on suggested improvements from Accelerate's AI-powered AxelGPT.`,
  },
  {
    element: '[data-testid="navItem-Products"]',
    title: "Products 5/5",
    intro: `<img src="/images/areas-for-improvement-example.png" style="max-width: 550px;"/><br/><br/>Choose areas to focus on to improve your product's digital maturity, and get guidance on how to achieve the improvements.`,
  },
  {
    element: '[data-testid="navItem-Maturity"]',
    title: "Maturity",
    intro: "Take your team maturity assessments.",
  },
  {
    element: '[data-testid="navItem-Capabilities"]',
    title: "Capabilities",
    intro: "Browse the DORA capabilities and the content that can help your team boost its maturity for each one.",
  },
  {
    element: '[data-testid="navItem-Tools"]',
    title: "Tools",
    intro: "Find the software delivery tools that are endorsed by bp.",
  },
  {
    element: '[data-testid="navItem-Documentation"]',
    title: "Documentation",
    intro: "Discover documentation created by your colleagues, and create your own to share with them.",
  },
  {
    tooltipClass: "global-product-tour-outro",
    intro: `<div class="container">
      <div class="row">
        <div class="col header mb-6">Congratulations - enjoy using Accelerate</div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <img src="/images/global_outro_modal_img 1.svg" alt="something" />
        </div>
        <div class="col body">
          Retake this tour by clicking the page tour link
        </div>
      </div>
      <div class="row mt-5 align-items-center">
        <div class="col">
          <img src="/images/global_outro_modal_img 2.svg" alt="something" />
        </div>
        <div class="col body">
          Pages with new features will have a blue pulse next to the link
        </div>
      </div>
    </div>`,
  },
];
