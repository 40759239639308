import { useLayoutEffect } from "react";

/**
 * See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 * @param behavior -- defaulted to `smooth`
 * @param block -- defaulted to `start`
 */
export function useAnchor(behavior = "smooth", block = "start") {
  useLayoutEffect(() => {
    const onHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView({ behavior, block } as ScrollIntoViewOptions);
        }
      }
    };

    window.addEventListener("hashchange", onHashChange, false);
    onHashChange();

    return () => {
      window.removeEventListener("hashchange", onHashChange, false);
    };
  }, []);
}
