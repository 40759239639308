import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button as OldButton } from "../button";
import { Link as OldLink } from "../Link";

const getMargin = ({ size = 0 }) => css(size ? `${size}px` : "0");

const Button = styled(OldButton)`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-family: Arial, Helvetica, sans-serif;
  color: hsla(0, 0%, 7%, 0.8);
`;

const Link = styled(OldLink)`
  color: hsla(227, 99%, 37%, 1);
`;

const HeroPanel = styled.div`
  border: 1px solid red;
`;

const Title = styled.h1`
  border: 1px solid red;
`;

const Paragraph = styled.p`
  padding: 1rem;
  border: 1px solid red;
  margin: ${getMargin};
`;

const style = {
  Button,
  Link,
  HeroPanel,
  Title,
  Paragraph,
  getMargin,
};

export default style;
