import { SVGProps } from "react";

export function PeopleIdea(props: SVGProps<SVGSVGElement>) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>people-idea</title>
      <path d="M292.41,388.28c0-114.46,93.12-207.59,207.59-207.59s207.59,93.13,207.59,207.59c0,80.81-42.59,152.4-108.51,182.4-18.38,8.24-20.42,23-20.64,25.76v22.37a22.28,22.28,0,1,1-44.55,0V595.86c.15-16.7,10.19-49.35,46.85-65.79,50-22.73,82.31-78.4,82.31-141.79,0-89.89-73.14-163-163-163s-163,73.14-163,163c0,63.39,32.35,119.06,82.42,141.84,36.55,16.39,46.59,49,46.74,65.74v22.95a22.28,22.28,0,1,1-44.55,0V596.54c-.18-2.47-2-17.51-20.54-25.82C335,540.68,292.41,469.09,292.41,388.28Zm310.23,288A22.26,22.26,0,0,0,578.49,656L417.6,670.21a22.28,22.28,0,0,0,1.93,44.47c.65,0,1.32,0,2-.09L582.4,700.41A22.28,22.28,0,0,0,602.64,676.26Zm-38.28,50.79H435.64a22.28,22.28,0,0,0,0,44.56H564.36a22.28,22.28,0,0,0,0-44.56ZM500,143.56a22.28,22.28,0,0,0,22.28-22.27V24.75a22.28,22.28,0,0,0-44.56,0v96.54A22.28,22.28,0,0,0,500,143.56ZM264.85,410.89a22.28,22.28,0,0,0-22.28-22.28H146a22.28,22.28,0,1,0,0,44.56h96.53A22.28,22.28,0,0,0,264.85,410.89ZM279,221.39a22.28,22.28,0,0,0,31.51-31.51l-68.26-68.26a22.28,22.28,0,0,0-31.51,31.51ZM854,388.61H757.43a22.28,22.28,0,1,0,0,44.56H854a22.28,22.28,0,0,0,0-44.56ZM705.25,227.91A22.22,22.22,0,0,0,721,221.39l68.26-68.26a22.28,22.28,0,0,0-31.51-31.51L689.5,189.88a22.28,22.28,0,0,0,15.75,38ZM779.6,810.24C740.31,774.58,688.2,755,632.86,755A22.26,22.26,0,0,0,613.07,767c-.35.67-36,67.15-82.41,67.15H469.34c-23.22,0-43.78-17.17-56.94-31.57A190.54,190.54,0,0,1,386.93,767,22.26,22.26,0,0,0,367.14,755c-55.34,0-107.45,19.63-146.74,55.29-37.82,34.32-59.51,80-59.51,125.4v39.61a22.28,22.28,0,0,0,44.56,0V935.64c0-32.83,16.36-66.52,44.89-92.41,28.13-25.52,64.78-40.67,104.29-43.31,15.89,25.07,56.7,78.79,114.71,78.79h61.32c58,0,98.82-53.72,114.71-78.79,39.51,2.64,76.16,17.79,104.29,43.31,28.53,25.89,44.89,59.58,44.89,92.41v39.61a22.28,22.28,0,0,0,44.56,0V935.64C839.11,890.27,817.42,844.56,779.6,810.24ZM522.28,401V282.18a22.28,22.28,0,1,0-44.56,0V401a22.28,22.28,0,0,0,44.56,0ZM500,438.12a32.18,32.18,0,1,1-32.18,32.18A32.22,32.22,0,0,1,500,438.12Z" />
    </svg>
  );
}
