import styled from "@emotion/styled";
import { css } from "@emotion/react";

const getResponsivePadding = ({ width, height }) => css`
  ${(+height / width) * 100}%
`;

/**
 *
 * @param {path} string - file path
 * @returns string - css for responsive image using div and background-image
 */
const getBGI = ({ path }) =>
  css(
    path
      ? `
  background-image: url(${path});
  background-size: cover;
  background-position: center;
`
      : "",
  );

/**
 *
 * @param {borderRadius} number - the number value in pixels of the border radius
 * @returns string - css line with border radius with rem value
 */
const getBorderRadius = ({ borderRadius }) =>
  css(
    borderRadius
      ? `
  border-radius: ${+borderRadius / 16}rem;
  overflow: hidden;
  `
      : "",
  );

/**
 * ResponsiveImage styled component
 */
const ResponsiveImage = styled.div`
  width: 100%;
  padding-top: ${getResponsivePadding};
  align-self: self-start;

  ${getBorderRadius}
  ${getBGI}
`;

const style = {
  ResponsiveImage,
  getBorderRadius,
};

export default style;
