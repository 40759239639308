import { SVGProps } from "react";

export const Newsletter = ({ width = 14, height = 14, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" {...props}>
    <g fillRule="nonzero">
      <path d="M7 14H1.022A1.027 1.027 0 0 1 0 12.973V6.127c0-.368.018-.843.56-1.309l.115-.098a.25.25 0 0 1 .274-.037.251.251 0 0 1 .148.233v.797a.5.5 0 0 0 .21.414l.248.181L6.39 9.921c.368.276.853.276 1.22 0l4.837-3.613.247-.18a.5.5 0 0 0 .211-.415v-.797a.256.256 0 0 1 .422-.196l.115.098c.542.463.557.941.557 1.31v6.845c0 .564-.461 1.027-1.022 1.027H7zM11.484.003V0H2.54a.939.939 0 0 0-.934.938v4.647a.25.25 0 0 0 .106.208l.322.233a.15.15 0 0 0 .16.013.154.154 0 0 0 .084-.138V.969c0-.141.115-.257.256-.257H11.47a.258.258 0 0 1 .253.254v4.935c0 .058.03.11.084.138a.15.15 0 0 0 .16-.013l.322-.233a.247.247 0 0 0 .106-.208V.938a.942.942 0 0 0-.91-.935z" />
      <path d="M8.021 6.137H6.284l-.39 1.122H5.33l1.583-4.3h.479l1.586 4.3h-.56L8.02 6.137zM6.45 5.67h1.41L7.153 3.66 6.449 5.67z" />
    </g>
  </svg>
);
