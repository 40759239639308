import styled from "@emotion/styled";

import { Hero } from "../../components/hero";
import Page from "../../components/page-template";

export default function ExceptionPage({
  setIsHelpAndSupportModalOpen,
  title = "Welcome to bp Accelerate!",
  heading = "We've noticed you have encountered an error...",
  body = "Please contact the bp Accelerate team if this problem persists.",
  ctaText = "Get help",
}: {
  setIsHelpAndSupportModalOpen: (val: boolean) => void;
  title?: string;
  heading?: string;
  body?: string;
  ctaText?: string;
}) {
  const GreetingContainer = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid #f2f2f2;

    h1 {
      height: 39px;
      margin: 0 0 25px;
      font-size: 26px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #111;
    }
  `;

  return (
    <Page name="error" title="Error">
      <GreetingContainer>
        <h1 className="pt-4 mb-4" data-testid="dashboard-greeting">
          {title}
        </h1>
      </GreetingContainer>
      <Hero
        // @ts-ignore: Hero needs typing
        level={1}
        heading={heading}
        body={body}
        imageUrl="/images/welcome_alt.png"
        ctaText={ctaText}
        ctaOnClick={() => setIsHelpAndSupportModalOpen(true)}
      />
    </Page>
  );
}

ExceptionPage.defaultProps = {
  setIsHelpAndSupportModalOpen: () => {},
};
