import { SVGProps } from "react";

export function HotDrink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" fill="#000000" {...props}>
      <title>hot-drink</title>
      <g id="Hot_drinks" data-name="Hot drinks">
        <path d="M384.55524,916.60512a67.80266,67.80266,0,0,0,24.59971,4.61209H586.47936a91.05222,91.05222,0,0,0,34.73469-6.91523A319.74113,319.74113,0,0,0,794.29247,742.8141a22.3812,22.3812,0,0,0,2.73764.18529c56.37569,0,97.20374-15.29433,121.35094-45.45561,17.53945-21.9098,19.50611-44.15222,19.715-49.86656l15.72942-206.29641a72.39808,72.39808,0,0,0-70.7708-77.88715l-63.7473-1.25019v-40.4616a22.278,22.278,0,0,0-22.27722-22.27722H202.9707a22.278,22.278,0,0,0-22.27723,22.27722V618.81158A319.41911,319.41911,0,0,0,384.55524,916.60512ZM882.18249,408.04038a27.83953,27.83953,0,0,1,27.21612,29.95243l-15.77,206.83207q-.06381.847-.06381,1.694c0,.49493-1.01427,47.40135-84.23033,51.61967a320.17456,320.17456,0,0,0,9.9729-79.327V406.80735ZM225.24793,344.0591h549.505V618.81158A273.93193,273.93193,0,0,1,604.28954,873.08525a46.67641,46.67641,0,0,1-17.81018,3.5775H409.155a23.407,23.407,0,0,1-8.48933-1.59731A274.86295,274.86295,0,0,1,225.24793,618.81158Z" />
        <path d="M975.24793,873.76207A22.278,22.278,0,0,0,952.9707,896.0393c0,23.5806-8.56861,39.54981-26.19315,48.82039-14.85922,7.81443-30.71821,8.10837-30.73754,8.1103H103.9608c-23.5806,0-39.54982-8.56861-48.82039-26.19315-7.73515-14.70645-8.10257-30.39139-8.1103-30.86711a22.27742,22.27742,0,0,0-44.55446.12957,120.039,120.039,0,0,0,12.25247,49.56683c16.7408,33.47966,48.42977,51.91832,89.23268,51.91832H896.04A120.039,120.039,0,0,0,945.60684,985.272c33.47965-16.74079,51.91831-48.42976,51.91831-89.23267A22.278,22.278,0,0,0,975.24793,873.76207Z" />
        <path d="M311.81971,262.49969a22.27765,22.27765,0,1,0,19.92574-39.85148c-.23979-.1199-23.99056-12.60829-23.99056-34.5297,0-21.77638,23.90741-34.48523,23.99056-34.52971,18.05771-9.02885,48.61734-34.85651,48.61734-74.38118S349.80316,13.85528,331.74545,4.82643a22.27765,22.27765,0,1,0-19.92574,39.85148c.23979.1199,23.98863,12.60829,23.98863,34.52971,0,21.77637-23.90548,34.48522-23.98863,34.5297-18.05771,9.02885-48.61928,34.85651-48.61928,74.38119S293.762,253.47084,311.81971,262.49969Z" />
        <path d="M490.03753,262.49969a22.27765,22.27765,0,1,0,19.92574-39.85148c-.23979-.1199-23.99056-12.60829-23.99056-34.5297,0-21.77638,23.90741-34.48523,23.99056-34.52971,18.05771-9.02885,48.61735-34.85651,48.61735-74.38118S528.021,13.85528,509.96327,4.82643a22.27765,22.27765,0,1,0-19.92574,39.85148c.23979.1199,23.98863,12.60829,23.98863,34.52971,0,21.77637-23.90548,34.48522-23.98863,34.5297-18.0577,9.02885-48.61928,34.85651-48.61928,74.38119S471.97983,253.47084,490.03753,262.49969Z" />
        <path d="M668.25535,262.49969a22.27766,22.27766,0,1,0,19.92575-39.85148c-.23979-.1199-23.99057-12.60829-23.99057-34.5297,0-21.77638,23.90741-34.48523,23.99057-34.52971,18.0577-9.02885,48.61734-34.85651,48.61734-74.38118S706.2388,13.85528,688.1811,4.82643a22.27766,22.27766,0,1,0-19.92575,39.85148c.23979.1199,23.98863,12.60829,23.98863,34.52971,0,21.77637-23.90547,34.48522-23.98863,34.5297-18.0577,9.02885-48.61927,34.85651-48.61927,74.38119S650.19765,253.47084,668.25535,262.49969Z" />
      </g>
    </svg>
  );
}
