import { SVGProps } from "react";

export function ArrowSign(props: SVGProps<SVGSVGElement>) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>arrow-sign</title>
      <path d="M500,997.52A22.2,22.2,0,0,1,484.25,991L9,515.75a22.27,22.27,0,0,1,0-31.5L484.25,9a22.27,22.27,0,0,1,31.5,0L991,484.25a22.27,22.27,0,0,1,0,31.5L515.75,991A22.2,22.2,0,0,1,500,997.52ZM56.26,500,500,943.74,943.74,500,500,56.26Zm345,202.35H370.12a45.66,45.66,0,0,1-45.6-45.6V530.5c0-78.93,64.22-143.15,143.15-143.15h76.05L537,343.08c-.05-.35-.1-.7-.13-1.05a38.47,38.47,0,0,1,62-34.28c.55.43,1.08.89,1.59,1.37l132.74,126a22.27,22.27,0,0,1,0,32.31l-132.74,126c-.51.48-1,.94-1.59,1.37a38.47,38.47,0,0,1-62-34.28c0-.33.07-.65.12-1l7.44-49.77-67.16-.17a30.46,30.46,0,0,0-30.38,30.43V656.75A45.65,45.65,0,0,1,401.25,702.35Zm66.42-270.44a98.7,98.7,0,0,0-98.59,98.59V656.75a1,1,0,0,0,1,1h31.13a1,1,0,0,0,1-1V540.1a75.07,75.07,0,0,1,75-75l93,.23a22.27,22.27,0,0,1,22,25.57l-8.52,57,101.73-96.59L583.82,354.79l7.87,51.47a22.29,22.29,0,0,1-22,25.65Z" />
    </svg>
  );
}
