import { gql } from "@apollo/client";
import { graphql } from "../../gql/index";

export const GET_USER_ROLES = graphql(/* GraphQL */ `
  query UserRoles {
    currentUserProfile {
      _id
      username
      isOwner
      isDelegate
      isTechLead
      isDesignLead
    }
  }
`);

export const GET_CURRENT_USER = gql`
  query CurrentUserProfile {
    currentUserProfile {
      _id
      username
    }
  }
`;
