export const defaultProps = {
  title: "",
  info: "",
  badge: {
    icon: "",
    label: "",
  },
  description: "",
  user: {
    name: "",
    avatar: "",
  },
};
