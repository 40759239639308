import { MouseEvent } from "react";

import { Code24, FileDefault24, Settings24, Question24, Summary24 } from "@bphxd/ds-core-react/lib/icons";
import { AdvancedSearchFilters } from "./search-filters";
import Input from "../../components/Input";

export const MAX_RESULTS_PER_QUERY = 100;

type FilterIconProps = {
  type: AdvancedSearchFilters;
};

export function FilterIcon({ type }: FilterIconProps) {
  return {
    [AdvancedSearchFilters.Code]: <Code24 />,
    [AdvancedSearchFilters.Automation]: <Settings24 />,
    [AdvancedSearchFilters.Documents]: <FileDefault24 />,
    [AdvancedSearchFilters.FAQ]: <Question24 />,
    [AdvancedSearchFilters.KDD]: <Summary24 />,
  }[type];
}

type FilterItemProps = {
  name: AdvancedSearchFilters;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
  resultsCount: Record<AdvancedSearchFilters, number>;
  isSelected: boolean;
};

export function FilterItem({ name, onClick, resultsCount, isSelected }: FilterItemProps) {
  const count = Math.min(resultsCount[name], MAX_RESULTS_PER_QUERY);
  return (
    <>
      {resultsCount[name] >= 0 && (
        <div className="d-flex align-items-center justify-content-between w-100">
          <label
            htmlFor={name}
            className="d-flex align-items-center justify-content-between me-4 w-100"
            data-testid={`search-filter-label-${name}`}
          >
            <span className="d-flex align-items-center gap-4">
              <FilterIcon type={name} />
              {name}
            </span>
            <span>({count})</span>
          </label>
          <Input
            id={name}
            data-testid={`search-filter-${name}`}
            trackingName={`search-filter-${name}`}
            type="checkbox"
            checked={isSelected}
            onChange={onClick}
            disabled={count === 0}
          />
        </div>
      )}
    </>
  );
}
