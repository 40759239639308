import { DateTime } from "luxon";
import { Box } from "../box";

type TimestampProps = {
  createdAt: string;
  updatedAt: string;
};
export function Timestamp({ createdAt, updatedAt }: TimestampProps) {
  const lastUpdate = updatedAt && updatedAt !== createdAt ? updatedAt : createdAt;

  return (
    <Box flex flexDirection="column" align="end">
      <div style={{ color: "#111111", fontSize: 12 }}>
        Last update:{" "}
        <span data-testid="faq-question-updated-at">
          {DateTime.fromISO(lastUpdate).setLocale("en-GB").toLocaleString(DateTime.DATETIME_MED)}
        </span>
      </div>
    </Box>
  );
}
