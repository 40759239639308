import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "../Link";
import { Box } from "../box";
const getRadius = ({ radius }) => `border-radius: ${isNaN(radius) ? radius : `${radius / 16}rem`};`;

const getWidthAndHeight = ({ width, height }) => `
  width: ${width / 16}rem;
  height: ${height / 16}rem;
`;

const Placeholder = styled.div`
  background-color: #e8e8e8;
  ${getRadius}
  ${getWidthAndHeight}
`;

const CardTitle = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  height: 30px;
  letter-spacing: 2px;
  color: #111;
`;

const CardInfo = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  letter-spacing: 2px;
  color: #111;
`;

const LoadingCard = styled.div`
  width: 23rem;
  background-color: #fff;
  border-radius: 0.75rem;
`;

const getMargin = ({ margin }) => margin && css(`margin: ${margin}`);
const getBorder = ({ border }) => border && css(`border: ${border}`);
const getWidth = ({ width }) => css(`width: ${width ? width : "23rem"}`);

const Card = styled(Link)`
  ${getMargin}
  ${getBorder}
  ${getWidth}
  background-color: #fff;
  border-radius: 0.75rem;
  color: unset;
  text-decoration: unset;

  &:hover {
    color: unset;
    text-decoration: unset;
  }
`;

const CardHoverless = styled(Box)`
  ${getMargin}
  ${getBorder}
  ${getWidth}
  background-color: #fff;
  border-radius: 0.75rem;
  color: unset;
  text-decoration: unset;
`;

const style = {
  LoadingCard,
  Card,
  CardHoverless,
  CardTitle,
  CardInfo,
  Placeholder,
};

export default style;
