import { useQuery } from "@apollo/client";
import { LIST_CHATS } from "../../../api/axel/gql";

export function useAxelChats() {
  const {
    loading: chatsAreLoading,
    refetch: refetchChats,
    data: chatListData,
  } = useQuery(LIST_CHATS, {
    fetchPolicy: "network-only",
  });

  return {
    chats: chatListData?.listChats ?? [],
    chatsAreLoading,
    refetchChats: refetchChats as () => void,
  };
}
