import { SVGProps } from "react";
export function EyeSimple(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>eye-simple</title>
      <g id="Eye_simple" data-name="Eye simple">
        <ellipse cx="496.18631" cy="500" rx="95.77733" ry="95.23551" />
        <path d="M994.10459,498.82812c-.18565-1.89994-4.91376-47.02776-37.48453-89.22783C862.39628,287.52611,699.05508,220.297,496.68426,220.297c-202.37663,0-365.71782,67.22908-459.9358,189.30422C4.17768,451.80036-.55043,496.92721-.7351,498.82812l-.10443,1.07326-.001,1.079c-.00194,4.07449,1.0307,41.22447,37.34433,90.08741,14.42508,19.40942,149.88977,189.62523,460.18042,189.62523,310.29161,0,445.75727-170.21581,460.18139-189.62523,36.31459-48.86294,37.34529-86.01292,37.34336-90.08838v-1.07808ZM496.18631,715.34653c-119.48774,0-216.69825-96.60427-216.69825-215.34653s97.21051-215.34653,216.69825-215.34653S712.88553,381.25774,712.88553,500,615.67405,715.34653,496.18631,715.34653ZM72.26343,564.48987c-24.21875-32.58818-27.92486-57.2343-28.46438-62.70015A148.73126,148.73126,0,0,1,72.0188,436.82317C136.37185,353.443,237.6038,298.23808,363.89146,275.9704,286.78568,321.206,234.9336,404.68569,234.9336,500c0,95.51289,52.06819,179.14362,129.44082,224.31375-66.99352-12.57385-128.12258-35.137-182.68185-67.54936C110.59882,614.52854,75.97727,569.48677,72.26343,564.48987Zm848.84263,0c-3.71288,4.9969-38.3354,50.03867-109.42915,92.27452C756.717,689.41469,695.09372,712.077,627.521,724.59511,705.15823,679.4911,757.44,595.71,757.44,500c0-95.47421-52.02564-179.075-129.34594-224.25791,126.92375,22.10911,228.66549,77.40019,293.25567,161.08108a148.64653,148.64653,0,0,1,28.2391,65.07C949.12856,506.80693,945.62648,531.49752,921.10606,564.48987Z" />
      </g>
    </svg>
  );
}
