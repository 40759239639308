export enum Keys {
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Enter = "Enter",
  Escape = "Escape",
  Tab = "Tab",
}

export enum TestKeys {
  ArrowDown = `{${Keys.ArrowDown}}`,
  Enter = `{${Keys.Enter}}`,
  Tab = `{${Keys.Tab}}`,
}
