/* eslint-disable @typescript-eslint/no-explicit-any */
import { Attributes } from "@opentelemetry/api";
import { wrapRequestWithTrace } from "../telemetry/tracer";
import { ResponseData } from "../types/api-types";

type InstrumentedFetchArgs = {
  method: string;
  headers?: {
    [headerKey: string]: string;
  };
  apiUrl: string;
  apiResource?: string;
  payload?: any;
  spanName: string;
  spanAttributes: Attributes;
};
/**
 * Core function for platform - backend API requests. Its using get as a default function
 * passed to be executed within the current context started by the parent span.
 *
 * @param method {String} HTTP method
 * @param headers { [headerKey: String]: String } key-value
 * @param apiUrl {String} API URL
 * @param spanName {String} parent span name
 * @param spanAttributes {Object} additional attributes for telemetry
 * @return {Object}
 */
export async function instrumentedFetch<T>({
  method = "GET",
  headers = {},
  apiUrl,
  apiResource = "",
  payload = undefined,
  spanName = "",
  spanAttributes,
}: InstrumentedFetchArgs): Promise<ResponseData<T>> {
  return wrapRequestWithTrace<T>(spanName, spanAttributes, {
    method,
    additionalHeaders: headers,
    uri: apiResource ? apiUrl.replace(/\/$/, "") + "/" + apiResource.replace(/^\//, "") : apiUrl,
    payload,
  });
}
