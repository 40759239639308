"use client";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { trackEvent } from "../../utils/event-tracker";
import { Form, Row, Col } from "reactstrap";
import { Modal } from "../../components/modal";
import { FormGroup, Label } from "../../components/form-group";
import Input from "../../components/Input";
import { Button } from "../../components/button";
import { TagTypeDetails } from "../../api/metadata/types";

const DATA_TESTIDS = {
  typeInput: "tag-type",
  highlighted: "tag-highlighted",
  submitButton: "tag-type-submit-button",
};

type TagType = Pick<TagTypeDetails, "tagType">;

type AddTagTypeModalProps = {
  show: boolean;
  onHide: () => void;
  existingTagTypes: TagTypeDetails[];
  onSubmit: (data: TagType) => void;
  loading: boolean;
};

export function AddTagTypeModal({ show, onHide, existingTagTypes, onSubmit, loading }: AddTagTypeModalProps) {
  const [isDirty, setIsDirty] = useState(false);
  const schemaObject = {
    tagType: yup
      .string()
      .strict()
      .trim()
      .required("Required")
      .notOneOf(existingTagTypes?.map(({ tagType }) => tagType), "tagType must not already exist"),
  };
  const schema = yup.object(schemaObject).required();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    values: {
      tagType: "",
    },
  });

  function serializeValues(data: TagType) {
    onSubmit(data);
  }

  useEffect(() => {
    if (show) {
      trackEvent({
        name: `add-tag-type-modal-opened`,
      });
    }
  }, [show]);

  useEffect(() => {
    const subscription = watch(() => setIsDirty(true));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty}
      onClose={() => {
        setIsDirty(false);
        onHide();
      }}
      header="Create a new tagType"
    >
      <Form onSubmit={handleSubmit(serializeValues)}>
        <Row>
          <Col xs="8">
            <FormGroup className="without-margin-top">
              <Label htmlFor="tagType">Name</Label>
              <Controller
                name="tagType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    id="tagType"
                    data-testid={DATA_TESTIDS.typeInput}
                    type="text"
                    {...field}
                    error={error?.message}
                    invalid={!!error}
                    makeRoomForError
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col xs="4">
            <div className="text-end">
              <Button
                data-testid={DATA_TESTIDS.submitButton}
                name="submit-new-tag-type"
                type="submit"
                disabled={!isValid}
                loading={isSubmitting || loading}
                level="primary"
                theme="darker"
                rounded="pill"
              >
                Add new tagType
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
