import { debounce } from "lodash";
import mixpanel, { Dict } from "mixpanel-browser";

export function trackEvent({ name, ...variables }: Dict) {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  console.log(`Tracking event "${name}" with variables:`, variables);
  // @ts-expect-error ts doesn't know that dataLayer is part of window
  if (window?.dataLayer) {
    try {
      // @ts-expect-error ts doesn't know that dataLayer is part of window
      window.dataLayer.push({
        event: name,
        ...variables,
      });
      console.log(`Pushing event "${name}" to GTM with variables:`, variables);
    } catch (err) {
      console.log(`Failed to send to GTM. Reason: ${err}`);
    }
  }
  if (mixpanel) {
    try {
      mixpanel.track(name, variables);
    } catch (error) {
      console.log(`Failed to send to Mixpanel. Reason: ${error}`);
    }
  }
}

export function identifyUser({ uniqueId, ...properties }: Dict) {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  try {
    mixpanel.identify(uniqueId);
    trackEvent({ name: "login_successful" });
    if (properties) {
      mixpanel.people.set(properties);
    }
  } catch (error) {
    console.log(`Failed to send user identification to Mixpanel. Reason: ${error}`);
  }
}

export const trackPageview = debounce(trackPageviewInRealtime, 300);

function trackPageviewInRealtime({ name, isKhubPage = false, ...variables }: Dict) {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  console.log(`Tracking page view with variables:`, { name, ...variables });

  try {
    mixpanel.track(isKhubPage ? "page_viewed" : `page_${name}_viewed`, variables);
    mixpanel.people.union("pages_visited", name);
  } catch (error) {
    console.log(`Failed to send page view to Mixpanel. Reason: ${error}`);
  }
}
