import styled from "@emotion/styled";
import { css } from "@emotion/react";

const getAbsolute = (absolute) => {
  const abs = Array.isArray(absolute) ? absolute : [];
  const positionList = ["top", "right", "bottom", "left"];

  return positionList
    .map((position, key) => (abs[key] === undefined ? "" : `${position}: ${abs[key] ? `${abs[key] / 16}rem;` : "0;"}`))
    .filter(Boolean)
    .join("");
};

const getPosition = ({ absolute }) => {
  const style = css`
    position: ${Array.isArray(absolute) ? "absolute" : "relative"};
    ${getAbsolute(absolute)}
  `;

  return style;
};

const getPadding = ({ padding }) => {
  const newPad = Array.isArray(padding) ? padding : padding ? [padding] : [];

  return css(newPad.length ? `padding: ${newPad.map((pad) => `${pad / 16}rem`).join(" ")};` : "");
};

const getDirection = ({ vertical }) => css(vertical ? "flex-direction: column;" : "");

const getDisplay = ({ display }) =>
  css(
    display === "grid"
      ? `
  display: grid;
  grid-template-columns: 255px 1fr;
  `
      : "display: flex;",
  );

const getGap = ({ gap = 0 }) => css(gap ? `gap: ${gap / 16}rem;` : "");

const getAlign = ({ align }) => css(align ? `align-items: ${align === true ? "center" : align};` : "");

const getJustify = ({ justify }) => css(justify ? `justify-content: ${justify === true ? "center" : justify};` : "");

const getWidth = ({ width = 0, fullWidth }) =>
  css(width ? `width: ${width / 16}rem;` : fullWidth ? "width: 100%;" : "");

const getBorder = ({ border }) => css(border ? `border: 1px solid #d8d8d8` : "");

const getRadius = ({ borderRadius }) => {
  if (borderRadius) {
    return `border-radius: ${typeof borderRadius === "number" ? `${borderRadius}px` : `${borderRadius / 16}rem`}`;
  } else {
    return "";
  }
};

const getBackgroundColor = ({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`;

const getFlexWrap = ({ flexWrap }) => flexWrap && css(`flex-wrap: ${flexWrap}`);

const getFlexDirection = ({ flexDirection }) => flexDirection && css(`flex-direction: ${flexDirection}`);

const Box = styled.div`
  flex: ${({ flex }) => (flex ? flex : 1)};
  ${getFlexWrap}
  ${getFlexDirection}
  min-height: ${({ minHeight }) => minHeight && minHeight};
  ${getPosition}
  ${getDisplay}
  ${getPadding}
  ${getDirection}
  ${getGap}
  ${getWidth}
  ${getAlign}
  ${getJustify}
  ${getBorder}
  ${getRadius}
  ${getBackgroundColor}
`;

const style = {
  Box,
  getAbsolute,
  getDirection,
  getDisplay,
  getGap,
  getWidth,
  getPadding,
  getPosition,
  getAlign,
  getJustify,
  getBorder,
  getRadius,
  getFlexWrap,
  getFlexDirection,
};

export default style;
