import { AccountInfo } from "@azure/msal-browser";

type userIsGroupMemberProps = {
  account?: AccountInfo;
  groupId: string;
};

export function userIsGroupMember({ account, groupId }: userIsGroupMemberProps) {
  if (!account) {
    return;
  }
  if (account.idTokenClaims && account.idTokenClaims.roles) {
    return account?.idTokenClaims?.roles?.indexOf(groupId) > -1;
  }
}
