"use client";
import { useEffect } from "react";

import { trackEvent } from "../../utils/event-tracker";

import { Modal } from "../modal";
import { Button } from "../button";

export const TESTID_ENUMS = {
  prompt: "moderation-confirmation-modal-prompt",
  confirmButton: "moderation-confirmation-modal-submit",
};
export default function ModerationConfirmationModal({ show, onConfirm, onHide, type, target, loading }) {
  useEffect(() => {
    if (show) {
      trackEvent({
        name: `confirm-${type}-modal-opened`,
      });
    }
  }, [show]);
  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={false}
      onClose={() => {
        onHide();
      }}
      header="Confirmation"
    >
      <h4 className="text-center" data-testid={TESTID_ENUMS.prompt}>
        You're sure you want to {type} this question?
      </h4>
      <div className="text-center mb-8">
        <Button
          name={`moderator-confirm-${type}`}
          data-testid={TESTID_ENUMS.confirmButton}
          loading={loading}
          className="text-capitalize"
          onClick={() => onConfirm({ type, id: target })}
          theme="darker"
          level="primary"
          rounded="pill"
          size="lg"
        >
          {type}
        </Button>
      </div>
    </Modal>
  );
}

ModerationConfirmationModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  onHide: () => {},
  loading: false,
};
