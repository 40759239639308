import { create } from "zustand";
import { DocumentStatus, DocumentType } from "../../views/khub/enums";
import { MetadataAttributes } from "../../api/khub/v2/metadata";

/**
 * See: https://tkdodo.eu/blog/working-with-zustand
 * TL;DR:
 * 1. don't export the entire store
 * 2. keep state selection atomic
 * 3. export all actions since they're static and won't trigger rerenders
 */

type PageStore = {
  uuid: string;
  pageTitle: string;
  markdownRaw: string;
  renderedMdx: any;
  sourceUrl: string;
  repository: string;
  document: string;
  tags: string[];
  isWatchingDocument: boolean;
  status: DocumentStatus;
  enableCreatePageModal: boolean;
  isSuccessModalOpen: boolean;
  isErrorModalOpen: boolean;
  headings?: NodeListOf<HTMLHeadingElement>;
  legacyRenderer: boolean;
  userHasEditorAccess: boolean;
  createdByEmail: string;
  createdDate: string;
  lastPublishedByEmail: string;
  lastPublishedDate: string;
  lockedBy: string | null;
  promoted: string;
  documentType: DocumentType | null;
  techDebtDesc: string | null;
  actions: {
    setUuid: (val: string) => void;
    setPageTitle: (val: string) => void;
    setMarkdownRaw: (val: string) => void;
    setRenderedMdx: (val: any) => void;
    setSourceUrl: (val: string) => void;
    setRepository: (val: string) => void;
    setDocument: (val: string) => void;
    setTags: (val: string[]) => void;
    setStatus: (val: DocumentStatus) => void;
    setWatchingDocument: (val: boolean) => void;
    setEnableCreatePageModal: (val: boolean) => void;
    setIsSuccessModalOpen: (val: boolean) => void;
    setIsErrorModalOpen: (val: boolean) => void;
    setHeadings: (val: PageStore["headings"]) => void;
    setUseLegacyRenderer: (val: boolean) => void;
    setUserHasEditorAccess: (val: boolean) => void;
    setCreatedByEmail: (val: string) => void;
    setCreatedDate: (val: string) => void;
    setLastPublishedByEmail: (val: string) => void;
    setLastPublishedDate: (val: string) => void;
    setDocumentType: (val: DocumentType | null) => void;
    setLockedBy: (val: string | null) => void;
    setPromoted: (val: string) => void;
    setTechDebtDesc: (val: string | null) => void;
    setMetadata: (val: MetadataAttributes) => void;
  };
};

// do not export this, instead: export individual hooks
const usePageStore = create<PageStore>()((set) => ({
  uuid: "",
  pageTitle: "Loading...",
  markdownRaw: "",
  renderedMdx: undefined,
  sourceUrl: "",
  repository: "",
  document: "",
  tags: [],
  isWatchingDocument: false,
  status: DocumentStatus.PUBLISHED,
  enableCreatePageModal: false,
  isSuccessModalOpen: false,
  isErrorModalOpen: false,
  headings: undefined,
  legacyRenderer: false,
  userHasEditorAccess: true,
  createdBy: "",
  createdByEmail: "",
  createdDate: "",
  lastPublishedBy: "",
  lastPublishedByEmail: "",
  lastPublishedDate: "",
  lockedBy: null,
  promoted: "false",
  documentType: null,
  techDebtDesc: null,
  // separate namespace for actions
  actions: {
    setUuid: (uuid) => set(() => ({ uuid })),
    setPageTitle: (pageTitle) => set(() => ({ pageTitle })),
    setMarkdownRaw: (markdownRaw) => set(() => ({ markdownRaw })),
    setRenderedMdx: (renderedMdx) => set(() => ({ renderedMdx })),
    setSourceUrl: (sourceUrl) => set(() => ({ sourceUrl })),
    setRepository: (repository) => set(() => ({ repository })),
    setDocument: (document) => set(() => ({ document })),
    setTags: (tags) => set(() => ({ tags })),
    setStatus: (status) => set(() => ({ status })),
    setWatchingDocument: (isWatchingDocument) => set(() => ({ isWatchingDocument })),
    setEnableCreatePageModal: (enableCreatePageModal) => set(() => ({ enableCreatePageModal })),
    setIsSuccessModalOpen: (isSuccessModalOpen) => set(() => ({ isSuccessModalOpen })),
    setIsErrorModalOpen: (isErrorModalOpen) => set(() => ({ isErrorModalOpen })),
    setHeadings: (headings) => set(() => ({ headings })),
    setUseLegacyRenderer: (legacyRenderer) => set(() => ({ legacyRenderer })),
    setUserHasEditorAccess: (userHasEditorAccess) => set(() => ({ userHasEditorAccess })),
    setCreatedByEmail: (createdByEmail) => set(() => ({ createdByEmail })),
    setCreatedDate: (createdDate) => set(() => ({ createdDate })),
    setLastPublishedByEmail: (lastPublishedByEmail) => set(() => ({ lastPublishedByEmail })),
    setLastPublishedDate: (lastPublishedDate) => set(() => ({ lastPublishedDate })),
    setDocumentType: (documentType) => set(() => ({ documentType })),
    setLockedBy: (lockedBy) => set(() => ({ lockedBy })),
    setPromoted: (promoted) => set(() => ({ promoted })),
    setTechDebtDesc: (techDebtDesc) => set(() => ({ techDebtDesc })),
    setMetadata: (metadata: MetadataAttributes) =>
      set(() => {
        const {
          created_by = "",
          created_by_email = "",
          created_date = "",
          last_published_by = "",
          last_published_by_email = "",
          last_published_date = "",
          document_type = null,
          status,
          promoted = "false",
          locked_by = null,
          tech_debt = null,
        } = metadata;

        return {
          createdBy: created_by,
          createdByEmail: created_by_email,
          createdDate: created_date,
          lastPublishedBy: last_published_by,
          lastPublishedByEmail: last_published_by_email,
          lastPublishedDate: last_published_date,
          documentType: document_type,
          status: status ?? DocumentStatus.PUBLISHED,
          promoted: promoted,
          lockedBy: locked_by,
          techDebtDesc: tech_debt,
        };
      }),
  },
}));

export const useUuid = () => usePageStore((state) => state.uuid);
export const usePageTitle = () => usePageStore((state) => state.pageTitle);
export const useMarkdownRaw = () => usePageStore((state) => state.markdownRaw);
export const useRenderedMdx = () => usePageStore((state) => state.renderedMdx);
export const useSourceUrl = () => usePageStore((state) => state.sourceUrl);
export const useRepository = () => usePageStore((state) => state.repository);
export const useDocument = () => usePageStore((state) => state.document);
export const useTags = () => usePageStore((state) => state.tags);
export const useStatus = () => usePageStore((state) => state.status);
export const useWatchingDocument = () => usePageStore((state) => state.isWatchingDocument);
export const useEnableCreatePageModal = () => usePageStore((state) => state.enableCreatePageModal);
export const useIsSuccessModalOpen = () => usePageStore((state) => state.isSuccessModalOpen);
export const useIsErrorModalOpen = () => usePageStore((state) => state.isErrorModalOpen);
export const useHeadings = () => usePageStore((state) => state.headings);
export const useLegacyRenderer = () => usePageStore((state) => state.legacyRenderer);
export const useUserHasEditorAccess = () => usePageStore((state) => state.userHasEditorAccess);
export const useCreatedByEmail = () => usePageStore((state) => state.createdByEmail);
export const useCreatedDate = () => usePageStore((state) => state.createdDate);
export const useDocumentType = () => usePageStore((state) => state.documentType);
export const useLastPublishedByEmail = () => usePageStore((state) => state.lastPublishedByEmail);
export const useLastPublishedDate = () => usePageStore((state) => state.lastPublishedDate);
export const useLockedBy = () => usePageStore((state) => state.lockedBy);
export const usePromoted = () => usePageStore((state) => state.promoted);
export const useTechDebtDesc = () => usePageStore((state) => state.techDebtDesc);

// one selector for all of our actions
export const usePageActions = () => usePageStore((state) => state.actions);
