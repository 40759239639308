"use client";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { env } from "next-runtime-env";

import {
  APPROVE_QUESTION,
  GET_QUESTIONS,
  GET_TAG,
  GET_TAGS,
  REJECT_QUESTION,
  UNREJECT_QUESTION,
} from "../../api/faq/gql";
import { GET_ALL_TAG_TYPES, ADD_TAG_TYPE, DELETE_TAG_TYPE } from "../../api/metadata/gql";
import { APPROVE_COMPONENT, REJECT_COMPONENT } from "../../api/code-hub/gql";
import { ADD_GLOBAL_TAG, DELETE_GLOBAL_TAG } from "../../api/metadata/gql";
import { GET_ALL_EXISTING_COMPONENTS } from "../../api/code-hub/gql";
import { GET_ALL_TAG_DETAILS } from "../../api/metadata/gql";
import { ADD_NEW_SURVEY, DELETE_SURVEY, UPDATE_SURVEY } from "../../api/users/gql";
import { GET_ALL_SURVEYS } from "../../api/users/gql";

import { useLocation } from "../../hooks/use-location";

import { TabPane } from "reactstrap";
import Page from "../../components/page-template";
import { Loader } from "../../components/spinners/loading-spinner";
import buildIcon from "../../utils/buildIcon";
import { Link } from "../../components/Link";
import Select from "../../components/Select";
import { Button } from "../../components/button";
import { FAQPreviewCard } from "../../components/FAQComponents/faq-preview-card";
import { TagPreviewCard, TagDetails } from "./tag-preview-card";
import SurveyPreviewCard from "./survey-preview-card";
import TagTypePreviewCard from "./tag-type-preview-card";
import { ComponentPreviewCard } from "./component-preview-card";
import ModerationConfirmationModal from "../../components/FAQModals/ModerationConfirmationModal";
import { AskAQuestionModal } from "../../forms/FAQ/askAQuestion/modal";
import AddATagModal from "../../forms/moderation/AddATagModal";
import { AddTagTypeModal } from "../../forms/moderation/add-tag-type-modal";
import { type AddSurveyInputs, AddSurveyModal } from "../../forms/moderation/add-survey-modal";
import { type UpdateSurveyInputs, UpdateSurveyModal } from "../../forms/moderation/update-survey-modal";
import SurveyConfirmationModal from "./survey-confirmation-modal";
import { TagTypeDeleteModal } from "./tag-type-delete-modal";
import TagModerationConfirmationModal from "./TagModerationConfirmationModal";
import ComponentModerationConfirmationModal from "./ComponentModerationConfirmationModal";
import { NavTabs, TabContent } from "../../components/NavTabs";

import { ReportCard } from "./report-card";
import type { Component, ComponentConfirmationReply, SurveyConfirmationReply, TagConfirmationReply } from "./models";
import type { SurveyDetails } from "../../api/users/types.js";
import { updateQueryParams, updateQueryParamsAsSearchParams } from "../../utils/update-search-params";
import type { FAQQuestionWithAnswerAndCommentId } from "../../api/faq/types.js";
import { ApolloPlayGroundLink } from "../../components/buttons/apollo-playground-link";
import { NotificationInputs, SendNotificationModal } from "../../forms/moderation/send-notification-modal";
import { createNotification } from "../../pages/notifications";
import { usePerceivedAndEmpiricalsReport, useProductQuizAssessmentReport } from "../../hooks/use-reports";
import { AddTagTypeMutationVariables } from "../../gql/graphql";

export const EMPLOYEE_GROUP_ID = env("NEXT_PUBLIC_EMPLOYEE_GROUP_ID") ?? "0aeadfd7-7f8c-43d7-8ccd-2c688311e69b";

export const FAQ_MODERATOR_GROUP_ID =
  env("NEXT_PUBLIC_FAQ_MODERATOR_GROUP_ID") ?? "be250bf3-efa2-4894-9baa-70884fdf3207";

export const ADMIN_GROUP_ID = env("NEXT_PUBLIC_ADMIN_GROUP_ID") ?? "4d452a2c-5532-4669-a264-4f1738d0f839";

export const TESTID_ENUMS = {
  tagContainer: "tag-container",
  faqTab: "faq-moderation-tab",
  tagFilterButton: "tag-filter-button",
  ssaFilterButton: "ssa-filter-button",
  csFilterButton: "cs-filter-button",
  faqFilterButton: "faq-filter-button",
};

const TAB_ENUMS = ["Tags", "FAQs", "SSAs", "CSs", "TagType", "Surveys", "DORA metrics", "Notifications"];

const NEWEST = "newest";
const OLDEST = "oldest";

const SORT_OPTIONS = [
  { value: NEWEST, label: "Newest first" },
  { value: OLDEST, label: "Oldest first" },
];

const IN_REVIEW = "in-review";
const REJECTED = "rejected";
const ALL = "all";

export const FILTER_OPTIONS = [
  { value: IN_REVIEW, label: "Waiting for review" },
  { value: REJECTED, label: "Rejected" },
  { value: ALL, label: "All" },
];

export const QUESTION_STATUS = {
  rejected: 2,
  approved: 1,
  "in-review": 0,
};

const TAGS_QUERY_VARIABLES = {
  hideInReviewQuestions: false,
  hideApprovedQuestions: true,
  hideRejectedQuestions: false,
};

const QUESTION_QUERY_VARIABLES = {
  hideInReview: false,
  hideApproved: true,
  hideRejected: false,
};

const ComponentStatus = [
  {
    value: "Published",
    label: "Published",
  },
  {
    value: "In Review",
    label: "In Review",
  },
  {
    value: "Rejected",
    label: "Discarded",
  },
];

export function ModerationPage() {
  const { router, pathname, search } = useLocation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFaqInfo, setSelectedFaqInfo] = useState({});
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isEditingTag, setIsEditingTag] = useState(false);
  const [isAddSurveyModalOpen, setIsAddSurveyModalOpen] = useState(false);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);
  const [isSendGlobalNotificationModalOpen, setIsSendGlobalNotificationModalOpen] = useState(false);
  const [isUpdateSurveyModalOpen, setIsUpdateSurveyModalOpen] = useState(false);
  const [isSurveyConfirmationModalOpen, setIsSurveyConfirmationModalOpen] = useState(false);
  const [surveyErrorMessage, setSurveyErrorMessage] = useState<string | null>(null);
  const [isAddTagTypeModalOpen, setIsAddTagTypeModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isTagConfirmationModalOpen, setIsTagConfirmationModalOpen] = useState(false);
  const [isTagTypeConfirmationModalOpen, setIsTagTypeConfirmationModalOpen] = useState(false);
  const [isComponentConfirmationModalOpen, setIsComponentConfirmationModalOpen] = useState(false);
  const [errorTagMessage, setErrorTagMessage] = useState("");
  const [errorComponentConfirmationModal, setErrorComponentConfirmationModal] = useState<string>();
  const [confirmationModalType, setConfirmationModalType] = useState<string>();
  const [confirmationModalTarget, setConfirmationModalTarget] = useState<string | number>();
  const [currentTag, setCurrentTag] = useState<TagDetails | null>(null);
  const [isCreatingNotification, setIsCreatingNotification] = useState(false);
  const [isCreatingGlobalNotification, setIsCreatingGlobalNotification] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState<SurveyDetails | null>(null);
  const [associatedTags, setAssociatedTags] = useState<string[]>([]);

  const { data: allSurveys } = useQuery(GET_ALL_SURVEYS);

  const {
    isLoading: isDownloadingPerceivedAndEmpiricalMetricsReport,
    trigger: downloadPerceivedAndEmpiricalMetricsReport,
  } = usePerceivedAndEmpiricalsReport();

  const { isLoading: isDownloadingProductQuizAssessmentReport, trigger: downloadProductQuizAssessmentReport } =
    useProductQuizAssessmentReport();

  const [PersistSurvey, { loading: isSurveySubmitting }] = useMutation(ADD_NEW_SURVEY, {
    onCompleted() {
      setIsAddSurveyModalOpen(false);
    },
    refetchQueries: [{ query: GET_ALL_SURVEYS }],
  });

  const [updateSurvey, { loading: isSurveyUpdating }] = useMutation(UPDATE_SURVEY, {
    onCompleted() {
      setIsUpdateSurveyModalOpen(false);
    },
    refetchQueries: [{ query: GET_ALL_SURVEYS }],
  });

  const [removeSurvey, { loading: removingSurvey }] = useMutation(DELETE_SURVEY, {
    onCompleted() {
      setIsSurveyConfirmationModalOpen(false);
    },
    onError(error) {
      setSurveyErrorMessage(error?.message);
    },
    refetchQueries: [{ query: GET_ALL_SURVEYS }],
  });

  const [AddTag, { loading: tagSubmitting }] = useMutation(ADD_GLOBAL_TAG, {
    onCompleted() {
      setIsAddTagModalOpen(false);
      setCurrentTag(null);
    },
    refetchQueries: [{ query: GET_ALL_TAG_DETAILS }],
  });

  const [AddTagType, { loading: tagTypeSubmitting }] = useMutation(ADD_TAG_TYPE, {
    onCompleted() {
      setIsAddTagTypeModalOpen(false);
    },
    refetchQueries: [{ query: GET_ALL_TAG_TYPES }],
  });

  const [
    DeleteTagType,
    { loading: loadingDeleteTagTypeMutation, error: deleteTagTypeError, reset: resetDeleteTagTypeMutation },
  ] = useMutation(DELETE_TAG_TYPE, {
    onCompleted() {
      setIsTagTypeConfirmationModalOpen(false);
      if (search.tag) {
        refetchTag();
      } else {
        refetchTags();
      }
    },
    onError() {
      // Keep this block so that errors do not break the UI
      // setErrorTagTypeMessage(error?.message);
    },
    refetchQueries: [{ query: GET_ALL_TAG_TYPES }],
  });

  const [DeleteTag] = useMutation(DELETE_GLOBAL_TAG, {
    onCompleted() {
      setIsTagConfirmationModalOpen(false);
    },
    onError(error) {
      setErrorTagMessage(error?.message);
    },
    refetchQueries: [{ query: GET_ALL_TAG_DETAILS }],
  });

  const [ApproveComponent, { loading: componentApproving }] = useMutation(APPROVE_COMPONENT, {
    onCompleted() {
      setIsComponentConfirmationModalOpen(false);
    },
    onError(error) {
      setErrorComponentConfirmationModal(error?.message);
    },
    refetchQueries: [{ query: GET_ALL_EXISTING_COMPONENTS }],
  });

  const [RejectComponent, { loading: componentRejecting }] = useMutation(REJECT_COMPONENT, {
    onCompleted() {
      setIsComponentConfirmationModalOpen(false);
    },
    onError(error) {
      setErrorComponentConfirmationModal(error?.message);
    },
    refetchQueries: [{ query: GET_ALL_EXISTING_COMPONENTS }],
  });

  const [UnrejectQuestion, { loading: unrejectionLoading }] = useMutation(UNREJECT_QUESTION, {
    onCompleted() {
      setIsConfirmationModalOpen(false);
    },
  });

  const [RejectQuestion, { loading: rejectionLoading }] = useMutation(REJECT_QUESTION, {
    onCompleted() {
      setIsConfirmationModalOpen(false);
    },
  });

  const [ApproveQuestion, { loading: approvalLoading }] = useMutation(APPROVE_QUESTION, {
    onCompleted() {
      setIsConfirmationModalOpen(false);
    },
  });

  const {
    loading: tagIsLoading,
    data: tagQuery,
    refetch: refetchTag,
  } = useQuery(GET_TAG, {
    variables: {
      ...TAGS_QUERY_VARIABLES,
      name: search.tag,
    },
    skip: !search.tag,
  });

  const { loading: componentsAreLoading, data: allComponents } = useQuery(GET_ALL_EXISTING_COMPONENTS);

  const allSsas = allComponents?.allExistingComponents?.filter((x: Component) => x.componentType === "ssa");
  const allCss = allComponents?.allExistingComponents?.filter((x: Component) => x.componentType === "cs");
  const searchSsaStatus = search.showSsas;
  const searchCssStatus = search.showCss;

  const filteredSsas =
    searchSsaStatus === "all" || !searchSsaStatus
      ? allSsas
      : allSsas?.filter((component: Component) => component.status === searchSsaStatus);

  const filteredCss =
    searchCssStatus === "all" || !searchCssStatus
      ? allCss
      : allCss?.filter((component: Component) => component.status === searchCssStatus);

  const { loading: tagListDetailsAreLoading, data: tagListDetails } = useQuery(GET_ALL_TAG_DETAILS);

  const { loading: tagTypeListDetailsAreLoading, data: tagTypeListDetails } = useQuery(GET_ALL_TAG_TYPES);

  const {
    loading: tagsAreLoading,
    data: tagsQuery,
    refetch: refetchTags,
  } = useQuery(GET_TAGS, {
    variables: TAGS_QUERY_VARIABLES,
  });

  const tagsWithQuestions = [];
  if (tagsQuery) {
    tagsWithQuestions.length = 0;
    const filtered = tagsQuery.tags.filter(({ questionCount }: { questionCount: number }) => questionCount > 0);
    tagsWithQuestions.push(...filtered);
  }

  const {
    loading: questionsAreLoading,
    data: questionQuery,
    refetch: refetchQuestions,
  } = useQuery<{ questions: FAQQuestionWithAnswerAndCommentId }>(GET_QUESTIONS, {
    variables: QUESTION_QUERY_VARIABLES,
    skip: !!search.tag,
  });

  const showFaqs = search.showFaqs ?? IN_REVIEW;
  const questionList = questionQuery?.questions ?? tagQuery?.tag?.questions;
  const rejectedQuestions =
    questionList?.filter(({ status }: { status: number }) => status === QUESTION_STATUS.rejected) || [];

  const inReviewQuestions =
    questionList?.filter(({ status }: { status: number }) => status === QUESTION_STATUS["in-review"]) || [];
  const allQuestions = questionList || [];

  let showQuestions = [...inReviewQuestions];
  let pageInfo;
  const sortBy = SORT_OPTIONS.find(({ value }) => value === search.sort_by) || SORT_OPTIONS[0];

  if (showFaqs === REJECTED) {
    showQuestions = [...rejectedQuestions];
  } else if (showFaqs === ALL) {
    showQuestions = [...allQuestions];
  }

  if (search.tag) {
    const newSearchParams = updateQueryParamsAsSearchParams(search, {
      tag: undefined,
    });
    pageInfo = {
      breadcrumbLink: `${pathname}?${newSearchParams}`,
      breadcrumb: "Back to moderation dashboard",
      title: `FAQs with tag "${search.tag}"`,
    };
  } else {
    pageInfo = {
      pageHeader: "Accelerate moderation dashboard",
      title: "Accelerate moderation dashboard",
    };
  }

  const allTagDetails = tagListDetails?.getAllTagDetails?.tagDetails;
  const allTagTypeDetails = tagTypeListDetails?.getAllTagTypes;
  const searchTagType = search.showTags ?? "all";
  const filteredTagDetails =
    searchTagType === "all" || !searchTagType
      ? allTagDetails
      : allTagDetails?.filter((tag) => tag.tagType === searchTagType);
  showQuestions.sort((a: FAQQuestionWithAnswerAndCommentId, b: FAQQuestionWithAnswerAndCommentId) => {
    if (sortBy.value === NEWEST) {
      return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;
    }
    if (sortBy.value === OLDEST) {
      return new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1;
    }
    return 1;
  });

  const tagTypeOptions = allTagTypeDetails?.map((tagType) => {
    const { id: value, tagType: label } = tagType;
    return { value, label };
  });
  let selectedTab: string = (typeof search.tab === "object" ? search.tab[0] : search.tab) ?? TAB_ENUMS[0];
  if (!TAB_ENUMS.some((tabName) => selectedTab === tabName)) {
    selectedTab = TAB_ENUMS[0];
  }

  function filterTags(filter: string) {
    router?.push({
      pathname,
      query: updateQueryParams(search, {
        showTags: filter,
      }),
    });
  }
  function filterFaqs(filter: string) {
    router?.push({
      pathname,
      query: updateQueryParams(search, {
        showFaqs: filter,
      }),
    });
  }

  function filterSsa(filter: string) {
    router?.push({
      pathname,
      query: updateQueryParams(search, {
        showSsas: filter,
      }),
    });
  }

  function filterCss(filter: string) {
    router?.push({
      pathname,
      query: updateQueryParams(search, {
        showCss: filter,
      }),
    });
  }

  function handleFaqEdit(id: string) {
    setIsEditModalOpen(true);
    setSelectedFaqInfo(allQuestions.find((question: { id: string }) => question.id === id));
  }

  function handleConfirmation({ type, id }: ComponentConfirmationReply) {
    setConfirmationModalTarget(id);
    setConfirmationModalType(type);
    setIsConfirmationModalOpen(true);
  }

  function handleComponentConfirmation({ type, id }: ComponentConfirmationReply) {
    setConfirmationModalTarget(id);
    setConfirmationModalType(type);
    setIsComponentConfirmationModalOpen(true);
  }

  async function handleConfirmationClick({ type, id }: ComponentConfirmationReply | SurveyConfirmationReply) {
    const data = { variables: { id } };
    switch (type) {
      case "approve":
        await ApproveQuestion(data);
        break;
      case "reject":
        await RejectQuestion(data);
        break;
      case "unreject":
        await UnrejectQuestion(data);
        break;
      default:
    }
    if (search.tag) {
      refetchTag();
    } else {
      refetchTags();
      refetchQuestions();
    }
  }

  function handleTagTypeConfirmation({ type, id }: TagConfirmationReply) {
    const getAssociatedTags = allTagDetails
      ?.filter((tagDetail) => tagDetail.tagType?.toLowerCase() === id.toString().toLowerCase())
      ?.map((tagDetail) => tagDetail.tagName);

    setAssociatedTags(getAssociatedTags ?? []);
    setConfirmationModalTarget(id);
    setConfirmationModalType(type);
    setIsTagTypeConfirmationModalOpen(true);
  }

  function handleTagConfirmation({ type, id }: TagConfirmationReply) {
    setConfirmationModalTarget(id);
    setConfirmationModalType(type);
    setErrorTagMessage("");
    setIsTagConfirmationModalOpen(true);
  }

  async function handleTagConfirmationClick({ type, id }: TagConfirmationReply) {
    switch (type) {
      case "delete":
        await DeleteTag({ variables: { tag: id } });
        break;
      // TODO: when tag update mutation becomes available
      // case 'update':
      default:
    }
    if (search.tag) {
      refetchTag();
    } else {
      refetchTags();
    }
  }

  async function handleTagTypeConfirmationClick(id: string) {
    await DeleteTagType({ variables: { tagType: id } });
  }

  async function handleComponentConfirmationClick({ type, id }: ComponentConfirmationReply) {
    switch (type) {
      case "approve":
        await ApproveComponent({ variables: { data: { component_id: id } } });
        break; // edit is the next case
      case "reject":
        await RejectComponent({ variables: { data: { component_id: id } } });
        break; // edit is the next case
    }
  }

  async function handleTagEdit(tag: TagDetails) {
    setIsEditingTag(true);
    setCurrentTag(tag);
    setIsAddTagModalOpen(true);
  }

  async function handleTagAdd(
    tag: Omit<TagDetails, "tagType"> & {
      tagType: { label: string; value: string };
    },
  ) {
    return AddTag({
      variables: {
        command: {
          tagName: tag.tagName,
          tagType: tag.tagType.value ?? null,
          description: tag.description,
          kHubOverviewPage: tag.kHubOverviewPage,
          highlighted: tag.highlighted,
          icon: tag.icon,
        },
      },
    });
  }

  async function handleTagTypeAdd(tagType: AddTagTypeMutationVariables) {
    await AddTagType({ variables: tagType });
  }

  async function handleAddSurvey(survey: AddSurveyInputs) {
    await PersistSurvey({
      variables: { survey },
    });
  }

  async function handleSendNotificationSubmission(notification: NotificationInputs) {
    createNotification(setIsCreatingNotification, notification);
    setIsSendNotificationModalOpen(false);
  }

  async function handleSendGlobalNotificationSubmission(notification: NotificationInputs) {
    createNotification(setIsCreatingGlobalNotification, notification);
    setIsSendGlobalNotificationModalOpen(false);
  }

  function handleUpdateSurvey(survey: SurveyDetails) {
    setIsUpdateSurveyModalOpen(true);
    setCurrentSurvey(survey);
  }

  async function handleUpdateSurveySubmission(survey: UpdateSurveyInputs) {
    const { surveyId, linkToSurvey, startDate, endDate, estimatedTime, approved, description, surveyName } = survey;

    setCurrentSurvey(null);

    await updateSurvey({
      variables: {
        survey: {
          surveyId,
          linkToSurvey,
          startDate,
          endDate,
          estimatedTime,
          approved,
          description,
          surveyName,
        },
      },
    });
  }

  function handleSurveyConfirmation({ type, id }: SurveyConfirmationReply) {
    setIsSurveyConfirmationModalOpen(true);
    setConfirmationModalType(type);
    setConfirmationModalTarget(id.toString());
    setSurveyErrorMessage(null);
    const getSurvey = allSurveys?.listSurveys.find((survey: SurveyDetails) => survey.surveyId === id);
    setCurrentSurvey(getSurvey ?? null);
  }

  async function handleSurveyConfirmationClick({ id }: { id: string }) {
    const data = { variables: { surveyId: id } };
    await removeSurvey(data);
    setCurrentSurvey(null);
  }

  useEffect(() => {
    if (search.tag) {
      refetchTag();
    }
    refetchTags();
  }, [search]);

  const pageIsLoading =
    tagIsLoading ||
    tagListDetailsAreLoading ||
    questionsAreLoading ||
    componentsAreLoading ||
    tagTypeListDetailsAreLoading;

  return (
    <Page name="faq-moderation-page" {...pageInfo} isLoading={pageIsLoading}>
      {pageInfo?.breadcrumbLink ? <h3 className="fw-light mt-4">{pageInfo.title}</h3> : <ApolloPlayGroundLink />}

      <div className="mt-5">
        <NavTabs
          activeTab={selectedTab}
          tabs={[
            {
              id: 1,
              name: TAB_ENUMS[0],
              description: "Tags",
            },
            { id: 2, name: TAB_ENUMS[1], description: "FAQs" },
            { id: 3, name: TAB_ENUMS[2], description: "SSAs" },
            { id: 4, name: TAB_ENUMS[3], description: "CSs" },
            { id: 5, name: TAB_ENUMS[4], description: "TagType" },
            { id: 6, name: TAB_ENUMS[5], description: "Surveys" },
            { id: 7, name: TAB_ENUMS[6], description: "DORA metrics" },
            { id: 8, name: TAB_ENUMS[7], description: "Notifications" },
          ]}
          onTabSelection={(tab) =>
            router?.push(
              `${pathname}?${updateQueryParamsAsSearchParams(search, {
                tab,
              }).toString()}`,
            )
          }
        />
      </div>
      <TabContent activeTab={selectedTab}>
        <TabPane tabId={TAB_ENUMS[0]}>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-wrap gap-4">
              <div className="d-flex flex-row-reverse w-100 mb-4">
                <Button
                  onClick={() => {
                    setIsEditingTag(false);
                    setCurrentTag(null);
                    setIsAddTagModalOpen(true);
                  }}
                  name="add-tag-button"
                  data-testid="add-tag-button"
                  level="primary"
                  theme="standard"
                  rounded="pill"
                  size="lg"
                >
                  <span>Add a tag</span>
                </Button>
              </div>

              <Button
                onClick={() => filterTags("all")}
                data-testid={`${TESTID_ENUMS.tagFilterButton}-all`}
                name="faq-btn-filter-tag"
                active={searchTagType === "all"}
                color={`${searchTagType === "all" ? "dark" : "standard-outline"}`}
                level="tertiary"
                theme="standard"
                rounded="pill"
                size="sm"
              >
                All ( {allTagDetails?.length} )
              </Button>
              {allTagTypeDetails?.map((tagType) => {
                const { id, tagType: label } = tagType;
                return (
                  <Button
                    onClick={() => filterTags(id)}
                    key={id}
                    className="me-4"
                    name="faq-btn-filter-tag"
                    data-testid={`${TESTID_ENUMS.tagFilterButton}-${id}`}
                    active={searchTagType === id}
                    color={`${searchTagType === id ? "dark" : "standard-outline"}`}
                    level="tertiary"
                    theme="standard"
                    rounded="pill"
                    size="sm"
                  >
                    {label} ({allTagDetails?.filter((tag) => tag.tagType === id)?.length})
                  </Button>
                );
              })}
            </div>
            <div className="d-flex flex-column gap-5">
              {filteredTagDetails?.map((tagDetail) => {
                return (
                  <TagPreviewCard
                    key={tagDetail.id}
                    tag={tagDetail}
                    iconUrl={buildIcon(tagDetail?.icon)?.toString()}
                    onEditClick={handleTagEdit}
                    onDeleteClick={handleTagConfirmation}
                  />
                );
              })}
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[1]} data-testid={TESTID_ENUMS.faqTab}>
          <div className="d-flex justify-content-between gap-8 mt-4">
            <div className="d-flex flex-column flex-grow-1">
              <div className="d-flex gap-3">
                {FILTER_OPTIONS?.map(({ value, label }, index) => {
                  let count = inReviewQuestions.length;
                  if (index === 1) {
                    count = rejectedQuestions.length;
                  } else if (index === 2) {
                    count = allQuestions.length;
                  }

                  return (
                    <Button
                      onClick={() => filterFaqs(value)}
                      key={index}
                      className="me-4"
                      name="faq-btn-filter-tag"
                      data-testid={`${TESTID_ENUMS.faqFilterButton}-${value}`}
                      active={showFaqs === value}
                      color={`${showFaqs === value ? "dark" : "standard-outline"}`}
                      level="tertiary"
                      theme="standard"
                      rounded="pill"
                      size="sm"
                    >
                      {label} ({count})
                    </Button>
                  );
                })}
              </div>
              <div className="d-flex flex-column gap-5">
                <div className="d-flex align-items-center gap-2 justify-content-end">
                  <span className="me-3">Sorted by</span>
                  <Select
                    options={SORT_OPTIONS}
                    value={sortBy}
                    onChange={({ value }: { value: string }): void => {
                      router?.push({
                        pathname,
                        query: updateQueryParams(search, {
                          sort_by: value,
                        }),
                      });
                    }}
                  />
                </div>

                {showQuestions.map((faq) => {
                  return (
                    <FAQPreviewCard
                      key={faq.id}
                      {...faq}
                      onEditClick={handleFaqEdit}
                      onConfirmClick={handleConfirmation}
                    />
                  );
                })}
              </div>
            </div>
            {!search.tag ? (
              <div className="d-flex flex-column" style={{ width: 200 }} data-testid={TESTID_ENUMS.tagContainer}>
                <div>
                  {tagsAreLoading ? (
                    <div className="w-50 mt-4">
                      <Loader />
                    </div>
                  ) : (
                    tagsWithQuestions?.map(({ name, questionCount }) => (
                      <div key={name}>
                        <Link href={`${pathname}?${updateQueryParamsAsSearchParams(search, { tag: name })}`}>
                          <div className="chip chip-light" style={{ width: "fit-content" }}>
                            <div className="chip-label px-3">
                              {name} ({questionCount})
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[2]}>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-row">
              <div style={{ height: "fit-content" }}>
                <Button
                  onClick={() => filterSsa("all")}
                  className="me-4"
                  data-testid={`${TESTID_ENUMS.ssaFilterButton}-all`}
                  name="faq-btn-filter-tag"
                  active={searchSsaStatus === "all" || !searchSsaStatus}
                  color={`${searchSsaStatus === "all" ? "dark" : "standard-outline"}`}
                  level="tertiary"
                  theme="standard"
                  rounded="pill"
                  size="sm"
                >
                  All ( {allSsas?.length} )
                </Button>
                {ComponentStatus.map((componentStatus) => {
                  return (
                    <Button
                      onClick={() => filterSsa(componentStatus.value)}
                      key={componentStatus.value}
                      className="me-4"
                      name="faq-btn-filter-ssa"
                      data-testid={`${TESTID_ENUMS.ssaFilterButton}-${componentStatus.value}`}
                      active={searchSsaStatus === componentStatus.value}
                      color={`${searchSsaStatus === componentStatus.value ? "dark" : "standard-outline"}`}
                      level="tertiary"
                      theme="standard"
                      rounded="pill"
                      size="sm"
                    >
                      {componentStatus.label} (
                      {allSsas?.filter((component: Component) => component.status === componentStatus.value)?.length})
                    </Button>
                  );
                })}
                <div className="ms-auto"></div>
              </div>
            </div>
            <div className="d-flex flex-column gap-5">
              {filteredSsas?.map((component: Component) => {
                return (
                  <ComponentPreviewCard
                    key={component.id}
                    component={component}
                    onApproveClick={handleComponentConfirmation}
                    onRejectClick={handleComponentConfirmation}
                  />
                );
              })}
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[3]}>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-row">
              <div style={{ height: "fit-content" }}>
                <Button
                  onClick={() => filterCss("all")}
                  className="me-4"
                  data-testid={`${TESTID_ENUMS.csFilterButton}-all`}
                  name="faq-btn-filter-cs"
                  active={searchCssStatus === "all" || !searchCssStatus}
                  color={`${searchCssStatus === "all" ? "dark" : "standard-outline"}`}
                  level="tertiary"
                  theme="standard"
                  rounded="pill"
                  size="sm"
                >
                  All ( {allCss?.length} )
                </Button>
                {ComponentStatus.map((componentStatus) => {
                  return (
                    <Button
                      onClick={() => filterCss(componentStatus.value)}
                      key={componentStatus.value}
                      className="me-4"
                      name="faq-btn-filter-cs"
                      data-testid={`${TESTID_ENUMS.csFilterButton}-${componentStatus.value}`}
                      active={searchCssStatus === componentStatus.value}
                      color={`${searchCssStatus === componentStatus.value ? "dark" : "standard-outline"}`}
                      level="tertiary"
                      theme="standard"
                      rounded="pill"
                      size="sm"
                    >
                      {componentStatus.label} (
                      {allCss?.filter((component: Component) => component.status === componentStatus.value)?.length})
                    </Button>
                  );
                })}
                <div className="ms-auto"></div>
              </div>
            </div>
            <div className="d-flex flex-column gap-5">
              {filteredCss?.map((component: Component) => {
                return (
                  <ComponentPreviewCard
                    key={component.id}
                    component={component}
                    onApproveClick={handleComponentConfirmation}
                    onRejectClick={handleComponentConfirmation}
                  />
                );
              })}
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[4]}>
          <div className="container-fluid">
            <div className="d-flex flex-row-reverse w-100 mb-4">
              <Button
                name="tag-type-button"
                data-testid="tag-type-button"
                className="d-flex"
                onClick={() => {
                  setIsAddTagTypeModalOpen(true);
                }}
                level="primary"
                theme="standard"
                rounded="pill"
                size="lg"
              >
                Add Tag Type
              </Button>
            </div>
            <div className="row w-50">
              {allTagTypeDetails?.map((tagTypeDetail) => {
                return (
                  <div key={tagTypeDetail.id} className="col-12 w-100 mb-4">
                    <TagTypePreviewCard tagTypeDetail={tagTypeDetail} onDeleteClick={handleTagTypeConfirmation} />
                  </div>
                );
              })}
              <div className="ms-auto"></div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[7]}>
          <div className="container-fluid">
            <div className="d-flex flex-row w-100 mb-8">
              <Button
                name="add-new-survey"
                data-testid="survey-modal-button"
                className="d-flex"
                onClick={() => {
                  setIsSendNotificationModalOpen(true);
                }}
                level="primary"
                theme="standard"
                rounded="pill"
                size="lg"
              >
                Send Notification
              </Button>
              <Button
                name="add-new-survey"
                data-testid="survey-modal-button"
                className="d-flex"
                onClick={() => {
                  setIsSendGlobalNotificationModalOpen(true);
                }}
                level="primary"
                theme="standard"
                rounded="pill"
                size="lg"
              >
                Send Global Notification
              </Button>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[5]}>
          <div className="container-fluid">
            <div className="d-flex flex-row-reverse w-100 mb-8">
              <Button
                name="add-new-survey"
                data-testid="survey-modal-button"
                className="d-flex"
                onClick={() => {
                  setIsSendNotificationModalOpen(true);
                }}
                level="primary"
                theme="standard"
                rounded="pill"
                size="lg"
              >
                Add a New Survey
              </Button>
            </div>

            {/* Survey Table Header */}
            <div>
              {allSurveys?.listSurveys && (
                <div className="container-fluid w-100">
                  <div className="row mb-4 py-4 border rounded bg-light">
                    <div className="col">
                      <h6 className="m-0">Author</h6>
                    </div>
                    <div className="col">
                      <h6 className="m-0">Survey Name</h6>
                    </div>
                    <div className="col">
                      <h6 className="m-0">Estimated Time</h6>
                    </div>
                    <div className="col">
                      <h6 className="m-0">Status</h6>
                    </div>
                    <div className="col-3"></div>
                  </div>
                </div>
              )}

              {/* Display all surveys, sorted by the startDate in ascending order */}
              {(allSurveys?.listSurveys ? [...allSurveys.listSurveys] : [])
                ?.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                ?.map((survey) => (
                  <SurveyPreviewCard
                    key={survey.surveyId}
                    survey={survey}
                    onConfirmClick={handleSurveyConfirmation}
                    onUpdateClick={() => {
                      handleUpdateSurvey(survey);
                    }}
                  />
                ))}
            </div>
          </div>
        </TabPane>
        <TabPane tabId={TAB_ENUMS[6]}>
          <div className="container-fluid">
            <div className="d-flex flex-wrap" style={{ justifyContent: "flex-start" }}>
              <div className="w-50 p-3">
                <ReportCard
                  title="Product Quiz Assessment"
                  description="Breakdown of DORA maturity assessments for products."
                  onClick={() => downloadProductQuizAssessmentReport()}
                  isLoading={isDownloadingProductQuizAssessmentReport}
                />
              </div>
              <div className="w-50 p-3">
                <ReportCard
                  title="Perceived and Empirical Product Metric Data"
                  description="Products and their perceived and emipircal metrics data."
                  onClick={() => downloadPerceivedAndEmpiricalMetricsReport()}
                  isLoading={isDownloadingPerceivedAndEmpiricalMetricsReport}
                />
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
      <AddSurveyModal
        show={isAddSurveyModalOpen}
        onHide={() => setIsAddSurveyModalOpen(false)}
        loading={isSurveySubmitting}
        onSubmit={handleAddSurvey}
      />
      <SendNotificationModal
        show={isSendNotificationModalOpen}
        onHide={() => setIsSendNotificationModalOpen(false)}
        loading={isCreatingNotification}
        onSubmit={handleSendNotificationSubmission}
        globalNotification={false}
      />
      <SendNotificationModal
        show={isSendGlobalNotificationModalOpen}
        onHide={() => setIsSendGlobalNotificationModalOpen(false)}
        loading={isCreatingGlobalNotification}
        onSubmit={handleSendGlobalNotificationSubmission}
        globalNotification={true}
      />
      {currentSurvey ? (
        <>
          <UpdateSurveyModal
            show={isUpdateSurveyModalOpen}
            onHide={() => {
              setIsUpdateSurveyModalOpen(false);
              setCurrentSurvey(null);
            }}
            loading={isSurveyUpdating}
            onSubmit={handleUpdateSurveySubmission}
            {...currentSurvey}
          />
          {!!confirmationModalTarget && !!confirmationModalType && typeof confirmationModalTarget === "string" ? (
            <SurveyConfirmationModal
              show={isSurveyConfirmationModalOpen}
              onHide={() => {
                setSurveyErrorMessage("");
                setCurrentSurvey(null);
                setIsSurveyConfirmationModalOpen(false);
              }}
              onConfirm={handleSurveyConfirmationClick}
              target={confirmationModalTarget}
              type={confirmationModalType}
              loading={removingSurvey}
              error={surveyErrorMessage}
              {...currentSurvey}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <AddATagModal
        show={isAddTagModalOpen}
        onHide={() => setIsAddTagModalOpen(false)}
        onSubmit={handleTagAdd}
        loading={tagSubmitting}
        tagName={currentTag?.tagName}
        description={currentTag?.description}
        kHubOverviewPage={currentTag?.kHubOverviewPage}
        highlighted={currentTag?.highlighted}
        edit={isEditingTag}
        tagTypeOptions={tagTypeOptions}
        tagType={tagTypeOptions?.find(
          (tagType: { value: string; label: string }) => tagType.value === currentTag?.tagType,
        )}
        icon={currentTag?.icon}
        existingTags={isEditingTag ? [] : tagListDetails?.getAllTagDetails?.tagDetails}
      />
      <AddTagTypeModal
        show={isAddTagTypeModalOpen}
        onHide={() => setIsAddTagTypeModalOpen(false)}
        onSubmit={handleTagTypeAdd}
        loading={tagTypeSubmitting}
        existingTagTypes={allTagTypeDetails ?? []}
      />
      <AskAQuestionModal
        show={isEditModalOpen}
        editMode
        onHide={() => {
          setIsEditModalOpen(false);
          refetchTags();
        }}
        tagOptions={tagsQuery?.tags.map(({ name }: { name: string }) => ({
          value: name,
          label: name,
        }))}
        {...selectedFaqInfo}
      />
      <ModerationConfirmationModal
        onHide={() => setIsConfirmationModalOpen(false)}
        show={isConfirmationModalOpen}
        target={confirmationModalTarget}
        type={confirmationModalType}
        onConfirm={handleConfirmationClick}
        loading={rejectionLoading || approvalLoading || unrejectionLoading}
      />
      {confirmationModalTarget && typeof confirmationModalTarget === "string" ? (
        <TagTypeDeleteModal
          onHide={() => {
            resetDeleteTagTypeMutation();
            setIsTagTypeConfirmationModalOpen(false);
          }}
          show={isTagTypeConfirmationModalOpen}
          target={confirmationModalTarget}
          onConfirm={handleTagTypeConfirmationClick}
          loading={loadingDeleteTagTypeMutation}
          error={deleteTagTypeError?.message}
          associatedTags={associatedTags}
        />
      ) : (
        <></>
      )}
      <TagModerationConfirmationModal
        onHide={() => {
          setErrorTagMessage("");
          setIsTagConfirmationModalOpen(false);
        }}
        show={isTagConfirmationModalOpen}
        target={confirmationModalTarget}
        type={confirmationModalType}
        onConfirm={handleTagConfirmationClick}
        loading={rejectionLoading || approvalLoading || unrejectionLoading}
        error={errorTagMessage}
      />
      {!!confirmationModalTarget && !!confirmationModalType && typeof confirmationModalTarget === "number" ? (
        <ComponentModerationConfirmationModal
          onHide={() => setIsComponentConfirmationModalOpen(false)}
          show={isComponentConfirmationModalOpen}
          target={confirmationModalTarget}
          type={confirmationModalType}
          onConfirm={handleComponentConfirmationClick}
          loading={componentApproving || componentRejecting}
          error={errorComponentConfirmationModal}
        />
      ) : (
        <></>
      )}
    </Page>
  );
}
