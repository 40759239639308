import styled from "@emotion/styled";

import { Label as RSLabel, FormGroup as RSFormGroup, FormGroupProps } from "reactstrap";

const FG = styled(RSFormGroup)`
  margin-top: 28px;
  margin-bottom: 0;

  &.without-margin-top {
    margin-top: 0;
  }
`;

export function FormGroup(props: FormGroupProps) {
  // extract regular from props
  const { regular, ...rest } = props;
  let className = rest.className;

  // preserve the "default" behaviour of pre-pending .form-check-lg
  // HOWEVER build escape hatch if regular property is set on FormGroup.
  // Reactstrap adds .form-check to all FormGroup components that have a check property, so we don't need to double it.
  if (rest.check && !regular) {
    className = "form-check-lg " + className;
  }

  return (
    <FG {...rest} className={className}>
      {rest.children}
    </FG>
  );
}

export const Label = styled(RSLabel)`
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.35px;
  color: #111;

  .optional {
    font-size: 12px;
    line-height: 1.75;
    letter-spacing: 0.3px;
    color: rgba(17, 17, 17, 0.65);
    margin-left: 4px;
  }
`;
