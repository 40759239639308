import { NavButton } from "../buttons/nav-button";
import { TESTID } from "../../views/khub/enums";
import { Col, Container, Row } from "reactstrap";
import { NavBtnSkeleton } from "../skeletons/nav-btn";

export type BtnData = {
  href: string;
  title: string;
};
type NavBtnsProps = {
  isLoading?: boolean;
  prevBtnData?: BtnData;
  nextBtnData?: BtnData;
};
export function NavBtns({ isLoading, prevBtnData, nextBtnData }: NavBtnsProps) {
  const PlaceHolder = () => <Col xs="6"></Col>;

  if (isLoading) {
    return (
      <Container className="py-4">
        <Row className="justify-content-between">
          <Col xs="6" className="ps-0">
            <NavBtnSkeleton />
          </Col>
          <Col xs="6" className="pe-0">
            <NavBtnSkeleton />
          </Col>
        </Row>
      </Container>
    );
  }
  const roundedBorder = "border border-secondary-subtle bg-primary rounded";
  return (
    <Container data-testid={TESTID.navBtns} className="py-4">
      <Row className="justify-content-between">
        {prevBtnData?.href ? (
          <Col className={roundedBorder + " me-4"}>
            <NavButton href={prevBtnData.href} lowerText={prevBtnData.title} isNext={false} />
          </Col>
        ) : (
          <PlaceHolder />
        )}
        {nextBtnData?.href ? (
          <Col className={roundedBorder + " ms-4"}>
            <NavButton href={nextBtnData.href} lowerText={nextBtnData.title} isNext />
          </Col>
        ) : (
          <PlaceHolder />
        )}
      </Row>
    </Container>
  );
}
