import T from "./box.type";
import S from "./box.style";

const Box = ({
  className,
  children,
  display,
  flex,
  flexDirection,
  flexWrap,
  padding,
  gap,
  vertical,
  width,
  minHeight,
  fullWidth,
  absolute,
  dataTestid,
  align,
  justify,
  border,
  backgroundColor,
  borderRadius,
}) => {
  return (
    <S.Box
      className={className}
      data-testid={dataTestid}
      gap={gap}
      padding={padding}
      vertical={vertical}
      display={display}
      flex={flex}
      flexWrap={flexWrap}
      flexDirection={flexDirection}
      width={width}
      minHeight={minHeight}
      fullWidth={fullWidth}
      absolute={absolute}
      align={align}
      justify={justify}
      border={border}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    >
      {children}
    </S.Box>
  );
};

Box.defaultProps = T.defaultProps;

export default Box;
