"use client";
import { PropsWithChildren, useEffect, useState } from "react";
import { Toast as RSToast, ToastProps as RSToastProps, ToastBody, ToastHeader } from "reactstrap";

type ToastProps = PropsWithChildren<
  RSToastProps & {
    autohide?: boolean | number;
    title?: string;
  }
>;
export function Toast({ children, autohide, title, ...rest }: ToastProps) {
  const [show, setShow] = useState(true);
  const hideTime = typeof autohide === "boolean" ? 5000 : autohide;
  useEffect(() => {
    if (hideTime) {
      setTimeout(() => {
        setShow(false);
      }, hideTime);
    }
  }, [hideTime]);

  return (
    <div className="toast-container bottom-0 start-50 translate-middle-x position-fixed">
      <RSToast isOpen={show} {...rest} data-testid="toast">
        {title && <ToastHeader>{title}</ToastHeader>}
        <ToastBody style={{ color: "white", fontSize: 16, fontWeight: 400 }}>{children}</ToastBody>
      </RSToast>
    </div>
  );
}
