import { Download24 } from "@bphxd/ds-core-react/lib/icons/index.js";
import { Button } from "../../components/button";

export function ReportCard({
  title,
  description,
  onClick,
  isLoading,
}: {
  title: string;
  description: string;
  onClick: () => void;
  isLoading: boolean;
}) {
  const dataTestId = `report-card-${title.toLowerCase().replaceAll(" ", "-")}`;
  return (
    <div
      data-testid={dataTestId}
      className="d-flex align-items-center justify-content-between border rounded-4 px-5 py-4"
    >
      <div className="d-flex flex-column p-2">
        <p className="fs-5 mb-0">{title}</p>
        <p className="fs-6 mb-0">{description}</p>
      </div>
      <Button
        name="moderator-delete-tag"
        data-testid={`${dataTestId}-download-button`}
        onClick={onClick}
        Icon={Download24}
        level="tertiary"
        theme="standard"
        rounded="pill"
        size="xs"
      >
        {isLoading ? "Downloading..." : "Download"}
      </Button>
    </div>
  );
}
