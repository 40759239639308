import S from "./responsive-image.style";
import T from "./responsive-image.type";

const ResponsiveImage = ({ width, height, path, title, borderRadius }) => {
  return <S.ResponsiveImage width={width} height={height} path={path} title={title} borderRadius={borderRadius} />;
};

ResponsiveImage.defaultProps = T.defaultProps;

export default ResponsiveImage;
