import { Row, Col, Container, Progress } from "reactstrap";
import { LinkButton } from "../../components/link-button";

export const HEADING_TEXT = {
  success: "Submitted successfully!",
  error: "An error occurred",
};

export default function HelpAndSupportFormSubmissionResults({ failure = false }) {
  let heading = HEADING_TEXT.success;
  let body =
    "Your request has been submitted. Our team will review it and get in contact with you as soon as possible.";
  if (failure) {
    heading = HEADING_TEXT.error;
    body = "Oh no, something went wrong! Please contact us.";
  }

  return (
    <Container data-testid="submission-results">
      <Row>
        <Col xs="12">
          <h3 className="mb-0 text-center" data-testid="submission-heading">
            {heading}
          </h3>
        </Col>
      </Row>
      <Row>
        <div className="mx-auto my-8 col-sm-4">
          <img
            alt={failure ? "failure illustration" : "success illustration"}
            src={`/images/submission-${failure ? "failure" : "success"}-img.webp`}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </div>
      </Row>
      <Row>
        <div className="mx-auto col-sm-10">
          <p className="text-center lead" style={{ opacity: 1 }} data-testid="submission-body">
            {body}
          </p>
        </div>
      </Row>
      <Row>
        <div className="mx-auto mb-8 col-sm-8">
          <div className="mt-2 mb-8 d-flex justify-content-center">
            {failure && (
              <LinkButton
                href={"https://app.slack.com/client/T0220L0L9NU/CQD7KDRB2"}
                name="help-and-support-submission-failure-contact-us"
                type="button"
                level="primary"
                theme="darker"
                rounded="pill"
                size="lg"
              >
                Contact us
              </LinkButton>
            )}
          </div>
          <Progress color="dark" value={failure ? 50 : 100} style={{ height: 2 }} />
        </div>
      </Row>
    </Container>
  );
}
