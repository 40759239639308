import { gql } from "@apollo/client";

export const GET_ALL_COMPONENTS_TAGS = gql`
  query AllComponentsAndTags {
    getAllCs {
      id
      name
      description
      repoUrl
      readmeUrl
      authorEmail
      createdAt
      updatedAt
      tags
    }
    getAllSSAs {
      id
      name
      description
      specFileUrl
      authorEmail
      authenticationType
      authenticationCredential
      authorizationHeaderName
      createdAt
      updatedAt
      tags
    }
    getAllTagDetails {
      tagDetails {
        id
        tagName
        tagType
        kHubOverviewPage
        icon
        highlighted
        description
      }
    }
  }
`;

export const GET_ALL_USER_COMPONENTS_AND_TAG_DETAILS = gql`
  query AllUserComponents {
    allUserComponents {
      id
      name
      description
      authorEmail
      status
      componentType
      createdAt
      updatedAt
      tags
    }
    getAllTagDetails {
      tagDetails {
        id
        tagName
        tagType
        kHubOverviewPage
        icon
        highlighted
        description
      }
    }
  }
`;

export const DELETE_COMPONENT = gql`
  mutation DeleteComponent($data: DeleteComponent!) {
    deleteComponent(data: $data) {
      id
      name
      description
      authorEmail
      status
      componentType
      tags
    }
  }
`;

export const UPDATE_COMPONENT = gql`
  mutation UpdateComponent($data: UpdateComponent!) {
    updateComponent(data: $data) {
      id
      name
      description
      authorEmail
      status
      createdAt
      updatedAt
      componentType
      tags
    }
  }
`;

export const EXECUTE_COMPONENT = gql`
  mutation ExecuteSsa($data: SsaExecute!) {
    executeSsa(data: $data) {
      code
    }
  }
`;

export const SUBMIT_CS = gql`
  mutation SubmitCs($data: CsCommand!) {
    submitCs(data: $data) {
      id
      name
      description
      repoUrl
      readmeUrl
      authorEmail
      tags
    }
  }
`;

export const SUBMIT_SSA = gql`
  mutation SubmitSsa($data: SsaCommand!) {
    submitSsa(data: $data) {
      id
      name
      description
      authenticationType
      authenticationCredential
      authorizationHeaderName
      tags
    }
  }
`;

export const VALIDATE_SSA = gql`
  mutation ValidateSsa($data: SsaValidate!) {
    validateSsa(data: $data) {
      code
      preview
      message
      errors
    }
  }
`;

export const RETRIEVE_COMPONENT = gql`
  query RetrieveComponent($name: String!) {
    retrieveComponent(name: $name) {
      id
      name
      description
      authorEmail
      status
      createdAt
      updatedAt
      componentType
      tags
      repoUrl
      readmeUrl
      userParameters {
        name
        arg_type
        description
        http_parameter_type
        default
        options
        max_length
        max_value
        min_length
        min_value
        hidden
        regex
        regex_hint
        whats_this_link
        options_api
        info_link {
          url
          placeholder_text
        }
      }
    }
  }
`;

export const RETRIEVE_OPTIONS = gql`
  query RetrieveSsaOptions($data: SsaOptionsApiRequest!) {
    retrieveSsaOptions(data: $data) {
      options
      parameterName
    }
  }
`;

export const RELATED_COMPONENTS = gql`
  query AllRelatedComponents($data: RelatedTags!) {
    allRelatedComponents(data: $data) {
      id
      name
      description
      authorEmail
      status
      componentType
      tags
    }
  }
`;
export const GET_USER_COMPONENTS_AND_TAGS = gql`
  query AllUserComponentsProfiles {
    allUserComponents {
      id
      name
      description
      authorEmail
      status
      componentType
      createdAt
      updatedAt
      tags
    }
    allTags {
      tags
    }
  }
`;

export const GET_ALL_EXISTING_COMPONENTS = gql`
  query AllExistingComponents {
    allExistingComponents {
      id
      name
      description
      authorEmail
      status
      componentType
      tags
    }
  }
`;
export const APPROVE_COMPONENT = gql`
  mutation ApproveComponent($data: ApproveComponent!) {
    approveComponent(data: $data) {
      id
      name
      description
      authorEmail
      status
      componentType
      tags
    }
  }
`;

export const REJECT_COMPONENT = gql`
  mutation RejectComponent($data: RejectComponent!) {
    rejectComponent(data: $data) {
      id
      name
      description
      authorEmail
      status
      componentType
      tags
    }
  }
`;

export type Component = {
  id: number;
  name: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  tags?: string[];
  authorEmail?: string;
  componentType?: string;
  categories?: string[];
};

export type ExtendedComponent = Component & {
  repoUrl: string;
  readmeUrl: string;
  userParameters?: {
    name?: string;
    arg_type?: string;
    description?: string;
    http_parameter_type?: string;
    default?: string;
    options?: string;
    max_length?: number;
    max_value?: string;
    min_length?: number;
    min_value?: string;
    hidden?: boolean;
    regex?: string;
    regex_hint?: string;
    whats_this_link?: string;
    options_api?: any;
    info_link?: {
      url?: string;
      placeholder_text?: string;
    };
  }[];
};

export type ExecutableFunction = Component & {
  specFileUrl: string;
  authenticationType: string;
  authenticationCredential: string | null;
  authorizationHeaderName: string;
};

export type CodeBlock = Component & {
  repoUrl: string;
  readmeUrl: string;
};

export type RetrieveComponent = {
  retrieveComponent: ExtendedComponent;
};

export type RelatedComponents = {
  allRelatedComponents: Component[];
};

export type AllComponents = {
  getAllSSAs: ExecutableFunction[];
  getAllCs: CodeBlock[];
  getAllTagDetails: {
    tagDetails: {
      tagName: string;
      description: string;
    }[];
  };
};

export type RetrieveSsaOptions = {
  retrieveSsaOptions: {
    options: string[];
    parameterName: string;
  };
};
