import { DateTime, Interval } from "luxon";

const TIME_INTERVALS = ["years", "months", "weeks", "days", "hours", "minutes", "seconds"];

export default function formatTimestampToRelativeDate(timestamp) {
  if (timestamp) {
    const start = DateTime.fromISO(timestamp);
    const end = DateTime.now();
    const interval = Interval.fromDateTimes(start, end).toDuration(TIME_INTERVALS).toObject();
    const largestUnit = TIME_INTERVALS.find((int) => interval[int] > 0);
    const value = Math.round(interval[largestUnit]);
    const formattedUnit = value > 1 ? largestUnit : value < 1 ? null : largestUnit?.slice(0, -1);
    return value && formattedUnit ? `${value} ${formattedUnit} ago` : "Just now";
  }
  return "";
}
