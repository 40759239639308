import { ACCELERATE_APIS } from "../config";
import { instrumentedFetch } from "../instrumented-fetch";
import { BaseRequestArgs, PostRequestArgs } from "../../types/api-types";

// @ts-expect-error
export const khubUrl: string = ACCELERATE_APIS.knowledgehub.url;

export async function getKHubAPI<T = Record<string, any>>({ spanName, query, spanAttributes = {} }: BaseRequestArgs) {
  return instrumentedFetch<T>({
    spanName,
    method: "GET",
    apiUrl: khubUrl,
    apiResource: query,
    spanAttributes,
  });
}

export async function deleteKHubAPI<T = Record<string, any>>({
  spanName,
  query,
  spanAttributes = {},
}: BaseRequestArgs) {
  return instrumentedFetch<T>({
    spanName,
    method: "DELETE",
    apiUrl: khubUrl,
    apiResource: query,
    spanAttributes,
  });
}

export async function postKHubAPI<T = Record<string, any>>({
  spanName,
  query,
  payload,
  spanAttributes = {},
}: PostRequestArgs) {
  return instrumentedFetch<T>({
    spanName,
    method: "POST",
    apiUrl: khubUrl,
    apiResource: query,
    payload,
    spanAttributes,
  });
}

export async function putKHubAPI<T = Record<string, any>>({
  spanName,
  query,
  payload,
  spanAttributes = {},
}: PostRequestArgs) {
  return instrumentedFetch<T>({
    spanName,
    method: "PUT",
    apiUrl: khubUrl,
    apiResource: query,
    payload,
    spanAttributes,
  });
}
