import { Badge as CoreBadge, BadgeProps as CoreBadgeProps } from "@bphxd/ds-core-react";
import { ReactNode } from "react";

type BadgeStyleProps = {
  icon?: ReactNode;
  iconName?: string;
  label: string | ReactNode;
  dataTestid?: string;
};

type BadgeProps = BadgeStyleProps & CoreBadgeProps;

export const Badge = ({ color, icon, label, dataTestid }: BadgeProps) => {
  if (label == null || label === "") {
    return null;
  }
  return (
    <CoreBadge color={color}>
      <div className="d-flex gap-2 align-items-center" data-testid={dataTestid}>
        {icon && (
          <div className="position-relative md">
            <div className="position-absolute d-flex align-items-center align-content-center">{icon}</div>
          </div>
        )}
        {label}
      </div>
    </CoreBadge>
  );
};
